import { css } from 'emotion';
import { styles, grid, fonts, grid_negative } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        backgroundColor: colors.lilaMiddle,
        '&__block': {
            '&_top': {
                paddingTop: grid.default[5],
                paddingBottom: grid.default[5]
            },
            '&_bottom': {
                borderTop: [null, null, null, `1px solid rgba(223,156,191,.5)`]
            }
        },
        '&__block_bottom &': {
            '&__content': {
                paddingTop: grid.default[1],
                paddingBottom: grid.default[1]
            }
        },
        '&__content': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexDirection: [null, null, null, null, 'column'],
            borderTop: [`1px solid rgba(223,156,191,.5)`, null, null, 'none'],
            width: ['calc(100% /12 * 9)', null, null, 'calc(100% - 80px)'],
            marginRight: ['calc(100% / 12)', null, null, 'auto'],
            marginLeft: 'auto',
            '&_vertical-middle': {
                alignItems: 'center'
            }
        },
        '&__block:first-of-type &': {
            '&__content': {
                border: 'none'
            }
        },
        '&__item': {
            width: '100%',
            padding: [null, null, null, 0],
            '&_4of10': {
                width: ['40%', null, '30%', null, '100%'],
                paddingRight: [null, null, null, 20, 0]
            },
            '&_3of10': {
                width: ['30%', null, '35%', null, '100%']
            }
        },
        '&__row': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexDirection: [null, null, 'column'],
            marginBottom: [null, null, null, null, 23]
        },
        '&__row-item': {
            width: '50%',
            marginTop: [null, null, 40, 30],
            padding: [null, null, null, 0],
            ':first-of-type': {
                marginTop: 0
            },
            '&_left': {
                width: [null, 'calc(100% / 4 * 1.75)', '100%']
            },
            '&_right': {
                width: [null, 'calc(100% / 4 * 2.25)', '100%']
            }
        },
        '&__logo': {
            display: 'block',
            width: [191, 145],
            height: [67, 53],

            img: {
                objectFit: 'contain',
                width: '100%',
                height: '100%'
            }
        },
        '&__contacts': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: [null, null, null, 0]
        },
        '&__address': {
            ...fonts.p4,
            marginBottom: [null, null, null, null, 7],
            color: colors.white
        },
        '&__contact-link': {
            ...fonts.p6,
            transition: '.3s ease',
            marginTop: grid.default[1],
            color: colors.lila,
            ':hover': {
                color: colors.rose
            }
        },
        '&__navigation': {
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: [null, null, 'column'],
            marginTop: grid_negative.default[1]
        },
        '&__nav-item': {
            ...fonts.b1,
            width: ['50%', null, 'auto'],
            transition: '.3s ease',
            marginTop: grid.default[1],
            padding: [null, null, null, null, 0],
            color: '#df9cbf',
            ':hover': {
                color: colors.lila
            }
        },
        '&__copyright': {
            ...fonts.p7l,
            padding: [null, null, null, 0],
            color: colors.white
        },
        '&__btn-wrapper': {
            marginTop: [null, null, null, null, 30]
        },
        '&__dev': {
            marginTop: [null, null, null, null, 9]
        }
    })
);
