import React from "react";
import { default as st } from "./style";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import MediaQuery from "react-responsive";
import { styles } from "../../constants";

import Image from "../../components/Image";
import TextCollapse from "../../components/TextCollapse";

const TrendsItem = ({ img, description, header, reverse }) => {
    const [ref, inView] = useInView({
        threshold: 0.3
    });

    return (
        <div className={`${st}${reverse ? ` ${st}_reverse` : ""}`} ref={ref}>
            <div
                className={`${st}__wrapper-anim${
                    inView ? ` ${st}__wrapper-anim_active` : ""
                }`}
            >
                <div className={`${st}__col ${st}__col_image`}>
                    <div className={`${st}__img-block`}>
                        <div className={`${st}__img-wrapper`}>
                            <Image src={img} />
                        </div>
                    </div>
                </div>
                <div className={`${st}__col ${st}__col_content`}>
                    <div className={`${st}__wrapper`}>
                        <h3 className={`${st}__title`}>{header}</h3>
                        <MediaQuery maxWidth={styles.breakpoints["550"]}>
                            {matches =>
                                matches ? (
                                    <div className={`${st}__text`}>
                                        <TextCollapse text={description} />
                                    </div>
                                ) : (
                                    <div className={`${st}__text`}>
                                        {parse(description)}
                                    </div>
                                )
                            }
                        </MediaQuery>
                    </div>
                </div>
            </div>
        </div>
    );
};

TrendsItem.propTypes = {
    img: PropTypes.string.isRequired,
    header: PropTypes.string,
    description: PropTypes.string.isRequired,
    reverse: PropTypes.bool
};

export default TrendsItem;
