import { css } from "emotion";
import { styles, fonts, grid, common } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        display: "flex !important",
        alignItems: "flex-start",
        alignContent: "center",
        justifyContent: "space-between",
        flexDirection: [null, null, "column"],

        "&__wrap": {
            paddingTop: grid.default[9],
            width: [null, "calc(100% / 9 * 8)", "calc(100% / 9 * 7)", "100%"],
            marginLeft: "auto",
            marginRight: [null, null, "auto"],
            paddingLeft: [null, 10, null, 0],
            paddingRight: [null, 10, 0],

            ".slick-arrow": {
                ...common.slickArrow({
                    bottom: "auto",
                    top: [586, 401, 378, 452],
                    right: ["auto", null, 0],
                    left: [
                        "calc(100% / 9 * 4 + 50px)",
                        "calc(100% / 9 * 4 + 62px)",
                        "auto"
                    ]
                })
            },
            ".slick-prev": {
                transform: [
                    "translate(-100%, -100%)",
                    null,
                    "translate(0%,-100%)"
                ]
            },
            ".slick-next": {
                transform: ["translate(-100%, 0%)", null, "translate(0,0)"]
            },
            ".slick-dots": {
                display: "flex !important",
                justifyContent: "center",
                position: "absolute",
                top: 270,
                bottom: "auto",
                left: 0,
                right: 0,
                li: {
                    padding: 5
                },
                button: {
                    transform: "rotate(45deg)",
                    width: "9px",
                    height: "9px",
                    border: `1px solid ${styles.colors.lila}`,
                    padding: 0,
                    margin: "0 auto",
                    ":before": {
                        content: "none"
                    }
                },
                ".slick-active": {
                    button: {
                        backgroundColor: styles.colors.lila
                    }
                }
            }
        },

        "&__side": {
            "&_left": {
                width: ["calc(100% / 9 * 4 + 20px)", "49.5%", "100%"],
                height: [520, 342, 310, 384, 191]
            },
            "&_right": {
                width: ["calc(100% / 9 * 4)", "45%", "100%"],
                marginTop: [null, null, 35]
            }
        },

        "&__text": {
            marginBottom: grid.default[4]
        },
        h3: {
            ...fonts.h3,
            marginBottom: grid.default[4]
        },
        p: {
            ...fonts.p4,
            marginBottom: grid.default[3],
            "&:last-child": {
                marginBottom: 0
            }
        },
        img: {
            ...styles.of()
        },
        "&_reverse &,&__wrap:nth-child(even) &": {
            "&__side": {
                "&_right": {
                    marginLeft: ["calc(100% / 9 * 0.5)", 0]
                }
            }
        },
        "&_reverse": {
            flexDirection: ["row-reverse", null, "column"]
        },
        "&__wrap:nth-child(even) &": {
            flexDirection: "row-reverse"
        }
    })
);
