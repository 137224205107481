import { css } from 'emotion';
import { styles, fonts } from '../../constants';

const { mq, colors } = styles;

export default css(
    mq({
        cursor: "pointer",
        
        '&__title': {
            ...fonts.b1,
            transition: 'color .3s ease',
            color: colors.lila
        },
        '&__icon': {
            marginLeft: ['1.5em', null, null, '1.1em'],
            transition: 'color .3s ease',
            color: colors.lila,
            fontSize: [10, null, null, 9]
        },
        ':hover &': {
            '&__title,&__icon': {
                color: colors.greyDark
            }
        }
    })
);
