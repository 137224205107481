import { css, keyframes } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

const arrow_anim = keyframes({
    '0%': {
        transform: 'rotate(40deg)',
        opacity: 0
    },
    '100%': {
        transform: 'rotate(0)',
        opacity: 1
    }
});

export default css(
    mq({
        position: 'relative',
        paddingTop: grid.default[9],

        '&__content': {
            display: 'flex',
            flexDirection: [null, null, null, null, 'column-reverse'],
            justifyContent: 'space-between',
            alignItems: 'center',
            width: ['calc(100% / 9 * 7)', null, '88.9%', '100%'],
            margin: ['0 auto', null, '0 0 0 auto']
        },
        '&__text-block': {
            width: [
                'calc(100% / 7 * 4)',
                null,
                '63.4%',
                'calc(62.6% - 10px)',
                '100%'
            ],
            padding: [null, null, null, 0],
            marginTop: [null, null, null, null, 20]
        },
        '&__img-wrapper': {
            width: [
                'calc(100% / 7 * 3)',
                null,
                '36.6%',
                'calc(37.4% - 10px)',
                '100%'
            ],
            padding: [null, null, null, 0]
        },
        '&__list-item': {
            ...fonts.p3,
            position: 'relative',
            transition:
                'transform 2s cubic-bezier(0.18, 1, 0.21, 1), opacity 2s cubic-bezier(0.18, 1, 0.21, 1)',
            transform: 'translateX(20px)',
            opacity: 0,
            paddingLeft: 27,
            marginTop: grid.default[3],
            ':before': {
                content: "''",
                position: 'absolute',
                left: 0,
                top: '.7em',
                display: 'block',
                backgroundColor: colors.greyDark,
                width: 14,
                height: 1
            },
            ':first-of-type': {
                marginTop: 0
            }
        },

        '&__header': {
            ...fonts.h2,
            transition:
                'transform 2s cubic-bezier(0.18, 1, 0.21, 1), opacity 2s cubic-bezier(0.18, 1, 0.21, 1)',
            transform: 'translateY(20px)',
            opacity: 0,
            marginBottom: grid.default[4]
        },
        '&__more': {
            marginTop: grid.default[4]
        },
        '&__img': {
            width: ['100%', '93%', null, '100%'],
            maxWidth: [358, null, null, null, 120],
            margin: ['0 auto', 0, null, null, '0 auto'],
            svg: {
                width: '100%',
                height: '100%',
                objectFit: 'contain'
            }
        },
        '.girl-arrow': {
            transformBox: 'fill-box',
            transformOrigin: '50% -25%',
            opacity: 0
        },
        '&_active &': {
            '&__header,&__list-item': {
                transform: 'translate(0,0)',
                opacity: 1
            }
        },
        '&_active': {
            '.girl-arrow': {
                animation: `${arrow_anim} 2s ease both`,
                '&_second': {
                    animationDelay: '.2s'
                },
                '&_third': {
                    animationDelay: '.4s'
                }
            }
        }
    })
);
