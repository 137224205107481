import fetch from 'isomorphic-fetch';

export function fetchQuery(obj) {
    const { origin, pathname } = window.location;

    let params = {},
        { options, type, data, url, error, alias } = obj,
        request = new Request(url, options);

    options = {
        ...options,
        method: type ? type : 'POST',
        mode: 'cors',
        alias: alias ? alias : pathname,
        body: type === 'POST' || type === 'PATCH' ? JSON.stringify(data) : null,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true

}
    };

    data = data || {};
    if (!origin.match('localhost')) {
        url = `${origin}${url}`;
    }
    if (type === 'GET') {
        params = data;
    }

    url = new URL(url);

    Object.keys(params).forEach(key =>
        url.searchParams.append(key, params[key])
    );
    return dispatch => {
        dispatch(fetchRequest(data));
        return fetch(request)
            .then(response => {
                if (response.ok) return response.json();
                throw response.json();
            })
            .then(json => {
                if (json) {
                    let response = {};

                    response[options.alias] = json;
                    dispatch(fetchSuccess(response));
                    //obj.success && obj.success.call(json);
                }
            })
            .catch(err => {
                dispatch(fetchFailure(err));
                err.then(data => {
                    error && error.call(data);
                });
            });
    };
}

export function fetchRequest(data) {
    return {
        type: 'FETCH_REQUEST',
        data
    };
}
export function fetchFailure(error) {
    return {
        type: 'FETCH_FAILURE',
        error
    };
}
export function fetchSuccess(response) {
    return {
        type: 'FETCH_SUCCESS',
        response
    };
}

