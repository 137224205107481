const actions = {
    foo: 'bar',
    HOME: {
        SET_INFO: 'HOME__SET__INFO'
    },
    REQUEST: {
        FETCH_REQUEST: 'FETCH_REQUEST',
        FETCH_FAILURE: 'FETCH_FAILURE',
        FETCH_SUCCESS: 'FETCH_SUCCESS'
    },
    ACCOUNT: {
        PUSH_USER: 'PUSH_USER',
        UPD_USER: 'UPD_USER',
        PUSH_ORDERS: 'PUSH_ORDERS'
    },
    SLIDER_HOME: {
        SET_SLIDE_INDEX: 'HOME_SET_SLIDE_INDEX'
    }
};
export default actions;
