import React, { Component } from 'react';
import { default as st } from './style';
import { SlideDown } from 'react-slidedown';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import CatalogItem from '../../components/CatalogItem';

export default class CatalogList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible_count: 5
        };
    }

    showMore = step => {
        this.setState({
            visible_count: this.state.visible_count + step
        });
    };

    render() {
        const { items } = this.props,
            { visible_count } = this.state,
            step = 3;

        return (
            <div className={st}>
                <div className={`${st}__list-wrapper`}>
                    <TransitionGroup className={`${st}__list`}>
                        {items
                            .filter((item, index) => index < visible_count)
                            .map((item, index) => {
                                return (
                                    <CSSTransition
                                        timeout={1500}
                                        classNames={`${st}__list-item-wrapper`}
                                        key={index}
                                    >
                                        <div className={`${st}__list-item`}>
                                            <CatalogItem
                                                reverse={
                                                    index % 2 ? true : false
                                                }
                                                {...item}
                                            />
                                        </div>
                                    </CSSTransition>
                                );
                            })}
                    </TransitionGroup>
                    <SlideDown className={`${st}__show-more-toggle`}>
                        {visible_count < items.length && (
                            <div className={`${st}__show-more-wrapper`}>
                                <span
                                    className={`${st}__show-more`}
                                    onClick={() => {
                                        this.showMore(step);
                                    }}
                                >
                                    Показать еще
                                </span>
                            </div>
                        )}
                    </SlideDown>
                </div>
            </div>
        );
    }
}
