import React, { Component } from "react";
import { default as st } from "./style";
import PictureBlock from "../PictureBlock";
import Slider from "react-slick";

import { styles } from "../../constants";

const { breakpoints } = styles;

export default class Banner extends Component {
    render() {
        const { images, frame = false, big } = this.props,
            settings = {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                speed: 1000,
                arrows: true,
                fade: false,
                centerPadding: "0",
                responsive: [
                    {
                        breakpoint: breakpoints["550"] + 1,
                        settings: {
                            arrows: false,
                            dots: true
                        }
                    }
                ]
            };

        return (
            <div
                className={`${st}${frame ? " _frame" : ""}${
                    big ? " _big" : ""
                }`}
            >
                {images.length > 1 ? (
                    <Slider className={`${st}__slider`} {...settings}>
                        {images.map((item, key) => (
                            <div key={key} className={`${st}__img-wrap`}>
                                <PictureBlock images={item} />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div className={`${st}__img-wrap`}>
                        <PictureBlock images={images[0]} />
                    </div>
                )}
            </div>
        );
    }
}
