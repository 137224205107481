import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        overflow: 'hidden',
        transition: 'border-color .3s ease,color .3s ease',
        backgroundColor: colors.lila,
        height: [74, 64, null, null, 54],
        cursor: 'pointer',
        color: colors.white,

        '&__icon': {
            marginRight: [13, 17, null, null, 10],
            fontSize: [30, 25]
        },

        '&__wrapper': {
            ...fonts.b1,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: ['1px 36px 0', null, '1px 15px 0', null, '1px 10px 0'],
            minWidth: 173,
            textAlign: 'center',
            color: 'inherit'
        },
        '&__circle': {
            position: 'absolute',
            display: 'block',
            borderRadius: '50%',
            transform: 'translate(-50%,-50%)',
            backgroundColor: colors.rose,
            transition: 'width 0.4s ease-in-out, height 0.4s ease-in-out',
            width: 0,
            ':after': {
                content: "''",
                display: 'block',
                paddingTop: '100%'
            }
        },
        '&_stretch': {
            width: '100%',
            minWidth: 0
        },
        '&_bordered': {
            border: `1px solid ${colors.white}`,
            backgroundColor: 'transparent'
        },
        '&_lila': {
            borderColor: colors.lila,
            color: colors.lila
        },
        '&_lila &': {
            '&__circle': {
                backgroundColor: colors.lila
            }
        },
        ':hover': {
            borderColor: colors.rose
        },
        ':hover &': {
            '&__circle': {
                transition: 'width .4s ease-in-out, height .6s ease-in-out',
                width: '220%'
            }
        },
        '&_lila:hover': {
            borderColor: colors.lila,
            color: colors.white
        }
    })
);
