import React from 'react';
import { default as st } from './style';
import { useInView } from 'react-intersection-observer';

import { ReactComponent as Girl } from '../../images/svg/woman.svg';

import LinkCustom from '../../components/LinkCustom';

export default props => {
    const [ref, inView] = useInView({
        threshold: 0.4
    });
    return (
        <div className={`${st}${inView ? ` ${st}_active` : ''}`}>
            <div className={`${st}__content`} ref={ref}>
                <div className={`${st}__text-block col`}>
                    <h2 className={`${st}__header`}>Эффекты</h2>
                    <ul className={`${st}__list`}>
                        {props.effects.map((effect, index) => (
                            <li
                                key={index}
                                className={`${st}__list-item`}
                                style={{ transitionDelay: `${index * 0.1}s` }}
                            >
                                {effect}
                            </li>
                        ))}
                    </ul>
                    <div className={`${st}__more`}>
                        <LinkCustom
                            title="Подробнее"
                            to="/advantages#programs"
                            icon="arrow-right-small"
                        />
                    </div>
                </div>
                <div className={`${st}__img-wrapper col`}>
                    <div className={`${st}__img`}>
                        <Girl />
                    </div>
                </div>
            </div>
        </div>
    );
};
