import React, {Component} from "react";
import { default as st } from "./style";
import PropTypes from "prop-types";
import LinkCustom from "../LinkCustom";
import {bindActionCreators} from "redux";
import * as popupActions from "../../actions/popup";
import {connect} from "react-redux";

class ProductHeader extends Component {
    render() {
        const
            { title, subtitle, price, baseStore, popupActions } = this.props,
            { fetchResult } = baseStore,
            { togglePopUp } = popupActions;
        
        return (
            <div className="block-wrapper">
                <div className="container container_right">
                    <div className={`${st} col`}>
                        <div className={`${st}__row`}>
                            <h1 className={`${st}__title`}>{title}</h1>
                        </div>
                
                        <div className={`${st}__row`}>
                            <div className={`${st}__col ${st}__col_left`}>
                                {subtitle && (
                                    <p className={`${st}__subtitle`}>{subtitle}</p>
                                )}
                            </div>
                            <div className={`${st}__col ${st}__col_right`}>
                                <div className={`${st}__price-wrap`}>
                                    <span className={`${st}__label`}>от</span>
                                    <span className={`${st}__price`}>{price}</span>
                                </div>
                        
                                <LinkCustom
                                    title="Связаться с менеджером"
                                    icon="arrow-right-small"
                                    onClick={() => {
                                        togglePopUp({
                                            showPopUp: true,
                                            content:
                                            fetchResult.base
                                                .header_popup
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProductHeader.propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    subtitle: PropTypes.string
};


function mapStateToProps({ baseStore, popupStore }) {
    return { baseStore, popupStore };
}

function mapDispatchToProps(dispatch) {
    return {
        popupActions: bindActionCreators(popupActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductHeader);
