import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from '../../actions/main.js';

import SectionGrid from '../../components/SectionGrid';
import {urls} from '../../constants';
import Banner from "../../components/Banner";
import GalleryLinksSlider from "../../components/GalleryLinksSlider";
import Description from "../../components/Description";
import InfraredCabinsEffects from "../../components/InfraredCabinsEffects";
import TabsContent from "../../components/TabsContent";
import Features from "../../components/Features";
import FormDefault from "../../containers/FormDefault";
import Breadcrumbs from "../../components/Breadcrumbs";
import isNotEmpty from "../../utils/is-not-empty";

class Advantages extends Component {
    componentDidMount() {
        const { routePath } = this.props;
    
        if (!this.props.mainStore.fetchResult || 
                !this.props.mainStore.fetchResult.hasOwnProperty(routePath)) {
                    this.props.mainActions.fetchQuery({
                        type: 'GET',
                        url: urls.advantages,
                        alias: routePath
                    });
                }        
    }

    render() {
        const { mainStore, routePath } = this.props,
            { fetchResult } = mainStore;
        
        return (
            <Fragment>
                {fetchResult[routePath] && (
                    <div className='overflow-wrap'>
                        {isNotEmpty(fetchResult[routePath].breadcrumbs) &&
                            <Breadcrumbs links={fetchResult[routePath].breadcrumbs} />
                        }
    
                        {isNotEmpty(fetchResult[routePath].banner) &&
                            <SectionGrid
                                right_column_hide={true}
                            >
                                <Banner
                                    images={fetchResult[routePath].banner}
                                />
                            </SectionGrid>
        
                        }
    
                        {isNotEmpty(fetchResult[routePath].description_1) &&
                            <SectionGrid>
                                <Description {...fetchResult[routePath].description_1} />
                            </SectionGrid>
                        }
    
                        {isNotEmpty(fetchResult[routePath].features) &&
                            <SectionGrid>
                                <Features {...fetchResult[routePath].features} />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].description_2) &&
                            <SectionGrid>
                                <Description {...fetchResult[routePath].description_2} titleLevel={2}/>
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].effects) &&
                            <SectionGrid>
                                <InfraredCabinsEffects {...fetchResult[routePath].effects} />
                            </SectionGrid>
                        }
    
                        {isNotEmpty(fetchResult[routePath].tabs_content) &&
                            <SectionGrid>
                                <TabsContent {...fetchResult[routePath].tabs_content}/>
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].gallery_links_slider) &&
                            <SectionGrid
                                right_column_hide={true}
                            >
                                <GalleryLinksSlider {...fetchResult[routePath].gallery_links_slider} />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].form) &&
                            <SectionGrid last={true}>
                                <FormDefault
                                    theme="white"
                                    {...fetchResult[routePath].form}
                                />
                            </SectionGrid>
                        }
              
                    </div>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ mainStore }) {
    return { mainStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Advantages);
