import { css } from "emotion";
import { styles, grid } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        position: "relative",
        display: "block",
        marginBottom: grid.default[9],

        "&__table": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: [null, null, "column"]
        },

        "&__col": {
            transform: "translateY(50px)",
            opacity: 0,
            transition: "0.5s ease",
            padding: [null, null, null, 0],

            "&_left": {
                width: ["calc(100% / 7 * 4)", null, "100%"]
            },

            "&_right": {
                width: ["calc(100% / 7 * 2)", null, "100%"],
                marginTop: [null, null, 35]
            }
        },

        "&_active &": {
            "&__col": {
                transform: "translateY(0)",
                opacity: 1,

                "&_left": {
                    transitionDelay: "0.3s"
                }
            }
        }
    })
);
