import { css } from "emotion";
import { styles, fonts, grid, grid_negative } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        marginBottom: grid.default[9],

        ".container_narrow": {
            width: ["calc(100% / 9 * 8)", null, null, "100%"],
            marginRight: 0
        },
        "&__title": {
            ...fonts.h2,
            marginBottom: grid.default[6],
            textAlign: [null, null, null, "left"],
            padding: [null, null, null, 0]
        },

        "&__list": {
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: ["flex-start", null, null, "space-between"],
            flexWrap: "wrap",
            width: ["calc(100% + 100px)", "100%"],
            left: [-50, 0],
            marginTop: grid_negative.default[6],
            ".slick-dots": {
                display: "flex !important",
                justifyContent: "center",
                position: "relative",
                bottom: "auto",
                marginTop: 24,
                li: {
                    padding: 5
                },
                button: {
                    transform: "rotate(45deg)",
                    width: "9px",
                    height: "9px",
                    border: `1px solid ${styles.colors.lila}`,
                    padding: 0,
                    margin: "0 auto",
                    ":before": {
                        content: "none"
                    }
                },
                ".slick-active": {
                    button: {
                        backgroundColor: styles.colors.lila
                    }
                }
            }
        },

        "&__item": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: grid.default[6],
            padding: ["0 50px", "0 10px", null, "0"],
            width: [
                "calc(100% / 3)",
                null,
                "50%",
                "calc(47.5% - 10px)",
                "100%"
            ],
            transform: "translateY(50px)",
            opacity: 0,
            transition: "all 0.3s ease",
            textAlign: [null, null, null, null, "center"]
        },

        "&__icon": {
            position: "relative",
            width: "100%",
            marginBottom: grid.default[3],

            img: {
                display: "block",
                height: [66, 55, null, null, 54],
                width: "auto",
                maxWidth: "100%",
                margin: [null, null, null, null, "0 auto"]
            }
        },

        "&__subtitle": {
            ...fonts.p6,
            marginBottom: grid.default[3]
        },

        "&__text": {
            ...fonts.p4
        },

        "&_active &": {
            "&__item": {
                transform: "translateY(0)",
                opacity: 1,
                ...styles.setDelays({ count: 10, delay: 100 })
            }
        }
    })
);
