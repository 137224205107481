import { css } from "emotion";
import { styles, common, grid, fonts, grid_negative } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        position: "relative",
        overflow: ["hidden", null, null, null, 'visible'],
        width: "100%",
        paddingBottom: [null, null, null, null, 80],

        '.slick-list': {
            width: ["100%", null, null, null, "calc(100% + 40px)"],
        },

        "&__slider": {
            width: [
                "calc(100% + 50px)",
                "calc(100% + 40px)",
                "calc(100% + 34px)",
                "calc(100% + 40px)",
                "calc(100% + 20px)"
            ],
            left: [-25, -20, -17, -20, -10],

            ".slick-arrow": {
                ...common.slickArrow({
                    top: [
                        "calc((((100vw / 12 * 10 + 50px) / 5) - 50px) * 2)",
                        "calc((((100vw / 12 * 10 + 40px) / 5) - 40px) * 2)",
                        "calc((((100vw / 12 * 10 + 34px) / 4) - 34px) * 2)",
                        "calc((((100vw - 90px + 40px) / 3) - 40px) * 2)"
                    ],
                    bottom: "auto",
                    left: [
                        "calc(100% / 5 * 4)",
                        null,
                        "calc(100% / 4 * 3)",
                        "calc(100% / 3 * 2)"
                    ],
                    right: "auto"
                }),

                marginTop: grid_negative.default[6]
            },

            ".slick-prev": {
                transform: "translateY(-200%)"
            },

            ".slick-next": {
                transform: "translateY(-100%)"
            },
            ".slick-dots": {
                display: "flex !important",
                justifyContent: "center",
                position: "relative",
                bottom: "auto",
                marginTop: 20,
                li: {
                    padding: 5
                },
                button: {
                    transform: "rotate(45deg)",
                    width: "9px",
                    height: "9px",
                    border: `1px solid ${styles.colors.lila}`,
                    padding: 0,
                    margin: "0 auto",
                    ":before": {
                        content: "none"
                    }
                },
                ".slick-active": {
                    button: {
                        backgroundColor: styles.colors.lila
                    }
                }
            }
        },

        "&__item": {
            position: "relative",
            overflow: "hidden",
            width: "100%",
            padding: ["0 25px", "0 20px", "0 17px", "0 20px", "0 10px"]
        },

        "&__img-wrap": {
            display: "block",
            position: "relative",
            ...styles.rh(1.9928),
            overflow: "hidden",
            marginBottom: grid.default[3],

            "&::before": {
                content: '""',
                display: "block",
                ...styles.absolute(),
                width: "100%",
                height: "100%",
                background: styles.colors.lila,
                opacity: 0.26,
                transition: "opacity 0.3s ease",
                zIndex: 2
            },

            "& img": {
                display: "block",
                ...styles.absolute(),
                ...styles.of(),
                transition: "transform 0.3s ease",
                transformOrigin: "50% 50%",
                zIndex: 1
            }
        },

        "&__item:hover &": {
            "&__img-wrap": {
                img: {
                    transform: "scale(1.05)"
                },

                "&::before": {
                    opacity: 0
                }
            }
        },

        "&__title": {
            ...fonts.h2,
            marginBottom: grid.default[7]
        },

        "&__subtitle": {
            ...fonts.p2,
            color: styles.colors.greyDark
        }
    })
);
