import { styles } from './';

const { breakpoints } = styles,
    base = [
        {
            type: 'default',
            values: [
                {
                    numbers: [
                        5,
                        10,
                        15,
                        25,
                        45,
                        50,
                        70,
                        90,
                        110,
                        140,
                        210,
                        210,
                        null
                    ]
                },
                {
                    point: breakpoints['1400'],
                    numbers: [
                        5,
                        10,
                        10,
                        20,
                        35,
                        40,
                        50,
                        70,
                        80,
                        110,
                        120,
                        120,
                        null
                    ]
                },
                {
                    point: breakpoints['1100'],
                    numbers: [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null
                    ]
                },
                {
                    point: breakpoints['900'],
                    numbers: [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        60
                    ]
                },
                {
                    point: breakpoints['550'],
                    numbers: [
                        5,
                        10,
                        10,
                        15,
                        25,
                        30,
                        35,
                        50,
                        40,
                        70,
                        80,
                        0,
                        null
                    ]
                }
            ]
        }
    ];

let grid_generate = function(obj) {
    let { negative } = obj || {},
        grid = {};

    for (let i = 0; i < base.length; i++) {
        let { type, values } = base[i];

        grid[type] = [];

        for (let j = 0; j < values.length; j++) {
            let { numbers } = values[j];

            for (let k = 0; k < numbers.length; k++) {
                if (!grid[type][k]) {
                    grid[type][k] = [];
                }

                if (negative) {
                    grid[type][k].push(
                        numbers[k] ? numbers[k] * -1 : numbers[k]
                    );
                } else {
                    grid[type][k].push(numbers[k]);
                }
            }
        }
    }

    return grid;
};

const grid = grid_generate();
export const grid_negative = grid_generate({ negative: true });

export default grid;
