import React from 'react';
import { default as st } from './style';
import { YanMap } from '../../containers';
import FormDefault from '../../containers/FormDefault';

export default ({ coords, form }) => {
    return (
        <div className={st}>
            <div className={`${st}__header`}>Будьте на связи!</div>
            <div className={`${st}__content`}>
                <div className={`${st}__map`}>
                    <YanMap coords={coords} />
                </div>
                <div className={`${st}__form`}>
                    <FormDefault {...form} vertical={true} theme="white" />
                </div>
            </div>
        </div>
    );
};
