import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { default as st } from "./style";

import * as popupActions from "../../actions/popup.js";

import Button from "../../components/Button";
import Image from "../../components/Image";

class ContactsInfo extends Component {
    render() {
        const { address, popup, btn, popupActions, contacts } = this.props,
            { togglePopUp } = popupActions;

        return (
            <div className={`${st} col`}>
                {address && <h3 className={`${st}__address`}>{address}</h3>}
                <div className={`${st}__contacts`}>
                    {contacts.phone && (
                        <div className={`${st}__item`}>
                            <a
                                className={`${st}__link-item ${st}__link-item_link`}
                                href={contacts.phone.href}
                                title="Позвонить"
                            >
                                <i className={`${st}__icon icon-phone`} />
                                <span className={`${st}__link-title`}>
                                    {contacts.phone.title}
                                </span>
                            </a>
                        </div>
                    )}

                    {contacts.email && (
                        <div className={`${st}__item`}>
                            <a
                                className={`${st}__link-item ${st}__link-item_link`}
                                href={contacts.email.href}
                                title="Написать"
                            >
                                <i className={`${st}__icon icon-sms`} />
                                <span className={`${st}__link-title`}>
                                    {contacts.email.title}
                                </span>
                            </a>
                        </div>
                    )}

                    {contacts.schedule && (
                        <div className={`${st}__item`}>
                            <span className={`${st}__link-item`}>
                                <i className={`${st}__icon icon-time`} />
                                <span className={`${st}__link-title`}>
                                    {contacts.schedule.title}
                                </span>
                            </span>
                        </div>
                    )}
                    {contacts.social && (
                        <div className={`${st}__item ${st}__item_row`}>
                            {contacts.social.map((item, index) => {
                                return (
                                    <a
                                        className={`${st}__soc-item`}
                                        href={item.href}
                                        title={item.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        key={index}
                                    >
                                        <Image src={item.icon} />
                                    </a>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div className={`${st}__btn`}>
                    <Button
                        bordered={true}
                        mod="_lila"
                        stretch={true}
                        {...btn}
                        onClick={() => {
                            togglePopUp({
                                showPopUp: true,
                                content: popup
                            });
                        }}
                    />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        popupActions: bindActionCreators(popupActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(ContactsInfo);
