import React, { Component } from "react";
import { Formik, Form } from "formik";
import { default as st } from "./style";

import { urls } from "../../constants";

import CatalogFilterItem from "../../containers/CatalogFilterItem";

const fields = [
    {
        title: "По предназначеннию:",
        name: "category",
        fields: [
            {
                value: "all",
                label: "Все модели",
                single: true,
                checked: true
            },
            {
                value: "1",
                label: "Для дома"
            },
            {
                value: "2",
                label: "Для офиса"
            },
            {
                value: "3",
                label: "для гостиничного номера"
            },
            {
                value: "4",
                label: "Для SPA и wellness центра"
            }
        ]
    },
    {
        title: "По количеству человек:",
        name: "quantity",
        fields: [
            {
                value: "all",
                icons_count: 1,
                label: "all",
                title: "all",
                single: true,
                checked: true
            },
            {
                value: "1",
                label: "1",
                icons_count: 1
            },
            {
                value: "2",
                label: "2",
                icons_count: 2
            },
            {
                value: "3",
                label: "3",
                icons_count: 3
            },
            {
                value: "4",
                title: "+",
                icons_count: 3,
                label: "3+"
            }
        ]
    }
];

export default class CatalogFilter extends Component {
    render() {
        const { onSubmit, initialValues } = this.props;

        return (
            <Formik
                enableReinitialize={false}
                initialValues={initialValues}
                onSubmit={(values, actions, e) => {
                    onSubmit({ url: urls.filter, data: values });
                }}
            >
                {props => (
                    <Form className={st}>
                        {fields.map((item, index) => {
                            return (
                                <div className={`${st}__item`} key={index}>
                                    <CatalogFilterItem {...item} />
                                </div>
                            );
                        })}
                    </Form>
                )}
            </Formik>
        );
    }
}
