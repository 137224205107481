import React, { Component } from 'react';
import { default as st } from './style';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class LinkCustom extends Component {
    render() {
        const
            { title, href, to, icon = 'arrow-right-small', onClick } = this.props,
            Tag = to ? Link : (href ? 'a' : 'div');
        
        return (
            <React.Fragment>
                <Tag className={st} to={to} href={href} title={title} onClick={onClick}>
                    <span className={`${st}__title`}>{title}</span>
                    {icon && <i className={`${st}__icon icon-${icon}`} />}
                </Tag>
            </React.Fragment>
        )
    }
}


LinkCustom.propTypes = {
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
    to: PropTypes.string,
    icon: PropTypes.string
};

export default LinkCustom;
