import React, { Component } from "react";
import { default as st } from "./style";
import { InView } from "react-intersection-observer";
import { ReactComponent as SkinSvg } from "../../images/svg/skin2.svg";
import { ReactComponent as SkinSvgMobile } from "../../images/svg/skin-mobile.svg";
import AnimatedFrame from "../AnimatedFrame";
import MediaQuery from "react-responsive";

import { styles } from "../../constants";

const { breakpoints } = styles;

class SkinGraphic extends Component {
    render() {
        return (
            <InView threshold={0.4}>
                {({ inView, ref }) => {
                    const activeMod = inView ? ` ${st}_active` : "";

                    return (
                        <div className={`${st} ${activeMod}`} ref={ref}>
                            <MediaQuery maxWidth={breakpoints["550"]}>
                                {matches =>
                                    !matches && (
                                        <div className={`${st}__frame-wrap`}>
                                            <AnimatedFrame active={inView} />
                                        </div>
                                    )
                                }
                            </MediaQuery>

                            <div className={`${st}__svg-wrap container_narrow`}>
                                <MediaQuery maxWidth={breakpoints["550"]}>
                                    {matches =>
                                        matches ? (
                                            <SkinSvgMobile />
                                        ) : (
                                            <SkinSvg />
                                        )
                                    }
                                </MediaQuery>
                            </div>
                        </div>
                    );
                }}
            </InView>
        );
    }
}

export default SkinGraphic;
