import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import main from './main';
import sliderHome from './sliderHome';
import base from './base';
import popup from './popup';
import filter from './filter';

export default combineReducers({
    mainStore: main,
    baseStore: base,
    routing: routerReducer,
    sliderHome: sliderHome,
    popupStore: popup,
    filterStore: filter
});
