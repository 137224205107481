import React, { Component, Fragment } from "react";
import { default as st } from "./style";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as filterActions from "../../actions/filter.js";
import { urls } from "../../constants";

import CatalogFilter from "../../containers/CatalogFilter";
import CatalogList from "../../components/CatalogList";

const initialValues = {
    category: [{ value: "all", label: "Все модели", single: true }],
    quantity: [{ value: "all", label: "all", single: true }]
};

class Catalog extends Component {
    componentDidMount() {
        if (!this.props.filterStore.fetchResult) {
            this.get_catalog_items({ url: urls.filter, data: initialValues });
        }
    }

    get_catalog_items = ({ url, data }) => {
        this.props.filterActions.fetchQuery({
            url: url,
            options: {
                method: 'POST',
                body: JSON.stringify(data)
            }

        });
    };

    render() {
        const { filterStore } = this.props;

        return (
            <div className={st}>
                {filterStore.fetchResult && (
                    <Fragment>
                        <CatalogFilter
                            onSubmit={this.get_catalog_items}
                            initialValues={initialValues}
                        />
                        <CatalogList items={filterStore.fetchResult} />
                    </Fragment>
                )}
            </div>
        );
    }
}

function mapStateToProps({ filterStore }) {
    return { filterStore };
}

function mapDispatchToProps(dispatch) {
    return {
        filterActions: bindActionCreators(filterActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
