import React, { Component } from 'react';
import { default as st } from './style';
import SectionGrid from '../../components/SectionGrid';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class Breadcrumbs extends Component {
    render() {
        const { links } = this.props;

        return (
            <SectionGrid first={true}>
                <div className="block-wrapper">
                    <div className="container container_right">
                        <div className={`${st} col`}>
                            {links.map(({ title, to }, index) => {
                                return (
                                    <div className={`${st}__item`} key={index}>
                                        {to ? (
                                            <Link
                                                className={`${st}__link`}
                                                to={to}
                                                title={title}
                                            >
                                                {title}
                                            </Link>
                                        ) : (
                                            <span
                                                className={`${st}__link ${st}__link_text`}
                                            >
                                                {title}
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </SectionGrid>
        );
    }
}

Breadcrumbs.propTypes = {
    links: PropTypes.array.isRequired
};

export default Breadcrumbs;