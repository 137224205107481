import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";
const { mq, colors } = styles;

export default css(
    mq({
        maxWidth: [null, null, 470],
        padding: [null, null, null, 0],
        "&__address": {
            ...fonts.h3,
            maxWidth: 600
        },
        "&__btn": {
            width: "100%",
            maxWidth: 452,
            marginTop: grid.default[5]
        },
        "&__contacts": {
            display: "flex",
            justifyContent: ["flex-start", "space-between", "flex-start"],
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: grid.default[5]
        },
        "&__item": {
            marginRight: ["8%", 20, "11%", "8%"],
            marginTop: [null, null, 35, null, 20],
            ":last-of-type": {
                marginRight: 0
            },
            ":nth-of-type(-n+2)": {
                marginTop: [null, null, 0, null, 20]
            },
            ":first-of-type": {
                marginTop: [null, null, null, null, 0]
            }
        },
        "&__link-item": {
            display: "flex",
            alignItems: "center",
            color: colors.lila,
            "&_link": {
                transition: ".3s ease"
            }
        },
        "&__link-title": {
            ...fonts.p6,
            color: colors.greyDark
        },
        "&__link-item_link &": {
            "&__link-title": {
                color: colors.lila
            }
        },
        "&__link-item_link:hover &": {
            "&__link-title": {
                color: colors.greyDark
            }
        },
        "&__icon": {
            marginRight: 15,
            fontSize: 20
        },
        "&__soc-item": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: `1px solid ${colors.lila}`,
            width: 44,
            height: 44,
            marginRight: 10,
            ":last-of-type": {
                marginRight: 0
            }
        }
    })
);
