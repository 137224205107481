import React, { Fragment, useState } from "react";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import parse from "html-react-parser";
import { default as st } from "./style";

const TextCollapse = ({ text, className, maxLine = 5 }) => {
    let [expanded, handleExpand] = useState(false),
        sst = className ? className : st;

    const toggleExpanded = () => {
        handleExpand(!expanded);
    };

    let [showBtn, toggleShowBtn] = useState(false);

    return (
        <Fragment>
            {!expanded ? (
                <HTMLEllipsis
                    maxLine={maxLine}
                    className={`${sst}__text`}
                    unsafeHTML={text}
                    onReflow={({ clamped }) => {
                        if (clamped) {
                            toggleShowBtn(true);
                        }
                    }}
                />
            ) : (
                <div className={`${sst}__text`}>{parse(text)}</div>
            )}
            {showBtn && (
                <span
                    className={`${sst}__icon-wrapper${
                        expanded ? ` ${sst}__icon-wrapper_active` : ""
                    }`}
                    onClick={toggleExpanded}
                >
                    <i className={`${sst}__icon icon-angle-down`} />
                </span>
            )}
        </Fragment>
    );
};

export default TextCollapse;
