import { css } from 'emotion';
import { styles } from '../../constants';

const { mq, colors } = styles;

export default css(
    mq({
        width: [150, 104, 84],
        height: [75, 52, 42],
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingTop: '20px',
        backgroundColor: styles.colors.lilaLight,
        position: 'absolute',
        zIndex: 5,
        '&__icon': {
            transition: '.3s ease',
            transform: 'scaleX(-1)',
            fontSize: 10,
            color: colors.lila
        },
        ':hover &': {
            '&__icon': {
                transform: 'scaleX(-1) translateX(3px)'
            }
        }
    })
);
