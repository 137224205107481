import React from 'react';
import { default as st } from './style';

import LinkImageBorder from '../../components/LinkImageBorder';
import TrendsItem from '../../components/TrendsItem';
import LinkCustom from '../../components/LinkCustom';

export default props => {
    return (
        <div className={st}>
            <div className={`${st}__header-banner`}>
                <LinkImageBorder
                    to="/technologies#compare"
                    title="ИК-КАБИНА или САУНА?"
                    img="images/home-img.jpg"
                />
            </div>
            <h2 className={`${st}__header`}>
                Cоветы по оформлению жилого пространства.
            </h2>
            <div className={`${st}__trends`}>
                {props.trends.map((trend, index) => (
                    <div className={`${st}__trends-item`} key={trend.id}>
                        <TrendsItem
                            reverse={index % 2 ? true : false}
                            {...trend}
                        />
                    </div>
                ))}
            </div>
            <div className={`${st}__link-line`}>
                <LinkCustom
                    title="B-intense в интерьере"
                    to="/interior"
                    icon="arrow-right-small"
                />
            </div>
        </div>
    );
};
