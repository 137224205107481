import React from 'react';
import { YMaps, Map } from 'react-yandex-maps';
import { css } from 'emotion';
import { styles } from '../../constants';
const { mq } = styles;

const st = css(mq({ width: '100%', height: '100%' }));

export default ({ coords }) => {
    return (
        <div className={st}>
            <YMaps>
                <Map
                    defaultState={{ center: coords, zoom: 17 }}
                    width="100%"
                    height="100%"
                />
            </YMaps>
        </div>
    );
};
