const initialState = {
    type: 'base',
    isFetching: false,
    didInvalidate: false,
    fetchResult: {}
};

export default function baseReducer(state = initialState, { type, ...action }) {
    switch (type) {
        case 'BASE_FETCH_REQUEST':
            return {
                ...state,
                isFetching: true,
                didInvalidate: false
            };

        case 'BASE_FETCH_SUCCESS':
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                fetchResult: action.response
            };

        case 'BASE_FETCH_FAILURE':
            return {
                ...state,
                isFetching: false,
                didInvalidate: true,
                fetchResult: action.error
            };

        default:
            return state;
    }
}
