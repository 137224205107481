import { css } from "emotion";
import { styles, fonts } from "../../constants";
const { mq, colors } = styles;

export const st = css(
		mq({
			width: "100%",
			"&__icon": {
				transition: ".3s ease",
				color: colors.white,
				fontSize: [10, null, null, null, 8],
				"&._active": {
					transform: "scaleY(-1)"
				}
			},
			"&__child-wrapper": {
				opacity: 0,
				position: "absolute",
				left: 0,
				top: 0,
				overflow: "hidden",
				width: "100%",
				height: "100%"
			},
			"&__label": {
				...fonts.p5,
				flex: "1 0 auto",
				textAlign: "center",
				color: colors.white
			},
			"&__label-icon": {
				marginLeft: 12
			}
		})
	),
	sst = {
		control: base =>
			mq({
				...base,
				boxShadow: "none",
				backgroundColor: colors.greyDark,
				borderRadius: 0,
				border: "none",
				minHeight: [60, null, null, null, 55],
				paddingLeft: [27, null, null, null, 20],
				paddingRight: [18, null, null, null, 10],
				":hover": {
					borderColor: colors.blue_hover,
					".icon-chevron": {
						color: colors.blue_hover
					}
				}
			}),
		indicatorSeparator: () => ({
			display: "none"
		}),
		clearIndicator: base => ({
			...base,
			cursor: "pointer",
			color: `${colors.lila}!important`
		}),
		dropdownIndicator: base => ({
			...base,
			cursor: "pointer"
		}),
		singleValue: () =>
			mq({
				...fonts.p7,
				color: colors.font
			}),
		valueContainer: base =>
			mq({
				...base,
				overflow: "visible",
				padding: 0
			}),
		placeholder: () =>
			mq({
				...fonts.p7,
				color: colors.grey_middle
			}),
		menu: base =>
			mq({
				...base,
				zIndex: 10,
				top: "100%",
				left: 0,
				backgroundColor: colors.lilaLight,
				borderRadius: 0,
				paddingBottom: 0,
				boxShadow: "none",
				margin: 0
			}),
		menuList: base =>
			mq({
				...base,
				padding: 0
			}),
		option: (base, state) =>
			mq({
				...fonts.p5,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				transition: ".3s ease",
				borderTop: `1px solid ${colors.lilaDark}`,
				padding: "0 24px",
				height: 50,
				cursor: "pointer",
				color: state.isSelected ? colors.lila : colors.greyDark,
				backgroundColor: state.isSelected
					? colors.lilaDark
					: "transparent",
				":hover": {
					backgroundColor: colors.lilaDark
				}
			})
	};
