import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';

const { mq } = styles;

export default css(
    mq({
        position: 'relative',
        paddingTop: grid.default[9],
        paddingBottom: grid.default[9],
        '&__header': {
            ...fonts.h2,
            maxWidth: 980,
            marginBottom: grid.default[6],
            marginTop: grid.default[9],
            padding: [null, '0 10px', null, 0]
        },
        '&__trends-item': {
            marginTop: grid.default[9],
            ':first-of-type': {
                marginTop: 0
            }
        },
        '&__link-line': {
            marginTop: grid.default[6],
            textAlign: ['center', null, null, null, 'left']
        }
    })
);
