import { css } from "emotion";
import { styles, common } from "../../constants";

const { mq, colors } = styles;

export default css(
    mq({
        position: "relative",
        width: ["100%", null, "calc(100% / 9 * 8)", "100%"],
        marginLeft: "auto",

        "&__slider": {
            position: "relative",
            width: "100%",
            zIndex: 1,

            ".slick-arrow": {
                ...common.slickArrow({
                    bottom: [70, 55, 50, 32],
                    right: ["calc(100% / 10)", null, 90]
                })
            },

            ".slick-prev": {
                transform: "translateY(-100%)"
            },
            ".slick-dots": {
                display: "flex !important",
                justifyContent: "center",
                position: "relative",
                bottom: "auto",
                marginTop: 20,
                li: {
                    padding: 5
                },
                button: {
                    transform: "rotate(45deg)",
                    width: "9px",
                    height: "9px",
                    border: `1px solid ${styles.colors.lila}`,
                    padding: 0,
                    margin: "0 auto",
                    ":before": {
                        content: "none"
                    }
                },
                ".slick-active": {
                    button: {
                        backgroundColor: styles.colors.lila
                    }
                }
            }
        },

        "&__img-wrap": {
            display: "block",
            position: "relative",
            ":after": {
                content: "''",
                display: "block",
                paddingTop: ["34.6%", null, null, null, "79%"]
            }
        },

        "& picture": {
            display: "block",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%"
        },
        "& img": {
            display: "block",
            position: "relative",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center"
        },

        "&._frame": {
            "&::before": {
                content: ['""', null, null, null, "none"],
                display: "block",
                ...styles.absolute(
                    ["30%", "35%", "53.2%", "55.2%"],
                    ["20.5%", "20.9%", "18.8%", "11%"],
                    "auto",
                    "auto"
                ),
                width: ["15.5%", "13.5%", "14.7%", "20.2%"],
                height: ["107%", "94%", "79%", "59.5%"],
                boxSizing: "border-box",
                border: [
                    `15px solid ${colors.lilaLight}`,
                    null,
                    `10px solid ${colors.lilaLight}`
                ],
                zIndex: 2
            }
        },
        "&._big": {
            width: ["100%", null, null, "calc(100% - 90px)", "100%"],
            marginLeft: [null, null, null, 0]
        },
        "&._big &": {
            "&__img-wrap": {
                ":after": {
                    paddingTop: [null, null, "44.4%", "80%"]
                }
            }
        }
    })
);
