import { css } from "emotion";
import {styles, fonts, grid, common, grid_negative} from "../../constants";

const { mq, colors } = styles;

export default css(
    mq({
        position: "relative",
        display: "block",
        marginBottom: grid.default[9],
        
        '.slick-dots': {
            ...common.slickDots
        },
        
        "&_col": {
            paddingLeft: [10, null, 0],
            paddingRight: [10, null, 0]
        },
        "&__container": {
            position: "relative",
            display: "block",
            width: ["calc(100% / 9 * 7)", null, "calc(100% / 9 * 8)", "100%"],
            marginLeft: "auto",
            marginRight: ["auto", null, 0],
            paddingLeft: [null, null, 10, 26, 0],
            paddingRight: [null, null, null, 15, 0]
        },
        
        '&__list': {
            position: 'relative',
            
            '&:not(.slick-initialized)': {
                width: ['calc(100% + 40px)', null, 'calc(100% + 20px)'],
                left: [-20, null, -10],
                paddingTop: 10,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexWrap: 'wrap'
            },
            
            '&.slick-initialized': {
                '.slick-list': {
                    width: ['calc(100% + 40px)', null, 'calc(100% + 20px)'],
                    left: [-20, null, -10],
                    paddingTop: 10,
                },
    
                ".slick-arrow": {
                    ...common.slickArrow({
                        bottom: [-20, null, -10],
                        right: [40, null, 20]
                    }),
        
                    marginTop: grid_negative.default[6]
                },
                
                ".slick-prev": {
                    transform: 'translateY(-100%)'
                }
            }
        },
    
        '&__list:not(.slick-initialized) &__item': {
            marginBottom: [null,null,null,null,30],
            
            '&:last-of-type': {
                marginBottom: [null,null,null,null,0]
            }
        },
        
        '&__item': {
            width: ['50%', null, null, null, '100%'],
            padding: ['0 20px', null, '0 10px'],
    
            transform: "translateY(100px)",
            opacity: "0",
            transition: "1s ease",
        },
        
        "&__img-wrap": {
            position: "relative",
            display: "block",
            width: "100%",
            cursor: 'pointer',
            
            '&:hover': {
                img: {
                    transform: 'translateY(-10px)'
                }
            },
        
            img: {
                display: "block",
                width: "100%",
                height: "auto",
                transition: 'transform .3s ease'
            }
        },
        
     
        '&__list > &__item:nth-of-type(odd), .slick-slide:nth-of-type(odd) &__item': {
            transitionDelay: ".25s"
        },
        '&__list > &__item:nth-of-type(even), .slick-slide:nth-of-type(even) &__item': {
            paddingTop: [140, 100, 80, 70, 0],
            transitionDelay: "0s"
        },
    
        "&_active &__list > &__item:nth-of-type(odd), &_active .slick-slide:nth-of-type(odd) &__item": {
            transitionDelay: "0s"
        },
    
        '&_active &__list > &__item:nth-of-type(even), &_active .slick-slide:nth-of-type(even) &__item': {
            transitionDelay: ".25s"
        },
        
        '&__list > &__item:nth-of-type(odd) &, .slick-slide:nth-of-type(odd) &__item &': {
            '&__img-wrap': {
                paddingRight: [40, null, 20, null, 0]
            }
        },
    
        "&_active &": {
            "&__item, &__col": {
                transform: "translateY(0)",
                opacity: "1",
            }
        },
        "&__col": {
            transform: "translateY(100px)",
            opacity: "0",
            transition: "1s ease",

            "&_left": {
                width: ["calc(100% / 7 * 3.5 - 10px)", null, null, null, "100%"],
                transitionDelay: ".25s"
            },
            
            "&_right": {
                width: [
                    "calc(100% / 7 * 3.5 - 10px)",
                    null,
                    null,
                    "100%"
                ],
                transitionDelay: "0s",
                paddingTop: [140, 100, 80, 70, 0]
            }
        },
        
        "&__bottom-wrap": {
            position: "relative",
            display: "block",
            marginTop: [-140, -100, -80, -70, 0],
            zIndex: 2
        },
    
        "&__list.slick-initialized + &__bottom-wrap": {
            marginTop: [-140, -100, -80, -20, 0],
        },

        "&__list.slick-initialized + &__bottom-wrap &__col_left": {
            width: ["calc(100% / 7 * 3.5 - 10px)", null, null, "100%"],
        },

        "&__title-wrap": {
            marginBottom: grid.default[8]
        },

        "&__title": {
            ...fonts.h3
        },

        "&__description": {
            marginTop: grid.default[4]
        },

        "&__bottom": {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: grid.default[6]
        },

        "&__link": {
            ...fonts.h3,
            color: colors.lila
        },

        "&__label": {
            ...fonts.p4,
            marginTop: grid.default[3]
        },

        "&_reset-margin": {
            marginBottom: 0
        }
    })
);
