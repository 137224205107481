import React from "react";
import { default as st } from "./style";
import { useInView } from "react-intersection-observer";
import parse from "html-react-parser";
import MediaQuery from "react-responsive";

import AnimatedFrame from "../AnimatedFrame";
import TextCollapse from "../../components/TextCollapse";

import { styles } from "../../constants";

const { breakpoints } = styles;

export default props => {
    const [ref, inView] = useInView({
            threshold: 0.4
        }),
        activeMod = inView ? ` ${st}_active` : "",
        styleMod = props.mod || "";

    return (
        <div className={`${st} col ${activeMod} ${styleMod}`} ref={ref}>
            <div className={`${st}__frame-wrap`}>
                <AnimatedFrame active={inView} />
            </div>
            <MediaQuery maxWidth={breakpoints["550"]}>
                {matches =>
                    matches ? (
                        <div className={`${st}__content`}>
                            <TextCollapse maxLine={8} text={props.content} />
                        </div>
                    ) : (
                        <div className={`${st}__content`}>
                            {parse(props.content)}
                        </div>
                    )
                }
            </MediaQuery>
        </div>
    );
};
