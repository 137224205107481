import facepaint from 'facepaint';

const styles = {
    breakpoints: {
        '1400': 1700,
        '1100': 1100,
        '900': 900,
        '700': 700,
        '550': 550
    },

    bp: val => `@media all and (max-width: ${val}px)`,
    bpm: val => `@media all and  (min-width: ${val + 1}px)`,
    
    setDelays: (data) => {
        const { base=0, count=0, delay=100, type='transition', selectorMod='', reverse=false, nth='nth-of-type' } = data;
        let stylesObj = {};
        
        for (let i = 1; i <= count; i++) {
            stylesObj[`&:${nth}(${i}n)${selectorMod}`] = {
                [`${type}Delay`]: reverse ? `${base + ((delay * count) - (delay * i))}ms` : `${base + (delay * i)}ms`
            }
        }
        
        return stylesObj;
    },
    
    colors: {
        lilaMiddle: '#915E92',
        rose: '#DF9CBF',
        white: '#FFFFFF',
        creme: '#FAF7EB',
        gradient: 'linear-gradient(180deg, #DF9CBF 0%, #905D92 100%);',
        lila: '#682366',
        lilaLight: '#F6F3F7',
        greyDark: '#333333',
        lilaDark: '#E6E2E7',
    },

    fontFamily: {
        robo: 'Roboto',
        tgb: 'Texgyreheros',
        icon: 'icomoon'
    },

    absolute: (top = '0', right = '0', bottom = '0', left = '0') => {
        return {
            position: 'absolute',
            top: top,
            right: right,
            bottom: bottom,
            left: left,
            margin: 'auto'
        };
    },
    rh: (mlt = 1, display='block') => {
        return {
            '&::after': {
                content: '""',
                display: display,
                paddingTop: `${100 * mlt}%`
            }
        };
    },
    
    of: (obj={}) => {
        const {pos='center center', fit='cover'} = obj;
        
        return {
            width: '100%',
            height: '100%',
            objectFit: fit,
            objectPosition: pos,
        }
    }
};

styles.mq = facepaint([
    styles.bp(styles.breakpoints['1400']),
    styles.bp(styles.breakpoints['1100']),
    styles.bp(styles.breakpoints['900']),
    styles.bp(styles.breakpoints['550'])
]);

export default styles;
