import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq, colors } = styles;

export default css(
    mq({
        "&__text": {
            p: {
                ...fonts.p4,
                marginTop: grid.default[3],
                ":first-child": {
                    marginTop: 0
                }
            }
        },
        "&__icon-wrapper": {
            transition: ".3s ease",
            display: "inline-block",
            marginTop: grid.default[2],
            "&_active": {
                transform: "scaleY(-1)"
            }
        },
        "&__icon": {
            fontSize: 11,
            color: colors.lila
        }
    })
);
