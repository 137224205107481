import { css } from "emotion";
import { styles, grid, fonts } from "../../constants";
const { mq, colors } = styles;

export default css(
    mq({
        backgroundColor: colors.lila,
        paddingTop: grid.default[6],
        paddingBottom: grid.default[6],
        "&__content": {
            width: "65.4%",
            margin: "0 auto"
        },
        "&__big-title": {
            ...fonts.h2,
            color: colors.white,
            textAlign: "center"
        },
        "&__title": {
            ...fonts.h3,
            color: colors.white,
            textAlign: "center"
        },
        "&__row": {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            flexWrap: "wrap"
        },
        "&__row-item": {
            width: "100%",
            marginTop: grid.default[4],
            "&_1of2": {
                width: ["calc(50% - 10px)", null, null, null, "100%"]
            },
            "&_top": {
                alignSelf: "flex-start"
            }
        },
        "&__privacy": {
            ...fonts.p7l,
            color: colors.white
        },
        "&__privacy-link": {
            transition: ".3s ease",
            color: colors.rose,
            ":hover": {
                color: colors.lilaMiddle
            }
        },
        "&_white": {
            backgroundColor: colors.white,
            paddingTop: grid.default[9],
            paddingBottom: [0, null, null, null, 80]
        },
        "&_white &": {
            "&__title,&__big-title": {
                color: colors.greyDark
            },
            "&__privacy": {
                color: colors.greyDark
            },
            "&__privacy-link": {
                color: colors.lila,
                ":hover": {
                    color: colors.lilaLight
                }
            },
            "&__content": {
                width: [null, "calc(100% / 9 * 6)", "100%"],
                marginLeft: [null, "calc(100% / 9)", 0],
                padding: [null, "0 10px", 0]
            }
        },
        "&_vertical": {
            padding: 0
        },
        "&_vertical &": {
            "&__content": {
                width: "100%"
            },
            "&__row-item": {
                width: "100%",
                marginTop: grid.default[3],
                "&_1of2": {
                    width: [
                        null,
                        null,
                        "calc(50% - 15px)",
                        "calc(50% - 10px)",
                        "100%"
                    ]
                },
                ":first-of-type": {
                    marginTop: 0
                },
                "&_privacy": {
                    marginTop: grid.default[4]
                }
            }
        },
        "&_popup": {
            backgroundColor: "transparent"
        },
        "&_popup &": {
            "&__content": {
                width: "100%"
            }
        },
        "&_filled &": {
            "&__content": {
                position: [null, null, null, null, "relative"],
                width: [null, null, "100%"],
                padding: [null, null, "0 70px", "0 50px", 0]
            }
        },
        "&_filled": {
            position: [null, null, null, null, "relative"],
            ":before": {
                content: [null, null, null, null, "''"],
                position: "absolute",
                left: -40,
                top: 0,
                display: "block",
                backgroundColor: colors.lila,
                width: "calc(100% + 80px)",
                height: "100%"
            }
        }
    })
);
