import React, {Component} from 'react';
import { default as st } from './style';
import Image from "../Image";
import PropTypes from "prop-types";

class IconsList extends Component {
    render() {
        const { list } = this.props;
        
        return (
            <div className={`${st}`}>
                {
                    list.map((item, key) => {
                        const { icon, text } = item;
                        
                        return (
                            <div key={key} className={`${st}__item`}>
                                <div className={`${st}__icon`}>
                                    <Image src={icon} />
                                </div>
        
                                <div className={`${st}__text-wrap`}>
                                    <span className={`${st}__text`}>{text}</span>
                                </div>
        
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

IconsList.propTypes = {
    list: PropTypes.array.isRequired
};

export default IconsList;