import React from 'react';
import { default as st } from './style';
import { useInView } from 'react-intersection-observer';

import Image from '../Image/';

export default props => {
    const [ref, inView] = useInView({
        threshold: 0.4
    });
    const { title, icon, items, inflow } = props;
    return (
        <div
            className={`${st} ${inView ? ` ${st}_active` : ''} ${
                inflow ? ` ${st}_inflow` : ''
            }`}
            ref={ref}
        >
            <div className={`${st}__side ${st}__side_left`}>
                <h3>{title}</h3>
                <div className={`${st}__icon`}>
                    <Image src={icon} />
                </div>
            </div>
            <div className={`${st}__side ${st}__side_right`}>
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`${st}__item`}
                        style={{ transitionDelay: `${index * 0.2}s` }}
                    >
                        <div className={`${st}__item-title`}>
                            <p>{item.title}</p>
                        </div>
                        <div className={`${st}__item-text`}>
                            <p>{item.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
