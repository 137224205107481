import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        paddingTop: grid.default[7],
        paddingBottom: grid.default[9],

        '&__list-item': {
            marginTop: grid.default[10],
            ':first-of-type': {
                marginTop: 0
            }
        },
        '&__show-more-wrapper': {
            textAlign: 'center'
        },
        '&__show-more': {
            ...fonts.b1,
            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            transition: '.3s ease',
            paddingTop: grid.default[9],
            color: colors.lila,
            ':hover': {
                color: colors.greyDark
            },
            ':after': {
                content: "''",
                display: 'block',
                backgroundColor: colors.lila,
                marginTop: grid.default[3],
                width: 1,
                height: [46, 30]
            }
        },
        '&__show-more-toggle': {
            transition: '.3s ease'
        },
        '&__list-item-wrapper': {
            '&-enter': {
                opacity: 0,
                transform: 'translateY(30px)'
            },
            '&-enter-active': {
                opacity: 1,
                transition: '1s ease',
                transform: 'translateY(0)'
            },
            '&-exit': {
                opacity: 1
            },
            '&-exit-active': {
                opacity: 0,
                transform: 'translateY(30)',
                transition: '.5s ease'
            }
        }
    })
);
