import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";
const { mq, colors } = styles;

export default css(
    mq({
        position: "relative",
        paddingBottom: grid.default[9],
        ".container": {
            width: [null, null, "calc(100% / 9 * 8)", "100%"],
            marginRight: [null, null, 0]
        },
        ":after": {
            ...fonts.p0,
            content: ["attr(data-additional)", null, "none"],
            position: "absolute",
            right: 0,
            top: 0,
            transformOrigin: "100% 0%",
            transform: "rotate(-90deg) translateY(-100%)",
            padding: "10px 0",
            color: colors.lilaLight
        },
        "&__title": {
            ...fonts.p1,
            padding: [null, null, null, 0],
            span: {
                color: colors.lilaMiddle
            }
        },
        "&__twin": {
            marginTop: grid.default[9],
            padding: [null, null, 0]
        },
        "&__contacts-info": {
            marginTop: grid.default[6],
            ".container": {
                width: [null, "calc(100% / 9 * 8)", null, "100%"],
                marginRight: [null, 0]
            }
        }
    })
);
