import { css } from "emotion";
import { styles, common, grid, fonts } from "../../constants";

const { mq, colors } = styles;

export default css(
    mq({
        position: "relative",
        width: "100%",
        paddingTop: grid.default[7],
        paddingBottom: grid.default[7],
        marginBottom: [null, null, null, null, 128],

        "&::before": {
            content: '""',
            display: "block",
            ...styles.absolute(0, "auto", 0, [0, null, null, null, -40]),
            width: ["100%", null, null, null, "calc(100% + 80px)"],
            height: "100%",
            background: colors.lilaLight,
            zIndex: 0
        },
        "&__title-wrap": {
            padding: [null, null, null, null, 0]
        },
        "&__title": {
            position: "relative",
            ...fonts.h3,
            marginBottom: grid.default[6]
        },

        "&__subtitle": {
            ...fonts.p6,
            marginBottom: grid.default[2]
        },

        "&__container": {
            position: "relative",
            padding: [null, null, null, "0 30px", 0]
        },

        "&__content": {
            paddingLeft: [25, 20, 0]
        },

        "&__item": {
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start"
        },

        "&__table": {
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: [null, null, "column"]
        },

        "&__col": {
            padding: [null, null, null, null, 0],
            "&_left": {
                width: [
                    "calc(100% / 7 * 2)",
                    "calc(100% / 7 * 3 - 20px)",
                    "calc(100% / 7 * 5)",
                    "calc(100% / 7 * 5 - 13px)",
                    "100%"
                ]
            },
            "&_right": {
                width: [
                    "calc(100% / 7 * 4)",
                    "calc(100% / 7 * 4 + 20px)",
                    "100%"
                ],
                marginTop: [null, null, 35, null, 25]
            }
        },

        "&__slider": {
            position: "relative",
            width: "100%",
            zIndex: 1,

            ".slick-arrow": {
                ...common.slickArrow({
                    backgroundColor: colors.white,
                    top: ["auto", null, 250, 182],
                    bottom: [0, null, "auto"],
                    right: "auto",
                    left: [
                        "calc(100% / 7 * 8)",
                        null,
                        null,
                        "calc(100% + 30px)"
                    ]
                })
            },

            ".slick-prev": {
                transform: "translate(-100%, -100%)"
            },

            ".slick-next": {
                transform: "translateX(-100%)"
            },

            ".slick-dots": {
                display: "flex !important",
                justifyContent: "center",
                position: "absolute",
                top: "calc(100% + 82px)",
                left: 0,
                bottom: "auto",
                li: {
                    padding: 5
                },
                button: {
                    transform: "rotate(45deg)",
                    width: "9px",
                    height: "9px",
                    border: `1px solid ${styles.colors.lila}`,
                    padding: 0,
                    margin: "0 auto",
                    ":before": {
                        content: "none"
                    }
                },
                ".slick-active": {
                    button: {
                        backgroundColor: styles.colors.lila
                    }
                }
            }
        },

        "&__img-wrap": {
            display: "block",
            position: "relative",
            width: "100%",
            height: [250, null, null, 230, 130],
            padding: [48, null, null, null, "33px 39px"],
            background: colors.white,

            img: {
                position: "relative",
                ...styles.of({ fit: "contain" }),
                display: "block"
            }
        }
    })
);
