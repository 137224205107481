const
    isProd = window.location.hostname.indexOf('localhost'),
    hostLocal = 'https://api.b-intense.ru/wp-json/',
    hostLocalServer = 'https://api.b-intense.ru/wp-json/',
    test_url = 'b-instance/',
    test_url_local = 'test-data/',
    local_url = 'http://localhost:3000/',
    get_url = filename => {
        return isProd ? `${hostLocal}${test_url}${filename}` : `${local_url}${test_url_local}${filename}`;
    };

function urls() {
    return isProd === -1 ? urlsObj.backend : urlsObj.frontend;
}

const urlsObj = {
    backend: {
            form_handler: hostLocalServer + 'sau/send-mail/v1/send',
            base: get_url('base'),
            homepage: get_url('homepage'),
            models: get_url('models'),
            product_page: get_url('model'),
            advantages: get_url('advantages'),
            interior: get_url('interior'),
            filter: get_url('filter'),
            contacts: get_url('contacts'),
            not_found: get_url('not-found'),
            about: get_url('about'),
            technologies: get_url('technologies')
        },

    frontend: {
        form_handler: hostLocalServer + 'sau/send-mail/v1/send',
        base: get_url('base.json'),
        homepage: get_url('home.json'),
        models: get_url('models.json'),
        product_page: get_url('product_page.json'),
        advantages: get_url('advantages.json'),
        interior: get_url('interior.json'),
        filter: get_url('filter.json'),
        contacts: get_url('contacts.json'),
        not_found: get_url('not-found.json'),
        about: get_url('about.json'),
        technologies: get_url('technologies.json')
    }
};

export default urls();
