import { css, keyframes } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq, colors } = styles;

const draw_line = keyframes({
        "0%": {
            height: "0"
        },
        "100%": {
            height: "100%"
        }
    }),
    show_vertical_line = keyframes({
        "0%": {
            transform: "translateX(-105%)"
        },
        "100%": {
            transform: "translateX(0)"
        }
    });

export default css(
    mq({
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        "&__col": {
            "&_left": {
                position: "relative",
                width: ["calc(100% / 6)", null, "calc(21.5% - 45px)", 90, 40],
                ":after": {
                    content: ["''", null, null, "none"],
                    position: "absolute",
                    right: 0,
                    top: 0,
                    backgroundColor: colors.lilaLight,
                    display: "block",
                    width: 1,
                    height: "100%"
                }
            },
            "&_middle": {
                width: [
                    "calc(100% / 12 * 9)",
                    null,
                    "calc(78.5% - 45px)",
                    "calc(100% - 180px)",
                    "calc(100% - 80px)"
                ],
                ":last-of-type": {
                    width: [
                        "calc(100% / 12 * 10)",
                        null,
                        "calc(78.5% + 45px)",
                        "calc(100% - 90px)",
                        "calc(100% - 80px)"
                    ]
                }
            },
            "&_right": {
                position: "relative",
                borderLeft: [
                    `1px solid ${colors.lilaLight}`,
                    null,
                    null,
                    "none"
                ],
                width: ["calc(100% / 12)", null, 90, 40],
                display: [null, null, null, "none"]
            }
        },
        "&__left-wrapper": {
            position: "sticky",
            left: 0,
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: [null, null, null, "transparent!important"],
            width: "100%",
            height: "100vh",
            "&_stretch": {
                position: "relative",
                height: "100%"
            }
        },
        "&__left-title": {
            ...fonts.p0,
            fontSize: ["7vh", null, "6vh"],
            transform: "rotate(-90deg)",
            color: colors.lilaLight
        },
        "&__right-wrapper": {
            position: "absolute",
            left: 0,
            top: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            maxHeight: 812
        },
        "&__right-logo": {
            width: "35%",
            path: {
                fill: "inherit"
            }
        },
        "&__logo-vertical": {
            img: {
                height: ["373px", "293px", "193px"],
                width: ["83px", "63px", "43px"]
            }
        },

        "&__gradient-block": {
            position: "absolute",
            left: 0,
            top: 0,
            backgroundImage: colors.gradient,
            animation: `${show_vertical_line} 1.2s ease .7s both`,
            willChange: "transform",
            height: ["100%", null, null, null, 365],
            width: ["calc(200% - 10px)", null, "calc(266% + 10px)", "230%", 170]
        },
        "&_first &": {
            "&__col_middle": {
                paddingTop: ["calc(100% / 12)", null, 90, null, 60]
            }
        },
        "&_last &": {
            "&__col_middle": {
                paddingBottom: [
                    grid.default[10][0],
                    grid.default[10][1],
                    grid.default[10][2],
                    grid.default[10][3],
                    0
                ]
            }
        },
        "&_overflow": {
            overflow: "hidden"
        },
        "&_main &": {
            "&__col": {
                "&_left": {
                    width: [null, null, "calc(14.1% - 45px)", 90, 40],
                    ":after": {
                        animation: `${draw_line} 8s ease both`,
                        height: 0
                    }
                },
                "&_middle": {
                    width: [
                        null,
                        null,
                        "calc(85.9% - 45px)",
                        "calc(100% - 180px)",
                        "calc(100% - 80px)"
                    ]
                }
            }
        }
    })
);
