import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        flexDirection: [null, null, null, 'column'],
        '&__col': {
            '&_content': {
                position: 'relative',
                width: [
                    'calc(100% / 9 * 5 - 10px)',
                    null,
                    'calc(55% - 10px)',
                    '100%'
                ]
            },
            '&_image': {
                zIndex: 1,
                position: 'relative',
                width: [
                    'calc(100% / 9 * 4 - 10px)',
                    null,
                    'calc(45% - 10px)',
                    '100%'
                ]
            }
        },
        '&__content': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: '100%',
            width: ['59.5%', null, '100%'],
            margin: '0 auto',
            padding: ['85px 0', '70px 0', '0 30px', '37px 0 0', '25px 0 0']
        },
        '&__title': {
            ...fonts.h2,
            transition: '.3s ease',
            color: colors.greyDark
        },
        '&__text': {
            ...fonts.p4,
            marginTop: grid.default[4],
            color: colors.greyDark,
            ':first-child': {
                marginTop: 0
            }
        },
        '&__img-block': {
            position: 'relative',
            width: '100%',
            ':after': {
                content: "''",
                display: 'block',
                paddingTop: ['79.37%', null, '100.37%', '65.3%']
            }
        },
        '&__img-wrapper': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            img: {
                objectFit: 'cover',
                objectPosition: 'center',
                width: '100%',
                height: '100%'
            }
        },
        '&__link': {
            marginTop: grid.default[4],

            ':first-child': {
                marginTop: 0
            }
        },
        '&__border': {
            position: 'absolute',
            right: 0,
            top: [-50, -40],
            pointerEvents: 'none',
            width: ['118%', '111%'],
            height: '100%',
            display: [null, null, 'none']
        },
        '&__border-line': {
            position: 'absolute',
            overflow: 'hidden',
            ':after,:before': {
                content: "''",
                position: 'absolute',
                display: 'block',
                transition: '.4s ease',
                backgroundColor: colors.lilaLight
            },
            ':nth-of-type(odd)': {
                width: '100%',
                height: 15,
                ':after,:before': {
                    top: 0,
                    width: '50%',
                    height: '100%'
                },
                ':before': {
                    left: 0,
                    transform: 'translateX(-105%)'
                },
                ':after': {
                    right: 0,
                    transform: 'translateX(105%)'
                }
            },
            ':nth-of-type(even)': {
                width: 15,
                height: '100%',
                ':after,:before': {
                    left: 0,
                    width: '100%',
                    height: '50%'
                },
                ':before': {
                    top: 0,
                    transform: 'translateY(-105%)'
                },
                ':after': {
                    bottom: 0,
                    transform: 'translateY(105%)'
                }
            },
            ':nth-of-type(1)': {
                left: 0,
                top: 0
            },
            ':nth-of-type(2)': {
                right: 0,
                top: 0
            },
            ':nth-of-type(3)': {
                right: 0,
                bottom: 0
            },
            ':nth-of-type(4)': {
                left: 0,
                bottom: 0
            }
        },
        '&_reverse': {
            flexDirection: ['row-reverse', null, null, 'column']
        },
        '&_reverse &': {
            '&__border': {
                right: 'auto',
                left: 0
            }
        },
        ':hover &': {
            '&__title': {
                color: colors.lila
            },
            '&__border-line': {
                ':after,:before': {
                    transform: 'translate(0,0)'
                }
            }
        }
    })
);
