const routing = {
    home: '/',
    models: '/models',
    product_page: '/models/:id',
    advantages: '/advantages',
    interior: '/interior',
    contacts: '/contacts',
    about: '/about',
    technologies: '/technologies'
};

export default routing;
