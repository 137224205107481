import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SectionGrid from '../../components/SectionGrid';
import TitleBlock from '../../components/TitleBlock';
import Catalog from '../../containers/Catalog';
import FormDefault from '../../containers/FormDefault';

import * as mainActions from '../../actions/main.js';
import { urls } from '../../constants';
import Breadcrumbs from "../../components/Breadcrumbs";
import isNotEmpty from "../../utils/is-not-empty";

class Models extends Component {
    componentDidMount() {
        const { routePath } = this.props;
    
        if (
            !this.props.mainStore.fetchResult ||
            !this.props.mainStore.fetchResult.hasOwnProperty(
                routePath
            )
        ) {
            this.props.mainActions.fetchQuery({
                type: 'GET',
                url: urls.models,
                alias: routePath
            });
        }
    }

    render() {
        const { mainStore, routePath } = this.props,
            { fetchResult } = mainStore;

        return (
            <Fragment>
                {fetchResult[routePath] && (
                    <Fragment>
    
                        {isNotEmpty(fetchResult[routePath].breadcrumbs) &&
                            <Breadcrumbs links={fetchResult[routePath].breadcrumbs} />
                        }
    
    
                        {isNotEmpty(fetchResult[routePath].title) &&
                            <SectionGrid>
                                <TitleBlock {...fetchResult[routePath].title} />
                            </SectionGrid>
                        }
    
                        <SectionGrid>
                            <Catalog />
                        </SectionGrid>
    
                        {isNotEmpty(fetchResult[routePath].form) &&
                            <SectionGrid last={true}>
                                <FormDefault
                                    theme="filled"
                                    {...fetchResult[routePath].form}
                                />
                            </SectionGrid>
                        }
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ mainStore }) {
    return { mainStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Models);
