import styles from '../constants/styles';

// [styles.bp(1400)]: {
//
// },
// [styles.bp(1100)]: {
//
// },
// [styles.bp(900)]: {
//
// },
// [styles.bp(550)]: {
//
// },
const { tgb } = styles.fontFamily;

let fonts = {
    h1: {
        fontFamily: tgb,
        fontSize: ['96px', '76px', '64px', null, '34px'],
        lineHeight: ['111px', '88px', '74px', null, '39px'],
        fontWeight: 'bold'
    },
    h2: {
        fontFamily: tgb,
        fontSize: ['64px', '46px', '40px', null, '28px'],
        lineHeight: ['74px', '53px', '46px', null, '32px'],
        fontWeight: 'bold'
    },
    h3: {
        fontFamily: tgb,
        fontSize: ['36px', '24px', '20px', null, '18px'],
        lineHeight: ['42px', '28px', '23px', null, '21px'],
        fontWeight: 'bold'
    },
    p0: {
        fontFamily: tgb,
        fontSize: ['90px', '65px', '50px'],
        lineHeight: ['104px', '75px', '58px'],
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    p1: {
        fontFamily: tgb,
        fontSize: ['58px', '40px', '35px', null, '24px'],
        lineHeight: ['67px', '46px', '41px', null, '28px'],
        fontWeight: 'bold'
    },
    p2: {
        fontSize: ['32px', '22px', null, null, '16px'],
        lineHeight: ['53px', '36px', null, null, '19px'],
        letterSpacing: '0.055em'
    },
    p3: {
        fontSize: ['18px', '14px'],
        lineHeight: ['27px', '21px'],
        letterSpacing: '0.055em'
    },
    p4: {
        fontSize: ['16px', '13px'],
        lineHeight: ['26px', '19px'],
        letterSpacing: '0.055em'
    },
    p5: {
        fontSize: ['14px', '12px'],
        lineHeight: ['23px', '20px'],
        letterSpacing: '0.055em',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    p6: {
        fontFamily: tgb,
        fontSize: ['18px', '16px'],
        lineHeight: ['21px', '19px'],
        fontWeight: 'bold'
    },
    p7l: {
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.055em'
    },
    p7r: {
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 'normal'
    },
    counter: {
        fontSize: '11px',
        lineHeight: '18px',
        letterSpacing: '0.27em',
        textTransform: 'uppercase',
        textAlign: 'right',
        fontWeight: 'bold'
    },
    b1: {
        fontSize: ['18px', '16px'],
        lineHeight: ['30px', '26px'],
        fontWeight: 'normal'
    },
    b2: {
        fontSize: '14px',
        lineHeight: '23px',
        fontWeight: 'normal'
    },
    placeholder: {
        fontSize: ['16px', '14px', null, null, '12px'],
        lineHeight: ['26px', '23px', null, null, '16px'],
        fontWeight: 'normal'
    },
    labelForm: {
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.055em',
        fontWeight: 'normal'
    },
    nav: {
        fontSize: [32, 24, null, null, 18],
        fontWeight: 300,
        lineHeight: ['1.64'],
        letterSpacing: '0.045em'
    }
};

export default fonts;
