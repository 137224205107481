import { actions } from '../constants';


export function pushUser(data) {
    return {
        type: actions.ACCOUNT.PUSH_USER,
        data
    };
}
export function updateUser(data) {
    return {
        type: actions.ACCOUNT.UPD_USER,
        data
    };
}
export function pushOrders(data) {
    return {
        type: actions.ACCOUNT.PUSH_ORDERS,
        data
    };
}