import React from "react";
import { default as st } from './style';

class CustomSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showInner: false
        }
    }

    onSelectClick = () => {
        const { showInner } = this.state;

        this.setState({
            showInner: !showInner
        });
    };

    getActiveOptionText = (list, active) => {
        let text = null;

        for (let i = 0; i < list.length; i++) {
            const { value, label } = list[i];

            if (value === active) text = label;
        }

        return text ? text : list[0].label
    };

    onClick = (func) => {
        this.setState({
            showInner: false
        });

        func();
    };

    render() {
        const
            { list, onSelect, active } = this.props,
            { showInner } = this.state,
            mod = showInner ? '_active' : '';

        return (
            <div className={`${st} ${mod}`}>
                <div className={`${st}__select`} onClick={this.onSelectClick}>
                    {this.getActiveOptionText(list, active)}
                </div>
                <div className={`${st}__inner`}>
                    {
                        list.map(({ value, label }, key) => {
                            const  activeMod = value === active ? '_active' : '';

                            return (
                                <div key={key} className={`${st}__option ${activeMod}`} onClick={() => this.onClick(onSelect.bind(this, value))}>
                                    {label}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default CustomSelect;
