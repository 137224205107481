import fetch from "isomorphic-fetch";

export function fetchQuery(obj) {
    const { origin } = window.location;

    let params = {},
        { options, type, data, url, error } = obj,
        request = new Request(url, options);

    data = data || {};
    if (!origin.match('localhost')) {
        url = `${origin}${url}`;
    }
    if (type === 'GET') {
        params = data;
    }

    url = new URL(url);

    Object.keys(params).forEach(key =>
        url.searchParams.append(key, params[key])
    );
    return dispatch => {
        dispatch(fetchRequest(data));
        return fetch(request)
            .then(response => {
                if (response.ok) return response.json();
                throw response.json();
            })
            .then(json => {
                if (json) {
                    dispatch(fetchSuccess(json));
                }
            })
            .catch(err => {
                dispatch(fetchFailure(err));
                err.then(data => {
                    error && error.call(data);
                });
            });
    };
}

export function fetchRequest(data) {
    return {
        type: "FILTER_FETCH_REQUEST",
        data
    };
}
export function fetchFailure(error) {
    return {
        type: "FILTER_FETCH_FAILURE",
        error
    };
}
export function fetchSuccess(response) {
    return {
        type: "FILTER_FETCH_SUCCESS",
        response
    };
}
