import React, { Component } from 'react';
import { css } from 'emotion';
import { default as st } from './style';
import parse from "html-react-parser";
import { isMobile } from 'react-device-detect';

export default class InfoWindow extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showWindow: false,
	        position: {}
        };
        
        this.baseOffset = 20;
        this.contentWindow = React.createRef();
        this.wrapEl = React.createRef();
    }
    
    // componentDidMount() {
    // 	this.setInfoWindowsPositions();
    // }
    
    setInfoWindowsPositions = () => {
    
    };
	
	toggleActive = (isActive) => {
        const
	        { showWindow } = this.state,
	        newState = {
		        showWindow: typeof isActive !== 'undefined' ? isActive : !showWindow
	        };
        
        if (isActive) {
        	const
		        windowWidth = document.body.clientWidth,
		        wrapElRect = this.wrapEl.current.getBoundingClientRect(),
		        contentWindowRect = this.contentWindow.current.getBoundingClientRect(),
		        
		        isContain = (windowWidth - (wrapElRect.left + contentWindowRect.width)) >= this.baseOffset,
		        
		        position = {
			        right: isContain ? 'auto' : wrapElRect.right - windowWidth + this.baseOffset,
			        left: isContain ? -this.baseOffset : 'auto'
		        };
        	
	        newState.position = position;
        }
        
        this.setState(newState);
    };
    
    render() {
	    const
		    { showWindow, position } = this.state,
		    { children, className, content, mod='' } = this.props,
		    activeMod = showWindow ? '_active' : '',
		    positionStyleObj = css({
			    ...position
		    });
        
        return (
            <div ref={this.wrapEl} className={`${st} ${className} ${activeMod}`}>
                {isMobile
                    ?
                    <div className={`${st}__children-wrap`} onClick={this.toggleActive.bind(this, !showWindow)} onMouseLeave={this.toggleActive.bind(this, false)}>
                        {children}
                    </div>
                    :
                    <div className={`${st}__children-wrap`} onMouseEnter={this.toggleActive.bind(this, true)} onMouseLeave={this.toggleActive.bind(this, false)}>
                        {children}
                    </div>
                }

    
                <div ref={this.contentWindow} className={`${st}__content ${mod} ${positionStyleObj}`}>
                    <div className={`${st}__top`}>
	                    {parse(content.main)}
                    </div>
                    
	                {content.bottom &&
		                <div className={`${st}__bottom`}>
			                <div className={`${st}__label`}>
				                {content.bottom.label}
			                </div>
			                <div className={`${st}__text`}>
				                {content.bottom.text}
			                </div>
		                </div>
	                }
                </div>
            </div>
        )
    }
}