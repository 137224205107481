import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { default as st } from './style';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ButtonInner extends Component {
    render() {
        let { text, icon } = this.props;
        return (
            <div className={`${st}__wrapper`}>
                {icon && <i className={`${st}__icon ${icon}`} />}
                {text}
            </div>
        );
    }
}

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.mouse_handler = this.mouse_handler.bind(this);
        this.btn = React.createRef();
        this.state = {
            left: 0,
            top: 0
        };
    }

    mouse_handler = e => {
        let el_event = e.nativeEvent,
            btn_coords = ReactDOM.findDOMNode(
                this.btn.current
            ).getBoundingClientRect(),
            rel_x = el_event.x - btn_coords.x,
            rel_y = el_event.y - btn_coords.y;

        this.setState({
            left: rel_x,
            top: rel_y
        });
    };

    base_tag = tag => {
        switch (tag) {
            case 'link':
                return Link;
            case 'button':
                return 'button';
            default:
                return 'div';
        }
    };

    render() {
        const {
                tag,
                href,
                text,
                mod,
                icon,
                stretch,
                onClick,
                bordered
            } = this.props,
            { top, left } = this.state;
        
        const circle_styles = {
                left: left,
                top: top
            },
            root_class = `${st} ${mod ? st + mod : ''}${
                bordered ? ` ${st}_bordered` : ''
            }${stretch ? ` ${st}_stretch` : ''}`;

        const BaseTag = this.base_tag(tag);

        return (
            <BaseTag
                className={root_class}
                to={href}
                title={text}
                onMouseEnter={this.mouse_handler}
                onMouseLeave={this.mouse_handler}
                ref={this.btn}
                onClick={onClick}
            >
                <div className={`${st}__circle`} style={circle_styles} />
                <ButtonInner text={text} icon={icon} />
            </BaseTag>
        );
    }
}

Button.propTypes = {
    tag: PropTypes.string,
    mod: PropTypes.string,
    text: PropTypes.string.isRequired,
    href: PropTypes.string,
    icon: PropTypes.string,
    stretch: PropTypes.bool,
    onClick: PropTypes.func,
    bordered: PropTypes.bool
};
