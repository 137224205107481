import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as mainActions from '../../actions/main.js';
import { urls } from '../../constants';

import SectionGrid from '../../components/SectionGrid';
import NotFoundSection from '../../components/NotFoundSection';
import isNotEmpty from "../../utils/is-not-empty";

const alias = 'not-found';

class Contacts extends Component {
    componentDidMount() {
        if (
            !this.props.mainStore.fetchResult ||
            !this.props.mainStore.fetchResult.hasOwnProperty(alias)
        ) {
            this.props.mainActions.fetchQuery({
                type: 'GET',
                url: urls.not_found,
                alias: alias
            });
        }
    }
    render() {
        const { mainStore } = this.props,
            { fetchResult } = mainStore;

        return (
            <Fragment>
                {fetchResult[alias] && (
                    <Fragment>
                        {isNotEmpty(fetchResult[alias]) &&
                            <SectionGrid first={true} last={true}>
                                <NotFoundSection {...fetchResult[alias]} />
                            </SectionGrid>
                        }
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ mainStore }) {
    return { mainStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contacts);
