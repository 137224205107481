import React, { Component } from "react";
import { default as st } from "./style";
import Image from "../Image";
import Slider from "react-slick";
import { styles } from "../../constants";
import { Link } from "react-router-dom";

export default class GalleryLinksSlider extends Component {
    constructor(props) {
        super(props);

        this.settings = {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: true,
            speed: 1000,
            arrows: true,
            dots: false,
            fade: false,
            centerPadding: "0",
            responsive: [
                {
                    breakpoint: styles.breakpoints[1100] + 1,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: styles.breakpoints[900] + 1,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: styles.breakpoints[550] + 1,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                        dots: true
                    }
                }
            ]
        };

        this.disableLinks = false;
        this.clickPos = 0;
    }

    toggleLinks = bool => {
        this.disableLinks = bool;
    };

    handleOnMouseDown = e => {
        this.clickPos = e.pageX;
    };

    handleClick = e => {
        const diff = Math.abs(this.clickPos - e.pageX) > 5;

        if (this.disableLinks || diff) e.preventDefault();
    };

    renderSlides = images => {
        return images.map((item, key) => {
            const { img, title, link } = item;

            return (
                <Link
                    to={link}
                    key={key}
                    className={`${st}__item`}
                    onMouseDown={e => this.handleOnMouseDown(e)}
                    onClick={e => this.handleClick(e)}
                    draggable="false"
                >
                    <div className={`${st}__img-wrap`}>
                        <Image src={img} />
                    </div>
                    <span className={`${st}__subtitle`}>{title}</span>
                </Link>
            );
        });
    };

    render() {
        const { list, title } = this.props;
        
        if (list.length < this.settings.slidesToShow) this.settings.infinite = false;

        return (
            <div className={st}>
                <h2 className={`${st}__title`}>{title}</h2>

                <Slider
                    beforeChange={this.toggleLinks.bind(this, true)}
                    onSwipe={this.toggleLinks.bind(this, true)}
                    afterChange={this.toggleLinks.bind(this, false)}
                    className={`${st}__slider`}
                    {...this.settings}
                >
                    {this.renderSlides(list)}
                </Slider>
            </div>
        );
    }
}
