import React, { Component } from 'react';
import { default as st } from './style';
import {InView} from 'react-intersection-observer';
import Image from "../Image";
import AnimatedFrame from "../AnimatedFrame";
import parse from "html-react-parser";
import PropTypes from 'prop-types';

class Features extends Component {
    render() {
        const { title, img, list } = this.props;
        
        return (
            <InView threshold={0.4}>
                {({inView, ref}) => {
                    const activeMod = inView ? `${st}_active` : '';
    
                    return (
                        <div className={`${st} ${activeMod}`} ref={ref}>
                            <div className={`${st}__frame-wrap`}>
                                <AnimatedFrame active={inView} />
                            </div>
                            
                            <div className={`${st}__title-wrap container_narrow`}>
                                <h2 className={`${st}__title`}>{parse(title)}</h2>
                            </div>
                            
                            <div className={`${st}__table`}>
                                <div className={`${st}__col ${st}__col_left`}>
                                    <div className={`${st}__img-wrap`}>
                                        <Image src={img} />
                                    </div>
                                </div>
                                <div className={`${st}__col ${st}__col_right`}>
                                    <div className={`${st}__list`}>
                                        {
                                            list.map((item, key) => {
                                                const { icon, text } = item;
            
                                                return (
                                                    <div key={key} className={`${st}__item`}>
                                                        <div className={`${st}__icon`}>
                                                            <Image src={icon} />
                                                        </div>
    
                                                        <div className={`${st}__text-wrap`}>
                                                            <span className={`${st}__text`}>{text}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

Features.propTypes = {
    title: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
};

export default Features;
