import { css } from "emotion";
import { styles, grid } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        position: "relative",
        display: "block",
        marginBottom: grid.default[9],
        "&__frame-wrap": {
            ...styles.absolute(),
            width: "100%",
            height: "100%",
            zIndex: 1
        },

        "&__svg-wrap": {
            display: "block",
            position: "relative",
            paddingTop: [
                grid.default[4][0],
                grid.default[4][1],
                grid.default[4][2],
                grid.default[4][3],
                0
            ],
            paddingBottom: [
                grid.default[4][0],
                grid.default[4][1],
                grid.default[4][2],
                grid.default[4][3],
                0
            ],
            zIndex: 2,
            width: [null, "72%", "79.5%", "87.5%", "100%"],

            svg: {
                width: "100%",
                height: "auto"
            }
        },

        ".graphic-line": {
            transform: ["scaleY(0)", null, null, null, "scaleX(0)"],
            transformOrigin: ["50% 18.5%", null, null, null, "74% 50%"],
            transition: "all .3s ease"
        },

        "&_active": {
            ".graphic-line": {
                transform: ["scaleY(1)", null, null, null, "scaleX(1)"],
                transition: "all 3s ease",
                transitionDelay: ".5s"
            }
        },
        "&_active &": {
            "&__img-wrap": {
                "&::before": {
                    transform: "translateX(101%)"
                }
            },

            "&__content": {
                transform: "translateY(0)",
                opacity: 1
            }
        }
    })
);
