import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';

const { mq, colors } = styles;



export default css(
    mq({
        position: 'relative',
        display: 'block',
        paddingTop: grid.default[6],
        marginBottom: grid.default[9],
    
        '&__frame-wrap': {
            display:'block',
            position:'absolute',
            top: 0,
            left: 0,
            width: ['calc(100% / 9 *3)', null, null, 'calc(100% / 6 *3)', 200],
            height: [780, 550, 780, 470, 270],
            maxHeight: ['calc(100% - 147px)', 'calc(100% - 57px)'],
            zIndex: -1
        },

        '&__title-wrap': {
            padding: [null, null, null, '0 calc(100% / 6 / 2) 0 calc(100% / 6)', 0]
        },
        
        '&__title': {
            ...fonts.h2
        },
    
        '&__img-wrap': {
            position: 'relative',
            display: 'block',
            width: "100%",
            overflow: 'hidden',
            
            '&::before': {
                content: '""',
                display: 'block',
                ...styles.absolute(),
                width: '100%',
                height: '100%',
                background: colors.white,
                transition: '.5s ease',
                zIndex: 3
            },
        
            img: {
                display: 'block',
                width: "100%",
                height: 'auto',
                zIndex: 1
            }
        },
        
        '&__list': {
            display: 'flex',
            flexDirection: ['column', null, 'row', null, 'column'],
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: ['flex-start', null, 'space-between', null, 'flex-start'],
            maxWidth: [null, 270, 'none']
        },
        
        '&__item': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginBottom: grid.default[4],
            transform: 'translateY(30px)',
            opacity: 0,
            transition: '.3s ease',
            ...styles.setDelays({delay: 100, count: 10}),
            width: ['100%', null, 'calc(100% / 7 * 3)', '50%', '100%'],
        
            '&:last-of-type': {
                marginBottom: 0
            },
        },
    
        '&__icon': {
            position: 'relative',
            width: 46,
            height: 35,
            flex: '0 0 auto',
        
            img: {
                ...styles.absolute(),
                ...styles.of({fit: 'contain'}),
                display: 'block'
            }
        },
    
        '&__text-wrap': {
            display: 'block',
            position: 'relative',
            paddingTop: grid.default[0],
            maxWidth: [null, null, null, 180, 'none']
        },
    
        '&__text': {
            display: 'block',
            position: 'relative',
            ...fonts.p6,
            paddingLeft: 20,
        },
        
        '&__container': {
            position: 'relative',
            display: 'block',
            width: 'calc(100% / 9 * 7)',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        
        '&__table': {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            zIndex: 1
        },
        
        '&__col': {
            '&_left': {
                paddingTop: [140, 50, null,  null, 35],
                width: ['calc(100% / 9 * 3)', 'calc(100% / 9 * 3 - 20px)', 'calc(100% / 9 * 6)', 'calc(100% / 6 * 3)', 200],
                margin: [null, null, '0 calc(100% / 9)', '0 calc(100% / 6) 0 0', '0 0 0 auto']
            },
            
            '&_right': {
                paddingTop: [70, null, null,  null, 50],
                width: ['calc(100% / 9 * 3)', null, 'calc(100% / 9 * 7)', '100%'],
                margin: ['0 0 0 calc(100% / 9)', '0 0 0 calc(100% / 9 + 20px)', '0 calc(100% / 9) 0 0', 0]
            }
        },
        
        '&_active &': {
            '&__img-wrap': {
                '&::before': {
                    transform: 'translateX(101%)'
                }
            },
            
            '&__item': {
                transform: 'translateY(0)',
                opacity: 1,
            }
        }
    })
);
