import React from "react";
import { default as st } from "./style";
import { innerHTML } from "../../helpers/functions";
import { useInView } from "react-intersection-observer";
import MediaQuery from "react-responsive";

import Slider from "react-slick";
import Image from "../Image/";
import LinkCustom from "../LinkCustom";
import TextCollapse from "../../components/TextCollapse";

import { styles } from "../../constants";

const { breakpoints } = styles;

export default props => {
    const [ref, inView] = useInView({
            threshold: 0.4
        }),
        settings = {
            fade: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: breakpoints["550"] + 1,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                }
            ]
        },
        { content, reverse } = props;
    return (
        <Slider {...settings} className={`${st}__wrap`}>
            {content.map((item, key) => (
                <div
                    key={key}
                    className={`${st} ${inView ? ` ${st}_active` : ""}  ${
                        reverse ? ` ${st}_reverse` : ""
                    }`}
                    ref={ref}
                >
                    <div className={`${st}__side ${st}__side_left`}>
                        {item.image && <Image src={item.image} />}
                    </div>
                    <div className={`${st}__side ${st}__side_right`}>
                        <h3>{item.title}</h3>
                        <MediaQuery maxWidth={breakpoints["550"]}>
                            {matches =>
                                matches ? (
                                    <div className={`${st}__side ${st}__text`}>
                                        <TextCollapse
                                            maxLine={8}
                                            text={item.text}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={`${st}__side ${st}__text`}
                                        dangerouslySetInnerHTML={innerHTML(
                                            item.text
                                        )}
                                    />
                                )
                            }
                        </MediaQuery>

                        <div className={`${st}__link`}>
                            <LinkCustom
                                to={item.link.href}
                                title={item.link.text}
                                icon="arrow-right-small"
                            />
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
};
