import React, { Component } from "react";
import { default as st } from "./style";

import Image from "../../components/Image";
import LinkCustom from "../../components/LinkCustom";

export default class NotFoundSection extends Component {
    render() {
        const { title, desc, bg } = this.props;
        return (
            <div className={st}>
                <div className={`${st}__code`}>
                    {bg && <Image className={`${st}__code-bg`} src={bg} />}
                    <img
                        className={`${st}__code-number`}
                        src={require("../../images/svg/404.svg")}
                        alt={""}
                    />
                </div>
                <div className={`${st}__content`}>
                    {title && <h6 className={`${st}__title`}>{title}</h6>}
                    {desc && <p className={`${st}__desc`}>{desc}</p>}
                </div>
                <div className={`${st}__link`}>
                    <LinkCustom
                        title="На главную"
                        to="/"
                        icon="arrow-right-small"
                    />
                </div>
            </div>
        );
    }
}
