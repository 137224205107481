import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        '&__title': {
            ...fonts.p5
        },
        '&__row': {
            display: 'flex',
            border: `1px solid #f6f3f7`,
            marginTop: grid.default[1]
        },
        '&__select-wrapper': {
            marginTop: grid.default[1]
        },
        '&__row-item': {
            flex: '1 0 auto',
            borderRight: `1px solid #f6f3f7`,
            width: '20%',
            ':last-of-type': {
                border: 'none'
            }
        }
    })
);
