import { css } from 'emotion';
import { styles, grid } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        display: [null, null, 'flex'],
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        '&__item': {
            width: ['100%', null, 'calc(50% - 10px)', '100%'],
            marginTop: grid.default[3],
            ':first-of-type': {
                marginTop: 0
            }
        }
    })
);
