import React, { Component } from "react";
import { default as st } from "./style";
import MediaQuery from "react-responsive";
import { styles } from "../../constants";

import SelectLinkFieldset from "../../components/SelectLinkFieldset";

const { breakpoints } = styles;

export default class LinkFieldset extends Component {
    render() {
        return (
            <div className={st}>
                <div className="container container_right col">
                    <MediaQuery maxWidth={breakpoints["550"]}>
                        {matches =>
                            matches ? (
                                <div className={`${st}__select`}>
                                    <SelectLinkFieldset
                                        options={this.props.data}
                                    />
                                </div>
                            ) : (
                                <div className={`${st}__row`}>
                                    {this.props.data.map(
                                        ({ anchor, label }, key) => (
                                            <a
                                                key={key}
                                                className={`${st}__item`}
                                                href={`#${anchor}`}
                                            >
                                                <span>{label}</span>
                                                <i
                                                    className={`${st}__icon icon-arrowstroke`}
                                                />
                                            </a>
                                        )
                                    )}
                                </div>
                            )
                        }
                    </MediaQuery>
                </div>
            </div>
        );
    }
}
