import React, { Component } from "react";
import { default as st } from "./style";
import TextBlock from "../TextBlock";
import PropTypes from "prop-types";
import Image from "../Image";
import { InView } from "react-intersection-observer";

class IllustratedDescription extends Component {
    render() {
        const { img, title, content } = this.props;

        return (
            <InView threshold={0.4}>
                {({ inView, ref }) => {
                    const activeMod = inView ? ` ${st}_active` : "";

                    return (
                        <div className={`${st} ${activeMod}`} ref={ref}>
                            <div className={`${st}__col ${st}__col_left`}>
                                <div className={`${st}__img-wrap`}>
                                    <Image src={img} />
                                </div>
                            </div>

                            <div className={`${st}__col ${st}__col_right`}>
                                <div className={`${st}__content`}>
                                    {title && (
                                        <h3 className={`${st}__title`}>
                                            {title}
                                        </h3>
                                    )}

                                    {content && <TextBlock content={content} />}
                                </div>
                            </div>
                        </div>
                    );
                }}
            </InView>
        );
    }
}

IllustratedDescription.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string,
    content: PropTypes.string
};

export default IllustratedDescription;
