import { css } from 'emotion';
import { styles, fonts } from '../../constants';

const { mq, colors, fontFamily } = styles;

export default css(
    mq({
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: 50,
        '&__link': {
            position: 'relative',
            display: 'block',
            width: [
                'calc(100% / 9 * 7)',
                'calc(100% / 9 * 7.5 + 5px)',
                '96%',
                null,
                '100%'
            ],
            margin: [
                '0 auto',
                '0 auto 0 calc(100% / 9 * 0.5 - 5px)',
                '0 0 0 auto'
            ],
            padding: [null, null, null, null, '0 20px']
        },
        '&__header-img-block': {
            position: 'absolute',
            left: [
                'calc(-100% / 7)',
                'calc(-100% / 7 * .5 - 5px)',
                '-4.7%',
                null,
                0
            ],
            top: 0,
            transition: '.6s ease',
            transform: 'translateY(105%)',
            opacity: 0,
            height: '100%',
            width: ['80.7%', '93.2%', '93%', '96%', 'calc(100% - 20px)']
        },
        '&__header-img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        '&__title': {
            ...fonts.h2,
            position: 'relative',
            display: 'block',
            maxWidth: [570, 606],
            paddingLeft: [88, 67, 73, 82, 0],
            color: colors.white,
            margin: [null, '0 auto'],
            ':before': {
                content: "'\\e90b'",
                position: ['absolute', null, null, null, 'relative'],
                left: 0,
                top: ['1.5em', '.8em', null, null, 0],
                transition: '.3s ease',
                display: [null, null, null, null, 'block'],
                marginBottom: [null, null, null, null, 9],
                fontFamily: fontFamily.icon,
                lineHeight: '1',
                fontSize: [20, null, null, null, 16],
                fontWeight: 'normal'
            }
        },
        '&__wrapper': {
            position: 'relative',
            padding: [
                '45px 72px 98px',
                '63px 72px 121px',
                '65px 72px 104px',
                '50px 49px 81px',
                '24px 0px 57px'
            ]
        },
        '&__border': {
            zIndex: [null, null, null, null, -1],
            position: 'absolute',
            left: 0,
            top: [-50, null, -40, -34, -20],
            pointerEvents: 'none',
            width: [
                '100%',
                null,
                'calc(100% + 10px)',
                null,
                'calc(100% + 20px)'
            ],
            height: ['100%', '105%', '104%', null, '100%']
        },
        '&__border-line': {
            position: 'absolute',
            overflow: 'hidden',
            ':after,:before': {
                content: "''",
                position: 'absolute',
                display: 'block',
                transition: '.8s ease',
                backgroundColor: colors.lilaLight
            },
            ':nth-of-type(odd)': {
                width: '100%',
                height: [15, null, 10],
                ':after,:before': {
                    top: 0,
                    width: '50%',
                    height: '100%'
                },
                ':before': {
                    left: 0,
                    transform: 'translateX(-105%)'
                },
                ':after': {
                    right: 0,
                    transform: 'translateX(105%)'
                }
            },
            ':nth-of-type(even)': {
                width: [15, null, 10],
                height: '100%',
                ':after,:before': {
                    left: 0,
                    width: '100%',
                    height: '50%'
                },
                ':before': {
                    top: 0,
                    transform: 'translateY(-105%)'
                },
                ':after': {
                    bottom: 0,
                    transform: 'translateY(105%)'
                }
            },
            ':nth-of-type(1)': {
                left: 0,
                top: 0
            },
            ':nth-of-type(2)': {
                right: 0,
                top: 0
            },
            ':nth-of-type(3)': {
                right: 0,
                bottom: 0
            },
            ':nth-of-type(4)': {
                left: 0,
                bottom: 0
            }
        },
        '&_active &': {
            '&__border-line': {
                ':after,:before': {
                    transform: 'translate(0,0)'
                }
            },
            '&__header-img-block': {
                transform: 'translateY(0)',
                opacity: 1
            }
        },
        '&__link:hover &': {
            '&__title:before': {
                transform: 'translateX(5px)'
            }
        }
    })
);
