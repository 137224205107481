import { css, keyframes } from 'emotion';
import { styles, grid, fonts } from '../../constants';

const { mq } = styles;

const img_anim = keyframes({
        '0%': {
            width: '0%'
        },
        '100%': {
            width: '100%'
        }
    }),
    cont_anim = keyframes({
        '0%': {
            opacity: 0,
            transform: 'translateY(20px)'
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0)'
        }
    });

export default css(
    mq({
        paddingBottom: grid.default[9],
        position: 'relative',
        zIndex: 2,
        '&__slide': {
            display: 'flex !important',
            flexDirection: ['row', null, null, 'column'],
            justifyContent: 'space-between',
            paddingTop: [null, null, null, '50px', '35px']
        },
        '&__img-animation': {
            position: 'relative',
            overflow: 'hidden',
            animation: `${img_anim} 1s ease 1.5s both`,
            transformOrigin: '0 50%',
            width: 0,
            height: '100%'
        },
        '&__img-wrapper': {
            position: 'relative',
            overflow: 'hidden',
            transformOrigin: '0 50%',
            transition: '.6s ease',
            width: 0,
            height: '100%',
            img: {
                width: ['630px', '445px', '320px', '100%'],
                height: ['630px', '445px', '435px', 424, 240],
                objectFit: 'cover'
            }
        },
        '&__info-block': {
            width: [
                'calc(100% - 630px)',
                'calc(100% - 445px)',
                'calc(100% - 310px)',
                'calc(100% - 165px)',
                '100%'
            ],
            paddingLeft: ['100px', '83px', '40px', 0],
            paddingRight: ['55px', '47px', '36px', 0],
            paddingBottom: [null, null, null, 110, 30],
            marginLeft: [null, null, null, 'auto'],
            paddingTop: grid.default[12],
            display: 'flex',
            alignItems: 'center'
        },
        '&__content_animation': {
            animation: `${cont_anim} 1s ease 1.5s both`
        },
        '&__content-wrapper': {
            transition: '.6s ease',
            transform: 'translateY(20px)',
            opacity: 0
        },
        '&__header': {
            display: 'inline-block',
            marginBottom: grid.default[0],
            ...fonts.p1,
            color: styles.colors.greyDark,
            span: {
                color: styles.colors.lilaMiddle
            }
        },
        '&__description': {
            display: 'inline-block',
            marginBottom: grid.default[4],
            ...fonts.p2,
            color: styles.colors.greyDark
        },
        '&__link-line': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            cursor: 'pointer'
        },
        '&__link-img': {
            marginLeft: '20px'
        },
        '&__link': {
            ...fonts.b1,
            color: styles.colors.lila
        },
        '&__next-arrow': {
            right: 0,
            bottom: ['-18px', '-28px', '-38px', '5px'],
            paddingBottom: [null, null, '12px']
        },
        '&__prev-arrow': {
            right: 0,
            bottom: ['-90px', '-80px', null, '-37px'],
            paddingTop: [null, null, '12px']
        },
        '.slick-dots': {
            display: 'flex !important',
            justifyContent: 'center',
            position: 'absolute',
            bottom: '2px',
            left: 0,
            right: 0,

            button: {
                transform: 'rotate(45deg)',
                margin: '0 15px',
                width: '9px',
                height: '9px',
                border: `1px solid ${styles.colors.lila}`,
                padding: 0,
                ':before': {
                    content: 'none'
                }
            },
            '.slick-active': {
                button: {
                    backgroundColor: styles.colors.lila
                }
            }
        },
        '.slick-arrow': {
            cursor: 'pointer'
        },
        '.slick-slide': {
            position: 'relative',
            opacity: '1!important'
        },
        '.slick-active': {
            zIndex: 1
        },
        '.slick-active &': {
            '&__img-wrapper': {
                transitionDelay: '.7s',
                width: '100%'
            },
            '&__content-wrapper': {
                transitionDelay: '.7s',
                transform: 'translateY(0)',
                opacity: 1
            }
        },
        '&__dots': {
            zIndex: 5,
            position: 'absolute',
            left: ['-18.1%', '-16.3%', '-5.3%', -51],
            top: ['50%', null, null, '18%'],
            transform: ['translateY(-50%)', null, null, 'none']
        }
    })
);
