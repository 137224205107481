import React, { Component } from 'react';
import { default as st } from './style';
import TextBlock from "../TextBlock";
import CustomSelect from "../CustomSelect";
import PropTypes from 'prop-types';
import {InView} from "react-intersection-observer";
import Image from "../Image";
import MediaQuery from "react-responsive/src/Component";

import { styles } from '../../constants';
const { breakpoints } = styles;

class TabsContent extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activeTabId: null
        }
    }
    
    toggleTabs = (id) => {
        this.setState({
            activeTabId: id
        })
    };
    
    getActiveMod = (activeTabId, id, key) => {
        const isMatch = activeTabId ? id === activeTabId : key === 0;
        return isMatch ? ' _active' : '';
    };
    
    renderNav = (nav) => {
        const { activeTabId } = this.state;
        
        return (
            nav.map(({value, label}, key) => {
                const mod = this.getActiveMod(activeTabId, value, key);
                
                return (
                    <div key={key} className={`${st}__link${mod}`} onClick={this.toggleTabs.bind(this, value)}>
                        {label}
                    </div>
                )
            })
        )
    };
    
    render() {
        const
            { nav, tabs } = this.props,
            { activeTabId } = this.state;
        
        return (
            <InView threshold={0.4}>
                {({inView, ref}) => {
                    const activeMod = inView ? ` ${st}_active` : '';
            
                    return (
                        <div className={`${st} ${activeMod}`} ref={ref}>
                            <div className={`${st}__nav`}>
                                <MediaQuery maxWidth={breakpoints['900']}>
                                    {matches => matches
                                        ?
                                        <CustomSelect list={nav} active={activeTabId} onSelect={this.toggleTabs} />
                                        :
                                        this.renderNav(nav)
                                    }
                                </MediaQuery>
                            </div>
                            
                            <div className={`${st}__tabs-wrap`}>
                                {
                                    tabs.map(({id, img, title, content}, key) => {
                                        const mod = this.getActiveMod(activeTabId, id, key);
                                        
                                        return (
                                            <div key={key} className={`${st}__tab${mod}`}>
                                                <div className={`${st}__col ${st}__col_left`}>
                                                    <div className={`${st}__img-wrap`}>
                                                        <Image src={img} />
                                                    </div>
                                                </div>
                                                <div className={`${st}__col ${st}__col_right`}>
                                                    <h3 className={`${st}__title`}>{title}</h3>
                                                    <TextBlock content={content} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                              
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

TabsContent.propTypes = {
    nav: PropTypes.array.isRequired,
    tabs: PropTypes.array.isRequired
};

export default TabsContent;
