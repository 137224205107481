import React, { Component } from 'react';
import { default as st } from './style';

export default class Dev extends Component {
    render() {
        return (
            <a
                className={`${st} col`}
                href="https://tutmee.ru/"
                target="_blank"
                rel="noopener noreferrer"
                title="Tutmee"
            >
                <span className={`${st}__title`}>
                    LTD Tutmee
                    <br /> Web development Agency
                </span>
                <span className={`${st}__img-wrapper`}>
                    <img src={require('../../images/svg/tutmee.svg')} alt="" />
                </span>
            </a>
        );
    }
}
