import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Image extends Component {
    render() {
        const { src, alt = '', className } = this.props;

        return (
            <img
                className={className}
                src={src}
                alt={alt}
            />
        );
    }
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string
};
