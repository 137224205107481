import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: ['row', null, null, null, 'row-reverse'],
        padding: [null, null, null, 0],
        '&__title': {
            ...fonts.p7l,
            display: 'block',
            transition: '.3s ease',
            marginRight: [10, null, null, null, 0],
            marginLeft: [null, null, null, null, 12],
            color: colors.white,
            textAlign: ['right', null, null, null, 'left'],
            br: {
                display: [null, null, null, null, 'none']
            }
        },
        '&__img-wrapper': {
            display: 'block',
            width: [27, null, null, null, 17],
            height: [42, null, null, null, 27],
            img: {
                width: '100%',
                height: '100%'
            }
        },
        ':hover &': {
            '&__title': {
                color: colors.lila
            }
        }
    })
);
