import React, { Component, Fragment } from "react";
import { default as st } from "./style";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import MediaQuery from "react-responsive";
import Slider from "react-slick";

import RotatedText from "../RotatedText";
import Image from "../Image";

import { styles } from "../../constants";

const { breakpoints } = styles;

class Compare extends Component {
    constructor(props) {
        super(props);

        this.settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            speed: 1000,
            arrows: false,
            fade: false,
            centerPadding: "0"
        };
    }

    renderRowContent(row, titles) {
        const { title, text } = row;

        return (
            <React.Fragment>
                <div
                    className={`${st}__col ${st}__col_left`}
                >
                    <MediaQuery maxWidth={breakpoints["550"]}>
                        <div className={`${st}__subtitle`}>
                            {titles.left}
                        </div>
                    </MediaQuery>

                    <div className={`${st}__text`}>
                        {text.left}
                    </div>
                </div>

                <div
                    className={`${st}__col ${st}__col_center`}
                >
                    <RotatedText
                        className={`${st}__row-title`}
                        text={title}
                    />
                </div>

                <div
                    className={`${st}__col ${st}__col_right`}
                >
                    <MediaQuery maxWidth={breakpoints["550"]}>
                        <div className={`${st}__subtitle`}>
                            {titles.right}
                        </div>
                    </MediaQuery>

                    <div className={`${st}__text`}>
                        {text.right}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { title, img, table } = this.props,
            { titles, rows } = table;

        return (
            <div className={`${st}`}>
                <div className={`${st}__title-wrap col container_narrow`}>
                    <h2 className={`${st}__title`}>{title}</h2>
                </div>
                <MediaQuery maxWidth={breakpoints["550"]}>
                    {matches =>
                        !matches && (
                            <InView>
                                {({ inView, ref }) => {
                                    const activeMod = inView ? `_active` : "";

                                    return (
                                        <div
                                            className={`${st}__images-container col ${activeMod}`}
                                            ref={ref}
                                        >
                                            <div className={`${st}__img-col`}>
                                                <div
                                                    className={`${st}__img-wrap`}
                                                >
                                                    <Image src={img.left} />
                                                </div>
                                            </div>
                                            <div className={`${st}__img-col`}>
                                                <div
                                                    className={`${st}__img-wrap`}
                                                >
                                                    <Image src={img.right} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                            </InView>
                        )
                    }
                </MediaQuery>
                <div className={`${st}__table col`}>
                    <MediaQuery minWidth={breakpoints["550"] + 1}>
                        <InView threshold={0.4}>
                            {({ inView, ref }) => {
                                const activeMod = inView ? `_active` : "";

                                return (
                                    <Fragment>
                                        <div
                                            className={`${st}__titles ${activeMod}`}
                                            ref={ref}
                                        >
                                            <div
                                                className={`${st}__col ${st}__col_left`}
                                            >
                                                <div
                                                    className={`${st}__subtitle`}
                                                >
                                                    {titles.left}
                                                </div>
                                            </div>

                                            <div
                                                className={`${st}__col ${st}__col_right`}
                                            >
                                                <div
                                                    className={`${st}__subtitle`}
                                                >
                                                    {titles.right}
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                );
                            }}
                        </InView>
                    </MediaQuery>


                    <MediaQuery maxWidth={breakpoints["550"]}>
                        {matches => matches
                            ?
                                <Slider className={`${st}__slider`} {...this.settings}>
                                    {rows.map((row, key) => (
                                        <div key={key} className={`${st}__row`}>
                                            {this.renderRowContent(row, titles)}
                                        </div>
                                    ))}
                                </Slider>
                            :
                                rows.map((row, key) => (
                                    <InView key={key} threshold={0.3}>
                                        {({ inView, ref }) => {
                                            const activeMod = inView ? `_active` : "";

                                            return (
                                                <div
                                                    className={`${st}__row ${activeMod}`}
                                                    ref={ref}
                                                >
                                                    {this.renderRowContent(row, titles)}
                                                </div>
                                            );
                                        }}
                                    </InView>
                                ))
                        }
                    </MediaQuery>


                </div>
            </div>
        );
    }
}

Compare.propTypes = {
    title: PropTypes.string.isRequired,
    img: PropTypes.object.isRequired,
    table: PropTypes.object.isRequired
};

export default Compare;
