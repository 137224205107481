import React, {Component} from 'react';
import { default as st } from './style';
import Image from "../Image";
import PropTypes from "prop-types";

class ColorsList extends Component {
    render() {
        const
            { list, active=true } = this.props,
            activeMod = active ? ` ${st}_active` : '';
        
        return (
            <div className={`${st} ${activeMod}`}>
                {
                    list.map((item, key) => {
                        const { img, title, text } = item;
                        
                        return (
                            <div key={key} className={`${st}__item`}>
                                <div className={`${st}__preview`}>
                                    <Image src={img} />
                                </div>
        
                                <div className={`${st}__description`}>
                                    <div className={`${st}__title`}>
                                        {title}
                                    </div>
            
                                    <div className={`${st}__text`}>
                                        {text}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

ColorsList.propTypes = {
    list: PropTypes.array.isRequired
};

export default ColorsList;