import { css } from "emotion";
import { styles, fonts } from "../../constants";

const { mq, colors } = styles;

export default css(
	mq({
		"&::before, &__content": {
			opacity: "0",
			transform: "translateY(20px)"
		},

		"&::before": {
			content: '""',
			...styles.absolute(
				["calc(100% + 20px)", null, "calc(100% + 8px)"],
				"auto",
				"auto",
				"calc(50% - 12px)"
			),
			display: "block",
			zIndex: 10,
			width: 24,
			height: 17,
			borderStyle: "solid",
			borderWidth: "0 12px 17px 12px",
			borderColor: `transparent transparent ${colors.lilaMiddle} transparent `,
			boxSizing: "border-box"
			// transitionDelay: '0s'
		},

		"&__children-wrap": {
			position: "relative",
			display: "block",
			width: "100%",
			zIndex: 1
		},

		"&__content": {
			...styles.absolute(
				["calc(100% + 35px)", null, "calc(100% + 20px)"],
				"auto",
				"auto",
				"-100vw"
			),
			display: "block",
			width: [420, 313],
			maxWidth: [
				"calc(100vw - 60px)",
				null,
				null,
				"calc(100vw - 180px)",
				"calc(100vw - 80px)"
			],
			pointerEvents: "none",

			"&::before, &::after": {
				content: '""',
				display: "block"
			},

			"&::before": {
				content: '""',
				...styles.absolute(),
				display: "block",
				width: "100%",
				height: "100%",
				background: colors.lilaMiddle,
				zIndex: 2
			}
		},

		"&__top, &__bottom": {
			position: "relative",
			display: "block",
			width: "100%",
			zIndex: 5
		},

		"&__top": {
			padding: [30, null, null, null, 15],
			boxSizing: "border-box",
			...fonts.p3,
			color: colors.white
		},

		"&__bottom": {
			padding: ["22px 30px", null, null, null, 20],
			background: colors.lilaLight,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "baseline"
		},

		"&__label": {
			...fonts.p4
		},

		"&__text": {
			...fonts.p5,
			paddingLeft: 20,
			color: colors.lila
		},

		"&__btn": {
			...styles.absolute(),
			display: "block",
			width: "100%",
			height: "100%",
			zIndex: 3
		},

		"&._active": {
			zIndex: 10,

			"&::before": {
				opacity: "1",
				transform: "translateY(0)",
				transition: "all .3s ease",
				transitionDelay: ".15s"
			}
		},

		"&._active &": {
			"&__content": {
				opacity: "1",
				transform: "translateY(0)",
				transition: "transform .3s ease, opacity .3s ease",
				transitionDelay: "0s"
			}
		}
	})
);
