import React, { Component } from "react";
import { default as st } from "./style";
import PropTypes from "prop-types";
import Image from "../Image";

class PersonInfo extends Component {
    render() {
        const { img, title, label } = this.props;

        return (
            <div className={`${st}`}>
                <div className={`${st}__img-wrap`}>
                    <Image src={img} />
                </div>
                <div className={`${st}__content`}>
                    {title && <p className={`${st}__title`}>{title}</p>}
                    {label && <p className={`${st}__label`}>{label}</p>}
                </div>
            </div>
        );
    }
}

PersonInfo.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string,
    label: PropTypes.string
};

export default PersonInfo;
