import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        height: [72, 58],
        '&__input': {
            position: 'absolute',
            left: 0,
            top: 0,
            display: 'block',
            opacity: 0,
            cursor: 'pointer',
            width: '100%',
            height: '100%'
        },
        '&__label': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            transition: '.3s ease',
            width: '100%',
            height: '100%',
            padding: '5px 20px',
            color: colors.rose,
            textAlign: 'center'
        },
        '&__label-text': {
            ...fonts.p5
        },
        '&__icons-row': {
            marginRight: '.5em'
        },
        '&__icon': {
            marginLeft: '.2em',
            fontSize: 20,
            ':first-of-type': {
                marginLeft: 0
            }
        },
        '&__input:checked ~ &': {
            '&__label': {
                backgroundColor: colors.lilaLight,
                color: colors.lila
            }
        }
    })
);
