import React from 'react';
import { default as st } from './style';

export default props => {
    return (
        <div
            className={`${st} ${props.position} slick-arrow`}
            onClick={props.onClick}
        >
            <i className={`${st}__icon icon-arrow-right-small`} />
        </div>
    );
};
