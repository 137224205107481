import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors, breakpoints } = styles;

export default css(
    mq({
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',

        '&__phone': {
            ...fonts.p6,
            ...styles.absolute('auto', 'auto', 40),
            transition: '.4s ease',
            opacity: 0,
            transitionDelay: '0s',
            color: colors.white,
        },

        '&__overlay': {
            position: 'absolute',
            left: 0,
            top: 0,
            pointerEvents: 'auto',
            background: colors.lila,
            width: '100%',
            height: '100%',
            '&-enter': {
                transform: 'translateX(-105%)'
            },
            '&-enter-active': {
                transform: 'translateX(0)',
                transition: 'transform .8s ease'
            },
            '&-exit': {
                transform: 'translateX(0)'
            },
            '&-exit-active': {
                transform: 'translateX(-105%)',
                transition: 'transform 1s ease .3s'
            }
        },

        '&__content': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            width: ['calc(100% / 2)', null, 'calc(100% / 12 * 8)', '100%'],
            height: '100%',
            margin: '0 auto',
            padding: [null, null, null, '0 80px', '0 30px'],
            '&_center': {
                alignItems: 'center'
            }
        },
        '&__col': {
            '&_left': {
                position: 'relative',
                width: ['58.2%', null, 'calc(100% / 8 * 5)', null, '100%'],
                padding: ['90px 0', null, null, '80px 0 40px', '60px 0 60px'],
            },
            '&_right': {
                width: ['41.8%', null, 'calc(100% / 8 * 3)'],
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        '&__nav-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            // height: '100%',
            margin: 'auto auto auto 0'
        },
        '&__scroll-wrap': {
            height: '100%',
            width: '100%',
            overflow: 'visible',
            display: 'flex',
    
            '.ScrollbarsCustom-Track': {
                opacity: 0,
                transition: '.4s ease'
            }
        },
        '&__popup-wrap': {
            height: '100%',
            width: '100%',
            overflow: 'visible',
            display: 'flex',
            padding: ['90px 0', null, null, '80px 0 40px', '60px 0 60px'],
        },
        '&__nav-item': {
            ...fonts.nav,
            position: 'relative',
            overflow: 'hidden',
            display: 'inline-block',
            transition: 'color .4s ease',
            opacity: 1,
            paddingRight: [70, 50, null, null, 35],
            marginTop: grid.default[4],
            color: colors.lilaLight,
            ':after': {
                content: "''",
                position: 'absolute',
                left: 0,
                top: 0,
                display: 'block',
                transform: 'translateY(105%)',
                background: colors.lila,
                width: '100%',
                height: '100%'
            },
            ':before': {
                content: "''",
                position: 'absolute',
                right: 0,
                top: '.9em',
                display: 'block',
                transition: '.4s ease',
                transform: 'translateX(20px)',
                opacity: 0,
                backgroundColor: colors.rose,
                height: 1,
                width: [52, 30, null, null, 20]
            },
            ':first-of-type': {
                marginTop: 0
            },
            ':hover,&_active': {
                color: colors.rose,
                ':before': {
                    transform: 'translateX(0)',
                    opacity: 1
                }
            }
        },
        '&__img-wrapper': {
            position: 'relative',
            overflow: 'hidden',
            opacity: 1,
            height: ['100%', null, '63%'],
            maxHeight: [null, null, null, 410],
            marginTop: [null, null, null, 60],
            width: '100%',
            img: {
                objectFit: 'cover',
                width: '100%',
                height: '100%'
            },
            ':after': {
                content: "''",
                position: 'absolute',
                left: 0,
                top: 0,
                transform: 'translateX(105%)',
                display: 'block',
                background: colors.lila,
                width: '100%',
                height: '100%'
            },

            [`@media (max-width: ${breakpoints['900']}px) and (orientation: landscape)`]: {
                display: 'none'
            }
        },
        '&__popup': {
            width: '100%',
            height: 'auto',
            margin: 'auto',
        },
        '&__scheme': {
            position: 'relative',
            width: '100%',
            maxWidth: 878,
            margin: '0 auto',
            ':before': {
                content: "''",
                display: 'block',
                paddingTop: '69.02%'
            }
        },
        '&__scheme-wrapper': {
            position: 'absolute',
            left: 0,
            top: 0,
            border: `15px solid ${colors.lilaMiddle}`,
            width: '100%',
            height: '100%',
            img: {
                objectFit: 'cover',
                width: '100%',
                height: '100%'
            }
        },
        '&__transition-group': {
            pointerEvents: 'none',
            height: '100%'
        },
        '&__anim': {
            width: '100%',
            height: '100%',
            '&-exit-active,&-exit': {
                zIndex: 1
            },
            '&-enter-active,&-enter': {
                zIndex: 2,
                position: 'absolute',
                left: 0,
                top: 0
            },
            '&-enter-done': {
                position: 'relative'
            }
        },
        '&__anim-enter-done &': {
            '&__content': {
                pointerEvents: 'auto'
            },
            '&__phone': {
                opacity: 1,
            },
            '&__scroll-wrap': {
                overflow: 'auto',
                
                '.ScrollbarsCustom-Track': {
                    opacity: 1
                }
            },
            '&__popup-wrap': {
                overflow: 'auto',
            },
        },
        '&__anim-enter &': {
            '&__nav-item': {
                opacity: 0,
                ':after': {
                    transform: 'translateY(0)'
                }
            },
            '&__img-wrapper': {
                opacity: 0,
                ':after': {
                    transform: 'translateX(0)'
                }
            },
            '&__popup': {
                transform: 'translateY(50px)',
                opacity: 0
            },
            '&__content': {
                pointerEvents: 'none'
            }
        },
        '&__anim-enter-active &': {
            '&__nav-item': {
                transition: 'color .4s ease, opacity 0s .7s',
                opacity: 1,
                ':after': {
                    transform: 'translateY(105%)',
                    transition: 'transform 1s ease .7s'
                }
            },
            '&__img-wrapper': {
                transitionDelay: '.5s',
                opacity: 1,
                ':after': {
                    transform: 'translateX(105%)',
                    transition: 'transform .8s ease .7s'
                }
            },
            '&__popup': {
                transition:
                    'transform 1.2s cubic-bezier(0.18, 1, 0.21, 1) .3s, opacity 1.2s cubic-bezier(0.18, 1, 0.21, 1) .3s',
                transform: 'translateY(0)',
                opacity: 1
            },
            '&__content': {
                pointerEvents: 'auto'
            }
        },
        '&__anim-exit &': {
            '&__nav-item': {
                opacity: 1,
                ':after': {
                    transform: 'translateY(105%)'
                }
            },
            '&__img-wrapper': {
                opacity: 1,
                ':after': {
                    transform: 'translateX(105%)'
                }
            },
            '&__popup': {
                transform: 'translateY(0)',
                opacity: 1
            },
            '&__content': {
                pointerEvents: 'auto'
            }
        },
        '&__anim-exit-active &': {
            '&__scroll-wrap': {
                '.ScrollbarsCustom-Track': {
                    opacity: 0
                }
            },
            '&__nav-item': {
                transition: 'color .4s ease, opacity 0s .5s',
                opacity: 0,
                ':after': {
                    transition: 'transform .5s ease',
                    transform: 'translateY(0)'
                }
            },
            '&__img-wrapper': {
                opacity: 0,
                transitionDelay: '.5s',
                ':after': {
                    transform: 'translateX(0)',
                    transition: 'transform .5s ease'
                }
            },
            '&__popup': {
                transition:
                    'transform 1.2s cubic-bezier(0.18, 1, 0.21, 1), opacity 1.2s cubic-bezier(0.18, 1, 0.21, 1)',
                transform: 'translateY(50px)',
                opacity: 0
            },
            '&__content': {
                pointerEvents: 'none'
            }
        }
    })
);
