import React, { Component } from 'react';
import { default as st } from './style';
import { innerHTML } from '../../helpers/functions';
import { updateSlideIndex } from '../../actions/sliderHome';
import { SliderArrowNext, SliderArrowPrev } from '../../components';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';
import { styles } from '../../constants';

import Image from '../../components/Image';
import LinkCustom from '../../components/LinkCustom';
import SliderHomeDots from '../../containers/SliderHomeDots';

export default class BannerSlider extends Component {
    render() {
        const settings = {
            infinite: true,
            arrows: true,
            fade: true,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (current, next) => {
                updateSlideIndex(next);
            },
            nextArrow: <SliderArrowNext position={`${st}__next-arrow`} />,
            prevArrow: <SliderArrowPrev position={`${st}__prev-arrow`} />,
            responsive: [
                {
                    breakpoint: '551px',
                    settings: {
                        dots: true,
                        arrows: false
                    }
                }
            ]
        };
        return (
            <div className={st}>
                <Slider {...settings}>
                    {this.props.slides.map(
                        ({ id, header, description, img, link }) => (
                            <div key={id} className={`${st}__slide`}>
                                <div className={`${st}__img-block`}>
                                    <div className={`${st}__img-animation`}>
                                        <div className={`${st}__img-wrapper`}>
                                            <Image src={img} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${st}__info-block`}>
                                    <div className={`${st}__content_animation`}>
                                        <div
                                            className={`${st}__content-wrapper`}
                                        >
                                            <h2
                                                className={`${st}__header`}
                                                dangerouslySetInnerHTML={innerHTML(
                                                    header
                                                )}
                                            />
                                            <p className={`${st}__description`}>
                                                {description}
                                            </p>
                                            <div className={`${st}__link-line`}>
                                                <LinkCustom
                                                    to={link}
                                                    title="Подробнее"
                                                    icon="arrow-right-small"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </Slider>
                <MediaQuery maxWidth={styles.breakpoints['550']}>
                    {matches =>
                        !matches && (
                            <div className={`${st}__dots`}>
                                <SliderHomeDots
                                    slides_count={this.props.slides.length}
                                />
                            </div>
                        )
                    }
                </MediaQuery>
            </div>
        );
    }
}
