import { css } from "emotion";
import { styles, fonts } from "../../constants";
const { mq, colors } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        width: '100%',
        zIndex: '10',

        '&__select': {
            position: 'relative',
            width: '100%',
            height: '60px',
            borderBottom: `1px solid ${colors.lilaDark}`,
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: ['5px 70px 5px 35px', null, null, null, '5px 0 5px 0'],
            cursor: 'pointer',
            background: colors.lilaLight,
            ...fonts.p5,

            '&::after': {
                content: '"\\e90e"',
                fontSize: 10,
                fontWeight: '100',
                fontFamily: 'icomoon',
                color: colors.greyDark,
                lineHeight: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: '.3s ease',
                ...styles.absolute('0', [45, null, null, null, 0],'0', 'auto'),
            },

        },

        '&__inner': {
            ...styles.absolute('100%','auto','auto'),
            width: '100%',
            height: '285px',
            overflow: 'auto',
            pointerEvents: 'none',
            opacity: '0',
            transform: 'translateY(20px)',
            transition: '.3s ease',
            background: colors.lilaLight,
            borderBottom: `1px solid ${colors.lilaDark}`,
        },

        '&__option': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderBottom: `1px solid ${colors.lilaDark}`,
            cursor: 'pointer',
            padding: ['25px 35px', null, null, null, '25px 0'],
            color: colors.lilaMiddle,
            ...fonts.p5,

            '&:last-of-type': {
                borderBottom: 'none'
            },

            '&:hover': {
                background: colors.lilaDark
            },

            '&._active': {
                color: colors.greyDark
            }
        },

        '&._active &': {
            '&__select': {
                '&::after': {
                    transform: 'scaleY(-1)'
                }
            },

            '&__inner': {
                pointerEvents: 'auto',
                opacity: '1',
                transform: 'translateY(-2px)'
            },
        }
    })
);
