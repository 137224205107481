export default function fetchQuery(obj) {
	const
		{ url, method='GET', body, customSuccess } = obj,
		fetchObj = {
			method: method
		};
	
	if (body) fetchObj.body = body;
	
	return fetch(url, fetchObj)
	.then((response) => response.json())
	.then((responseJson) => {
		if (customSuccess) {
			customSuccess(responseJson);
		} else {
			this.setState({
				...responseJson
			});
		}
	})
	.catch((error) => {
		console.error(error);
	});
}
