import React, { Component } from "react";
import { default as st } from "./style";
import Image from "../Image";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import MediaQuery from "react-responsive";
import Slider from "react-slick";

import { styles } from "../../constants";

const { breakpoints } = styles;

class ColsIconsListList extends Component {
    render() {
        const { list, slider } = this.props,
            ListTag = slider ? Slider : "div",
            settings = {
                dots: true,
                arrows: false
            };

        return (
            <ListTag className={`${st}__list`} {...settings}>
                {list.map((item, key) => {
                    const { icon, title, text } = item;

                    return (
                        <div key={key} className={`${st}__item`}>
                            <div className={`${st}__icon`}>
                                <Image src={icon} />
                            </div>

                            <h3 className={`${st}__subtitle`}>{title}</h3>

                            <span className={`${st}__text`}>{text}</span>
                        </div>
                    );
                })}
            </ListTag>
        );
    }
}

class ColsIconsList extends Component {
    render() {
        const { title, list } = this.props;

        return (
            <InView threshold={0.4}>
                {({ inView, ref }) => {
                    const activeMod = inView ? ` ${st}_active` : "";

                    return (
                        <div className={`${st} ${activeMod}`} ref={ref}>
                            <div className="container_narrow">
                                {title && (
                                    <h2 className={`${st}__title col`}>
                                        {title}
                                    </h2>
                                )}
                                <MediaQuery maxWidth={breakpoints["550"]}>
                                    {matches =>
                                        matches ? (
                                            <ColsIconsListList
                                                list={list}
                                                slider={true}
                                            />
                                        ) : (
                                            <ColsIconsListList list={list} />
                                        )
                                    }
                                </MediaQuery>
                            </div>
                        </div>
                    );
                }}
            </InView>
        );
    }
}

ColsIconsList.propTypes = {
    title: PropTypes.string,
    list: PropTypes.array.isRequired
};

export default ColsIconsList;
