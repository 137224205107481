import React, { Component, Fragment } from 'react';
import { Field } from 'formik';
import { default as st } from './style';

const check_values = (field, value) => {
    let flag = false;

    if (field.value instanceof Array) {
        for (let i = 0; i < field.value.length; i++) {
            if (field.value[i].value === value) {
                flag = true;
            }
        }
    } else {
        if (value === field.value.value && field.value.single) {
            flag = true;
        }
    }

    return flag;
};

export default class FilterCheckbox extends Component {
    build_icons = count => {
        let icon_arr = [];

        for (let i = 0; i < count; i++) {
            icon_arr.push(<i className={`${st}__icon icon-user`} key={i} />);
        }

        return icon_arr;
    };

    render() {
        const { name, value, label, title, icons_count, single } = this.props,
            item = {
                label: label,
                value: value,
                single: single
            };

        return (
            <div className={st}>
                <Field name={name}>
                    {({ field, form }) => (
                        <Fragment>
                            <input
                                {...field}
                                type="checkbox"
                                value={value}
                                checked={check_values(field, value)}
                                className={`${st}__input`}
                                onChange={e => {
                                    if (single) {
                                        form.setFieldValue(name, [item]);
                                    } else {
                                        if (field.value instanceof Array) {
                                            let values = field.value.filter(
                                                item =>
                                                    !item.single &&
                                                    item.value !== value
                                            );

                                            if (!check_values(field, value)) {
                                                values.push(item);
                                            }

                                            if (!values.length) {
                                                values.push(item);
                                            }

                                            form.setFieldValue(name, values);
                                        } else {
                                            form.setFieldValue(name, [item]);
                                        }
                                    }

                                    form.submitForm();
                                }}
                            />
                            <label className={`${st}__label`}>
                                {icons_count && (
                                    <span className={`${st}__icons-row`}>
                                        {this.build_icons(icons_count)}
                                    </span>
                                )}
                                {(label || (title && icons_count)) && (
                                    <span className={`${st}__label-text`}>
                                        {icons_count ? title : label}
                                    </span>
                                )}
                            </label>
                        </Fragment>
                    )}
                </Field>
            </div>
        );
    }
}
