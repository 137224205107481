import React, { Component, Fragment } from 'react';
import { default as st } from './style';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as mainActions from '../../actions/base.js';
import * as popupActions from '../../actions/popup.js';
import { urls } from '../../constants';

import MenuPopup from '../../containers/MenuPopup';
import Hamburger from '../../components/Hamburger';
import { ReactComponent as Logo } from '../../images/svg/logo-lila.svg';

export class Header extends Component {
    componentDidMount() {
        if (
            !this.props.baseStore.isFetching &&
            (!this.props.baseStore.fetchResult ||
                !this.props.baseStore.fetchResult.hasOwnProperty('base'))
        ) {
            this.props.mainActions.fetchQuery({
                type: 'GET',
                url: urls.base,
                alias: 'base'
            });
        }
    }
    render() {
        const { baseStore, popupStore, popupActions } = this.props,
            { fetchResult, isFetching } = baseStore,
            { togglePopUp } = popupActions,
            { popup } = popupStore;

        return (
            <Fragment>
                {!isFetching && fetchResult.base && (
                    <header
                        className={`${st}${
                            popup.showPopUp ? ` ${st}_white` : ''
                        }`}
                    >
                        <MenuPopup {...fetchResult.base} {...popup} />
                        <div className={`${st}__left`}>
                            <Link
                                className={`${st}__logo`}
                                to="/"
                                title="Главная"
                            >
                                <Logo className={`${st}__logo-img`} />
                            </Link>
                        </div>
                        <div className={`${st}__center`}>
                            <div className={`${st}__body`}>
                                <a
                                    className={`${st}__phone`}
                                    href={fetchResult.base.contacts.phone.href}
                                >
                                    {fetchResult.base.contacts.phone.title}
                                </a>
                                <div
                                    className={`${st}__callback`}
                                    onClick={() => {
                                        togglePopUp({
                                            showPopUp: true,
                                            content:
                                                fetchResult.base.header_popup
                                        });
                                    }}
                                >
                                    <i
                                        className={`${st}__callback-icon icon-phone`}
                                    />
                                    <span className={`${st}__text`}>
                                        Обратный звонок
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`${st}__right`}>
                            <Hamburger
                                menuIsOpen={popup.showPopUp}
                                clickHandler={() => {
                                    togglePopUp({
                                        showPopUp: !popup.showPopUp,
                                        content: null
                                    });
                                }}
                            />
                        </div>
                        <div className={`${st}__author`}>
                            <div className={`${st}__header`}>
                                Австрийское производство
                            </div>
                            <a
                                className={`${st}__distributor`}
                                href="https://www.b-intense.at/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                b-intense.at
                            </a>
                        </div>
                    </header>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ baseStore, popupStore }) {
    return { baseStore, popupStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch),
        popupActions: bindActionCreators(popupActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
