import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'fixed',
        left: 0,
        top: 0,
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        zIndex: 101,
        '&__left': {
            width: ['calc(100% / 6)', null, null, 'auto'],
            position: 'relative',
            pointerEvents: 'auto',
            zIndex: 2
        },
        '&__logo': {
            position: 'relative',
            display: 'block',
            width: [161, 121, null, 137, 96],
            marginLeft: [60, null, 30, 40]
        },
        '&__logo-img': {
            transition: 'fill .4s ease',
            width: '100%',
            fill: colors.lila
        },

        '&__center': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignContent: 'center',
            flexWrap: 'wrap',
            width: ['calc((100% / 12) * 9)', null, null, 'auto'],
            flex: [null, null, null, '1 0 auto'],
            paddingRight: ['45px', '50px', '40px', null, '20px', 0],
            pointerEvents: 'none'
        },
        '&__body': {
            position: 'relative',
            pointerEvents: 'auto',
            paddingTop: [11, 2]
        },
        '&__phone': {
            ...fonts.p6,
            transition: '.4s ease',
            display: [null, null, null, null, 'none'],
            color: colors.lila,
            ':hover': {
                color: colors.greyDark
            }
        },
        '&__callback': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            transition: '.4s ease',
            marginTop: [8, null, null, null, 0],
            cursor: 'pointer',
            color: colors.lila,
            ':hover': {
                color: colors.greyDark
            }
        },
        '&__text': {
            ...fonts.b2,
            paddingLeft: '6px',
            display: [null, null, null, null, 'none']
        },
        '&__callback-icon': {
            fontSize: 20
        },
        '&__right': {
            pointerEvents: 'auto',
            width: ['calc(100% / 12)', null, '90px', null, 60]
        },
        '&__author': {
            position: 'fixed',
            pointerEvents: 'auto',
            bottom: ['60px', null, '30px'],
            left: ['60px', null, '30px'],
            width: ['calc(100% /12)', null, 'calc(100% / 6)'],
            display: ['inline-block', null, null, 'none']
        },
        '&__header': {
            ...fonts.p5,
            transition: '.4s ease',
            marginBottom: grid.default[0],
            color: colors.lila
        },
        '&__distributor': {
            ...fonts.p7r,
            transition: '.4s ease',
            color: colors.lila,
            ':hover': {
                textDecoration: 'underline'
            }
        },
        '&_white &': {
            '&__logo-img': {
                fill: colors.white
            },
            '&__header': {
                color: colors.white
            },
            '&__phone,&__callback,&__distributor': {
                color: colors.white,
                ':hover': {
                    color: colors.rose
                }
            }
        }
    })
);
