import { css } from 'emotion';
import { styles } from '../../constants';

const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        width: '100%',
        cursor: 'pointer',
        ':before': {
            content: "''",
            display: 'block',
            paddingTop: '100%'
        },
        '&__wrapper': {
            position: 'absolute',
            left: 0,
            top: 0,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: styles.colors.lila,
            width: '100%',
            height: '100%'
        },
        '&__block': {
            position: 'relative',
            transition: '.4s ease',
            width: [28, null, null, null, 24]
        },
        '&__bar': {
            display: 'block',
            transition: '.4s ease',
            backgroundColor: colors.white,
            width: '100%',
            height: 2,
            marginTop: 6,
            ':first-of-type': {
                marginTop: 0
            }
        },
        '&__circle': {
            position: 'absolute',
            display: 'block',
            borderRadius: '50%',
            transform: 'translate(-50%,-50%)',
            backgroundColor: colors.rose,
            transition: 'width 0.4s ease-in-out, height 0.4s ease-in-out',
            width: 0,
            ':after': {
                content: "''",
                display: 'block',
                paddingTop: '100%'
            }
        },
        ':hover &': {
            '&__circle': {
                transition: 'width .4s ease-in-out, height .6s ease-in-out',
                width: '300%'
            }
        },
        '&_active &': {
            '&__block': {
                transform: 'rotate(90deg)'
            }
        }
    })
);
