import React, { Component } from 'react';
import { default as st } from './style';
import { ReactComponent as Logo } from '../../images/svg/logo-vertical.svg';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import AnchorWrap from "../AnchorWrap";

import { styles } from '../../constants';

const { colors, breakpoints } = styles;

export default class SectionGrid extends Component {
    render() {
        const {
            right_column,
            left_column,
            right_column_hide,
            first,
            last,
            overflow,
            mod,
            children
        } = this.props;

        return (
            <section
                className={`${st}${first ? ` ${st}_first` : ''}${
                    last ? ` ${st}_last` : ''
                }${overflow ? ` ${st}_overflow` : ''}${
                    mod ? ` ${st}_${mod}` : ''
                }`}
            >
                <div className={`${st}__col ${st}__col_left`}>
                    {left_column && (
                        <div
                            className={`${st}__left-wrapper${
                                left_column.stretch
                                    ? ` ${st}__left-wrapper_stretch`
                                    : ''
                            }`}
                            style={{
                                backgroundColor: left_column.fill
                                    ? left_column.fill
                                    : 'transparent'
                            }}
                        >
                            {left_column.gradient_block && (
                                <span className={`${st}__gradient-block`} />
                            )}
                            {left_column.text && (
                                <MediaQuery maxWidth={breakpoints['900']}>
                                    {matches =>
                                        !matches && (
                                            <p className={`${st}__left-title`}>
                                                {left_column.text}
                                            </p>
                                        )
                                    }
                                </MediaQuery>
                            )}
                            {left_column.logo && (
                                <MediaQuery maxWidth={breakpoints['900']}>
                                    {matches =>
                                        !matches && (
                                            <div
                                                className={`${st}__logo-vertical`}
                                            >
                                                <img
                                                    src={require('../../images/svg/logo-vertical.svg')}
                                                    alt={''}
                                                />
                                            </div>
                                        )
                                    }
                                </MediaQuery>
                            )}
                        </div>
                    )}
                </div>
                <div className={`${st}__col ${st}__col_middle`}>
                    <AnchorWrap anchor={children.props && children.props.anchor}>
                        {children}
                    </AnchorWrap>
                </div>
                {!right_column_hide && (
                    <div className={`${st}__col ${st}__col_right`}>
                        {right_column &&
                            (right_column.fill || right_column.logo) && (
                                <MediaQuery maxWidth={breakpoints['900']}>
                                    {matches =>
                                        !matches && (
                                            <div
                                                className={`${st}__right-wrapper`}
                                                style={{
                                                    backgroundColor: right_column.fill
                                                        ? right_column.fill
                                                        : 'transparent'
                                                }}
                                            >
                                                <Logo
                                                    className={`${st}__right-logo`}
                                                    style={{
                                                        fill: right_column.fill
                                                            ? colors.white
                                                            : colors.lilaLight
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                </MediaQuery>
                            )}
                    </div>
                )}
            </section>
        );
    }
}

SectionGrid.propTypes = {
    right_column: PropTypes.object,
    right_column_hide: PropTypes.bool,
    first: PropTypes.bool,
    left_column: PropTypes.object,
    overflow: PropTypes.bool,
    mod: PropTypes.string
};
