import { actions } from '../constants';

const initialState = {
    currentSlideIndex: 0
};
export default function homeReducer(state = initialState, { type, ...action }) {
    switch (type) {
        case actions.SLIDER_HOME.SET_SLIDE_INDEX: {
            return {
                ...state,
                currentSlideIndex: action.payload
            };
        }
        default:
            return state;
    }
}
