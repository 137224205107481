import React, { Component } from 'react';
import { default as st } from './style';
import {InView} from 'react-intersection-observer';
import { css } from 'emotion';
import RotatedText from "../RotatedText";
import InfoWindow from "../InfoWindow";
import Image from "../Image";
import PropTypes from 'prop-types';
import MediaQuery from "react-responsive/src/Component";
import {styles} from "../../constants";

class InfraredCabinsEffects extends Component {


    renderRotatedItems = (list) => {
        return (
            list.map((item, key) => {
                const
                    { icon, label, content } = item,
                    steps = list.length <= 5 ? 5 : list.length,
                    angle = (360 / steps) * key,
                    rotateStyles = css({
                        transform: `rotate(${angle}deg)`
                    }),
                    rotateStylesReverse = css({
                        transform: `rotate(${-angle}deg)`
                    });

                return (
                    <div key={key} className={`${st}__rotate-wrap ${rotateStyles}`}>
                        <InfoWindow mod={`${st}__info-content`} content={content} className={`${st}__info-wrap ${rotateStylesReverse}`}>
                            <div className={`${st}__info-item info-item`}>
                                <div className={`${st}__icon`}>
                                    <Image src={icon} />
                                </div>
                                <div className={`${st}__label info-label`}>
                                    {label}
                                </div>
                            </div>
                        </InfoWindow>
                    </div>

                )
            })
        )
    };

    renderListItems = (list) => {
        return (
            list.map((item, key) => {
                const
                    { icon, label, content } = item;

                return (
                    <div key={key} className={`${st}__item`}>
                        <InfoWindow mod={`${st}__info-content`} content={content} className={`${st}__inner-wrap`}>
                            <div className={`${st}__info-item info-item`}>
                                <div className={`${st}__icon`}>
                                    <Image src={icon} />
                                </div>
                                <div className={`${st}__label info-label`}>
                                    {label}
                                </div>
                            </div>
                        </InfoWindow>
                    </div>

                )
            })
        )
    };



    render() {
        const { aside_text, list } = this.props;
        
        return (
            <InView threshold={0.4}>
                {({inView, ref}) => {
                    const activeMod = inView ? `_active` : '';
                    
                    return (
                        <div className={`${st} ${activeMod}`} ref={ref}>
                            <div className={`${st}__table container_narrow`}>
                                <div className={`${st}__col col ${st}__col_left`}>
                                    <RotatedText className={`${st}__aside-text`} text={aside_text} />
                                </div>
        
                                <div className={`${st}__col col ${st}__col_right`}>
                                    <div className={`${st}__list-wrap`}>
                                        <MediaQuery minWidth={styles.breakpoints['700']+1}>
                                            <div className={`${st}__circle`}>
                                                <div className={`${st}__circle-col ${st}__circle-col_left`} />
                                                <div className={`${st}__circle-col ${st}__circle-col_right`} />
                                            </div>
                                        </MediaQuery>

                                        <div className={`${st}__list`}>
                                            <MediaQuery maxWidth={styles.breakpoints['700']}>
                                                {matches => matches
                                                    ?
                                                    this.renderListItems(list)
                                                    :
                                                    this.renderRotatedItems(list)
                                                }
                                            </MediaQuery>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

InfraredCabinsEffects.propTypes = {
    aside_text: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired
};

export default InfraredCabinsEffects;
