import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq, colors } = styles;

export default css(
    mq({
        "&__title-wrap": {
            marginBottom: grid.default[6],
            padding: [null, null, null, 0]
        },

        "&__title": {
            ...fonts.h2,
            maxWidth: 700
        },

        "&__images-container": {
            position: "relative",
            width: ["calc(100% / 9 * 8)", "100%"],
            marginLeft: "auto",
            marginRight: 0,
            display: "flex",
            justifyContent: [null, "space-between"],
            zIndex: 2,
            padding: [null, 0]
        },

        "&__img-col": {
            width: ["50%", "44%", "41.2%", "42.7%"],
            paddingLeft: ["calc(100% / 8 / 2)", 0],
            paddingRight: [40, 0]
        },

        "&__img-wrap": {
            position: "relative",
            display: "block",
            width: "100%",
            overflow: "hidden",

            ":after": {
                content: "''",
                display: "block",
                paddingTop: ["59.17%", null, null, "71.5%"]
            },

            "&::before": {
                content: '""',
                display: "block",
                ...styles.absolute(),
                width: "100%",
                height: "100%",
                background: colors.white,
                transition: ".5s ease",
                zIndex: 3
            },

            img: {
                ...styles.absolute(),
                ...styles.of(),
                zIndex: 1
            }
        },

        "&__images-container._active &": {
            "&__img-wrap": {
                "&::before": {
                    transform: "translateX(101%)"
                }
            }
        },

        "&__slider": {
            width: '100%',

            '.slick-list': {
                width: 'calc(100% + 40px)',
                position: 'relative',
                left: '-20px'
            },

            ".slick-dots": {
                display: "flex !important",
                justifyContent: "center",
                position: "relative",
                bottom: "auto",
                marginTop: 24,
                li: {
                    padding: 5
                },
                button: {
                    transform: "rotate(45deg)",
                    width: "9px",
                    height: "9px",
                    border: `1px solid ${styles.colors.lila}`,
                    padding: 0,
                    margin: "0 auto",
                    ":before": {
                        content: "none"
                    }
                },
                ".slick-active": {
                    button: {
                        backgroundColor: styles.colors.lila
                    }
                }
            }
        },

        "&__table": {
            position: "relative",
            display: ["block", null, null, null, "flex"],
            marginTop: grid.default[5],
            paddingBottom: grid.default[7],
            paddingLeft: [null, 0],
            paddingRight: [null, "7%", 0],
            width: ["calc(100% / 9 * 7)", "100%", null, null, "100%"],
            // overflow: [null, null, null, null, "auto"],
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1,

            "&::after, &::before": {
                content: ['""', null, null, null, "none"],
                display: "block",
                width: ["calc(100% / 7 * 3)", "37%", "41.2%", "42.7%"],
                height: "calc(100% + 100px)",
                zIndex: 1
            },

            "&::before": {
                ...styles.absolute("auto", "auto", "0", "0"),
                background: colors.gradient
            },

            "&::after": {
                ...styles.absolute("auto", ["0", "7%", "0"], "0", "auto"),
                boxSizing: "border-box",
                border: [
                    `15px solid ${colors.lilaLight}`,
                    null,
                    null,
                    `10px solid ${colors.lilaLight}`
                ]
            }
        },

        "&__titles": {
            position: "relative",
            left: 0,
            top: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            flexDirection: [null, null, null, null, "column"],
            height: [null, null, null, null, "100%"],
            marginBottom: [
                grid.default[3][0],
                grid.default[3][1],
                grid.default[3][2],
                grid.default[3][3],
                0
            ],
            zIndex: [2, null, null, null, 3]
        },
        "&__titles._active &": {
            "&__col": {
                opacity: 1,
                transition: "1s ease",
                transitionDelay: ".9s",
                transform: "translateX(0)"
            }
        },
        "&__titles &": {
            "&__col": {
                background: "none",
                border: "none",
                height: [null, null, null, null, "calc(50% - 25px)"],
                justifyContent: [null, null, null, null, "flex-start"],
                flex: "none"
            }
        },

        "&__col": {
            position: "relative",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: ["center", null, null, null, "space-between"],
            display: [null, null, null, null, "flex"],
            textAlign: [null, null, null, null, "center"],
            minHeight: [null, null, null, null, 250],
            width: [
                "calc(100% / 7 * 3)",
                "37%",
                "42%",
                null,
                "calc(100vw - 80px)"
            ],
            flex: [null, null, null, null, "1 0 auto"],
            opacity: [0, null, null, null, "1"],

            "&_left": {
                transform: ["translateX(10%)", null, null, null, "none"],
                background: [
                    null,
                    null,
                    null,
                    null,
                    "linear-gradient(-90deg, #DF9CBF 0%, #905D92 100%)"
                ],
                color: colors.white
            },

            "&_right": {
                border: [
                    null,
                    null,
                    null,
                    null,
                    `10px solid ${colors.lilaLight}`
                ],
                transform: ["translateX(-10%)", null, null, null, "none"],
            },

            "&_left, &_right": {
                padding: [
                    "0 calc(100% / 7 / 2)",
                    "0 40px",
                    "0 43px",
                    null,
                    "40px 20px 20px"
                ],
                transition: ".3s ease"
            },

            "&_center": {
                width: [
                    "calc(100% / 7)",
                    "19%",
                    "16%",
                    null,
                    "calc(100vw - 80px)"
                ],
                display: "flex",
                justifyContent: "center",
                transition: ".3s ease",
                flex: "none",
                minHeight: 50,
                zIndex: [null, null, null, null, 2],

                "&::after, &::before": {
                    content: '""',
                    display: "block",
                    width: [89, null, 62, null, 30],
                    height: 8,
                    background: `url(${require("../../images/svg/arrow-stroke.svg")}) center center no-repeat`,
                    backgroundSize: ["contain", null, null, null, "auto 100%"],
                    fontSize: 8,
                    transition: ".3s ease"
                },

                ":before": {
                    ...styles.absolute(
                        ["0", null, null, null, "100%"],
                        ["100%", null, null, null, "auto"],
                        [0, null, null, null, "auto"],
                        "auto"
                    ),
                    backgroundPosition: [null, null, null, null, "left top"],
                    transform: [
                        "translateX(75%)",
                        null,
                        null,
                        null,
                        "rotate(-90deg)"
                    ]
                },

                ":after": {
                    ...styles.absolute(
                        [0, null, null, null, "auto"],
                        "auto",
                        [0, null, null, null, "100%"],
                        ["100%", null, null, null, "auto"]
                    ),
                    backgroundPosition: [null, null, null, null, "left bottom"],
                    transform: [
                        "translateX(-75%) scaleX(-1)",
                        null,
                        null,
                        null,
                        "rotate(90deg)"
                    ]
                }
            }
        },

        "&__subtitle": {
            ...fonts.h3
        },

        "&__row": {
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: [null, null, null, null, "column"],
            marginBottom: [
                grid.default[6][0],
                grid.default[6][1],
                grid.default[6][2],
                grid.default[6][3],
                0
            ],
            marginRight: [null, null, null, null, 20],
            zIndex: 2,
            padding: [null, null, null, null, '0 20px'],

            "&:last-of-type": {
                marginBottom: 0,
                marginRight: 0
            }
        },
        "&__row:nth-of-type(2) &": {
            "&__col_left,&__col_right": {
                paddingTop: [null, null, null, null, 45],
                paddingBottom: [null, null, null, null, 45]
            }
        },

        "&__row._active &": {
            "&__col": {
                opacity: 1,

                "&_left, &_right": {
                    transition: "1s ease",
                    transitionDelay: ".9s",
                    transform: "translateX(0)"
                },

                "&_center": {
                    transition: ".7s ease",
                    transitionDelay: ".3s",
                    transform: "scale(1)",

                    "&::after, &::before": {
                        transition: ".7s ease",
                        transitionDelay: ".3s"
                    },

                    "&::before": {
                        transform: [
                            "translateX(50%)",
                            null,
                            null,
                            null,
                            "translateY(0) rotate(-90deg)"
                        ]
                    },

                    "&::after": {
                        transform: [
                            "translateX(-50%) scaleX(-1)",
                            null,
                            null,
                            null,
                            "translateY(0) scaleX(-1) rotate(90deg)"
                        ]
                    }
                }
            }
        },

        "&__row-title": {
            ...fonts.p5,
            color: colors.lilaMiddle,
            width: [null, null, null, null, "auto"],

            '& > *': {
                whiteSpace: [null, null, null, null, "normal"],
                transform: [null, null, null, null, "none"],

                ":before": {
                    content: [null, null, null, null, "none"],
                }
            }

        },

        "&__text": {
            ...fonts.p4,
            position: "relative",
            margin: [null, null, null, null, 'auto']
        }
    })
);
