import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        width: [
            'calc(100% / 9 * 6)',
            null,
            '70.5%',
            'calc(100% - 155px)',
            '100%'
        ],
        marginRight: 0,
        marginLeft: 'auto',
        '&__header': {
            ...fonts.h2,
            transition:
                'transform 2s cubic-bezier(0.18, 1, 0.21, 1), opacity 2s cubic-bezier(0.18, 1, 0.21, 1)',
            transform: 'translateY(20px)',
            opacity: 0,
            width: '100%',
            maxWidth: ['570px', '410px', 350, null, 240],
            padding: [null, null, null, null, 0],
            color: colors.greyDark
        },
        '&__content': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginTop: grid.default[6]
        },
        '&__block': {
            transition:
                'transform 2s cubic-bezier(0.18, 1, 0.21, 1), opacity 2s cubic-bezier(0.18, 1, 0.21, 1)',
            transform: 'translateY(20px)',
            opacity: 0,
            width: ['calc(100% / 3)', null, null, 'calc(100% / 2)', '100%'],
            display: 'flex',
            flexDirection: ['column', null, null, null, 'row'],
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            marginBottom: grid.default[6],
            padding: [null, null, null, null, 0],
            '&:last-child': {
                marginBottom: [null, null, null, null, 0]
            }
        },
        '&__icon': {
            height: ['55px', null, '45px', null, 'fit-content'],
            width: ['fit-content', null, null, null, 50],
            marginBottom: grid.default[4],
            margin: [null, null, null, null, 0],
            img: {
                width: '100%',
                height: '100%',
                objectFit: 'contain'
            }
        },
        '&__text': {
            ...fonts.p5,
            width: ['75%', null, null, null, 'calc(100% - 75px)'],
            color: colors.lilaMiddle
        },
        '&__item-content': {
            position: 'relative',
            display: [null, null, null, null, 'flex'],
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        '&__link': {
            position: 'relative'
        },
        '&__border': {
            position: 'absolute',
            left: '-20%',
            top: -35,
            pointerEvents: 'none',
            width: '67%',
            height: 'calc(100% + 55px)'
        },
        '&__border-line': {
            position: 'absolute',
            overflow: 'hidden',
            ':after,:before': {
                content: "''",
                position: 'absolute',
                display: 'block',
                transition: '.4s ease',
                backgroundColor: colors.lilaLight
            },
            ':nth-of-type(odd)': {
                width: '100%',
                height: 15,
                ':after,:before': {
                    top: 0,
                    width: '50%',
                    height: '100%'
                },
                ':before': {
                    left: 0,
                    transform: 'translateX(-105%)'
                },
                ':after': {
                    right: 0,
                    transform: 'translateX(105%)'
                }
            },
            ':nth-of-type(even)': {
                width: 15,
                height: '100%',
                ':after,:before': {
                    left: 0,
                    width: '100%',
                    height: '50%'
                },
                ':before': {
                    top: 0,
                    transform: 'translateY(-105%)'
                },
                ':after': {
                    bottom: 0,
                    transform: 'translateY(105%)'
                }
            },
            ':nth-of-type(1)': {
                left: 0,
                top: 0
            },
            ':nth-of-type(2)': {
                right: 0,
                top: 0
            },
            ':nth-of-type(3)': {
                right: 0,
                bottom: 0
            },
            ':nth-of-type(4)': {
                left: 0,
                bottom: 0
            }
        },
        '&__link:hover &': {
            '&__border-line': {
                ':after,:before': {
                    transform: 'translate(0,0)'
                }
            }
        },
        '&_active &': {
            '&__header,&__block': {
                transform: 'translateY(0)',
                opacity: 1
            }
        }
    })
);
