import { css, keyframes } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

const moveImgOverlay = keyframes`
    0% {
        transform: translateX(0)
    }
    
    100% {
        transform: translateX(101%)
    }
`;

const showContent = keyframes`
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;

export default css(
    mq({
        position: 'relative',
        marginBottom:grid.default[9],

        '&::before': {
            content: [null, null, null, null, '""'],
            display: 'block',
            ...styles.absolute('0', 'auto', '0', -40),
            width: 'calc(100% + 80px)',
            height: '100%',
            background: colors.lilaLight
        },
        
        '&__nav': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            zIndex: 4,
        },
        
        '&__link': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1 0 auto',
            height: [134, 80],
            ...fonts.p5,
            color: colors.lila,
            transition: 'all .3s ease',
            cursor: 'pointer',
            
            '&._active': {
                color: colors.greyDark,
                background: colors.lilaLight,
            },
            
            '&:hover': {
                color: colors.greyDark,
            }
        },
        
        '&__tabs-wrap': {
            position: 'relative',
            paddingTop: grid.default[6],
            paddingBottom: grid.default[6],
            background: colors.lilaLight,
            zIndex: 2,
        },
        
        '&__tab': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: [null, null, 'column'],
            paddingLeft: [null, null, 80, 50, 0],
            paddingRight: [null, null, 80, 50, 0],

            '&:not(._active)': {
                display: 'none'
            }
        },
        
        '&__col': {
            width: 'calc(100% / 9 * 4)',
            
            '&_left': {
                paddingLeft: [60, 46, 0],
                paddingRight: [60, 46, 0],
                width: ['calc(100% / 9 * 4 + 50px)', 'calc(100% / 9 * 4 + 40px)', '100%'],
            },
            
            '&_right': {
                width: ['calc(100% / 9 * 4 - 50px)', null, '100%'],
                marginTop: [null, null, 50, null, 30],
                
                '& > *': {
                    ...styles.setDelays({count: 3, delay: 150, type: 'animation'})
                }
            },
        },
    
        '&__img-wrap': {
            position: 'relative',
            display: 'block',
            width: "100%",
            height: [404, 287, 300, 280, 200],
            overflow: 'hidden',
        
            '&::before': {
                content: '""',
                display: 'block',
                ...styles.absolute(),
                width: '100%',
                height: '100%',
                background: colors.lilaLight,
                transition: '.5s ease',
                zIndex: 3
            },
        
            img: {
                ...styles.of(),
                display: 'block',
                zIndex: 1
            }
        },
        
        '&__title': {
            ...fonts.h3,
            marginBottom: grid.default[4]
        },
        
        '&_active &__tab._active &': {
            '&__img-wrap': {
                '&::before': {
                    animation: `${moveImgOverlay} .5s ease both`,
                    animationDelay: '.3s'
                }
            },
            
            '&__col': {
                '&_right': {
                    '& > *': {
                        animation: `${showContent} .5s ease both`,
                        ...styles.setDelays({count: 3, delay: 150, type: 'animation', nth: 'nth-child'})
                    }
                    
                }
            }
        }
    })
);
