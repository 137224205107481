import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routing } from "./constants";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-lightbox/style.css';
import * as popupActions from "./actions/popup";

import { Header } from "./containers";
import Footer from "./containers/Footer";

import Home from "./views/home";
import Models from "./views/models";
import Product from "./views/product";
import Interior from "./views/interior";
import Advantages from "./views/advantages";
import Contacts from "./views/contacts";
import NotFound from "./views/not-found";
import About from "./views/about";
import Technologies from "./views/technologies";

const routes = [
    {
        path: routing.home,
        Component: Home,
        exact: true
    },
    {
        path: routing.models,
        Component: Models,
        exact: true
    },
    {
        path: routing.product_page,
        Component: Product
    },
    {
        path: routing.advantages,
        Component: Advantages
    },
    {
        path: routing.technologies,
        Component: Technologies
    },
    {
        path: routing.interior,
        Component: Interior
    },
    {
        path: routing.contacts,
        Component: Contacts
    },
    {
        path: routing.about,
        Component: About
    }
];

let delay = { enter: 1000, exit: 1000 };

class App extends Component {
    constructor(props) {
        super(props);

        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual";
        }

        this.props.history.listen(location => {
            this.unlisten();
            this.delayScrollTop();
        });

        this.currentPathName = this.props.history.location.pathname;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.currentPathName !== nextProps.history.location.pathname) {
            this.currentPathName = nextProps.history.location.pathname;

            return true;
        } else {
            return false;
        }
    }

    componentDidUpdate() {
        this.triggerHit();
    }

    componentDidMount() {
        this.triggerHit();
    }

    triggerHit() {
        const { location } = this.props.history;

        if (window.ym) {
            window.ym(41616234, 'hit', location);
        }

        if (window.ga) {
            window.ga('set', 'page', location);
            window.ga('send', 'pageview');
        }
    }

    delayScrollTop = () => {
        if (this.currentPathName !== this.props.history.location.pathname) {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "instant"
                });
            }, delay.enter);
        }
    };

    unlisten = () => {
        const { popupActions } = this.props,
            { togglePopUp } = popupActions;

        togglePopUp({
            showPopUp: false
        });
    };
    render() {
        const { location } = this.props.history,
            currentKey = location.pathname;

        return (
            <div className="App">
                <Header />

                <TransitionGroup component="main" className={"page-main"}>
                    <CSSTransition
                        key={currentKey}
                        timeout={delay}
                        classNames={"page"}
                        appear
                    >
                        <div className="page-wrap">
                            <Switch location={location}>
                                {routes.map(
                                    ({ path, Component, exact = false }) => (
                                        <Route
                                            key={path}
                                            exact={exact}
                                            path={path}
                                            component={props => (
                                                <Component
                                                    routePath={path}
                                                    {...props}
                                                />
                                            )}
                                        />
                                    )
                                )}
                                <Route component={NotFound} />
                            </Switch>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return { popupActions: bindActionCreators(popupActions, dispatch) };
}

export default withRouter(connect(null, mapDispatchToProps)(App));
