import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { default as st } from './style';
import PropTypes from 'prop-types';

export default class Hamburger extends Component {
    constructor(props) {
        super(props);
        this.mouse_handler = this.mouse_handler.bind(this);
        this.btn = React.createRef();
        this.state = {
            left: 0,
            top: 0
        };
    }

    mouse_handler = e => {
        let el_event = e.nativeEvent,
            btn_coords = ReactDOM.findDOMNode(
                this.btn.current
            ).getBoundingClientRect(),
            rel_x = el_event.x - btn_coords.x,
            rel_y = el_event.y - btn_coords.y;

        this.setState({
            left: rel_x,
            top: rel_y
        });
    };

    render() {
        const { menuIsOpen, clickHandler } = this.props,
            { left, top } = this.state;

        let circle_styles = {
            left: left,
            top: top
        };

        return (
            <div
                className={`${st}${menuIsOpen ? ` ${st}_active` : ''}`}
                onClick={() => clickHandler(menuIsOpen)}
                ref={this.btn}
                onMouseEnter={this.mouse_handler}
                onMouseLeave={this.mouse_handler}
            >
                <div className={`${st}__wrapper`}>
                    <div className={`${st}__circle`} style={circle_styles} />
                    <div className={`${st}__block`}>
                        <span className={`${st}__bar`} />
                        <span className={`${st}__bar`} />
                        <span className={`${st}__bar`} />
                    </div>
                </div>
            </div>
        );
    }
}

Hamburger.propTypes = {
    menuIsOpen: PropTypes.bool,
    clickHandler: PropTypes.func
};
