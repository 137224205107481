import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        paddingBottom: grid.default[4],
        paddingTop: [null, null, null, 10],
        paddingLeft: [null, null, null, 0],
        paddingRight: [null, null, null, 0],
        '&__item': {
            display: 'inline',
            ':after': {
                content: "'»'",
                margin: '0 .25em'
            },
            ':last-of-type': {
                ':after': {
                    content: 'none'
                }
            }
        },
        '&__link': {
            ...fonts.p4,
            position: 'relative',
            textDecoration: 'underline',
            transition: '.3s ease',
            color: colors.lila,

            ':hover': {
                textDecoration: 'none',
                color: colors.greyDark
            },
            '&_text': {
                pointerEvents: 'none',
                textDecoration: 'none',
                color: colors.greyDark
            }
        }
    })
);
