import { css } from 'emotion';
import { styles, fonts } from '../../constants';

const { mq, colors } = styles;

export default css(
    mq({
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&__dots-line-empty': {
            position: 'relative',
            backgroundColor: colors.lila,
            overflow: 'hidden',
            height: 96,
            width: 1,
            marginTop: 20,
            marginBottom: 20
        },
        '&__dots-line-fill': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundColor: colors.white,
            display: 'inline-block',
            transition: 'top 2s ease'
        },
        '&__dots-number': {
            ...fonts.counter,
            color: colors.white
        }
    })
);
