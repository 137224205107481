import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        position: "relative",
        display: "flex",
        flexDirection: ["column", null, "row", null, "column"],
        justifyContent: ["flex-start", null, "space-between"],
        alignItems: "flex-start",

        "&__img-wrap": {
            position: "relative",
            display: "block",
            width: "100%",
            maxWidth: [null, null, 140, null, 90],
            ...styles.rh(),
            overflow: "hidden",
            marginBottom: grid.default[3],

            img: {
                display: "block",
                ...styles.absolute(),
                ...styles.of({ pos: "center top" })
            }
        },
        "&__content": {
            width: [null, null, "calc(100% - 160px)", null, "100%"]
        },
        "&__title": {
            ...fonts.p3,
            marginBottom: grid.default[2]
        },
        "&__label": {
            ...fonts.p7r
        }
    })
);
