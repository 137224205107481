import React, { Component } from "react";
import { default as st } from "./style";
import Slider from "react-slick";
import Image from "../Image";
import TextBlock from "../TextBlock";
import PropTypes from "prop-types";

import { styles } from "../../constants";

const { breakpoints } = styles;

class GreySlider extends Component {
    renderItem = (item, key = 0) => {
        const { img, title, content } = item;

        return (
            <div key={key} className={`${st}__item`}>
                <div className={`${st}__table`}>
                    <div className={`${st}__col col ${st}__col_left`}>
                        <div className={`${st}__img-wrap`}>
                            <Image src={img} />
                        </div>
                    </div>
                    <div className={`${st}__col col ${st}__col_right`}>
                        <div className={`${st}__content`}>
                            {title && (
                                <h4 className={`${st}__subtitle`}>{title}</h4>
                            )}

                            <TextBlock content={content} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { title, list } = this.props,
            settings = {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                speed: 1000,
                arrows: true,
                fade: false,
                centerPadding: "0",
                responsive: [
                    {
                        breakpoint: breakpoints["550"] + 1,
                        settings: {
                            arrows: false,
                            dots: true
                        }
                    }
                ]
            };

        return (
            <div className={`${st}`}>
                <div className={`${st}__container  container_narrow`}>
                    {title && (
                        <div className={`${st}__title-wrap col`}>
                            <h3 className={`${st}__title`}>{title}</h3>
                        </div>
                    )}

                    {list.length > 1 ? (
                        <Slider className={`${st}__slider`} {...settings}>
                            {list.map((item, key) =>
                                this.renderItem(item, key)
                            )}
                        </Slider>
                    ) : (
                        this.renderItem(list[0])
                    )}
                </div>
            </div>
        );
    }
}

GreySlider.propTypes = {
    title: PropTypes.string,
    list: PropTypes.array.isRequired
};

export default GreySlider;
