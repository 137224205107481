import { css, keyframes } from 'emotion';
import { styles, fonts, grid } from '../../constants';

const { mq, colors } = styles;

const breath = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    
    100% {
        opacity: 0;
        transform: scale(1.5);
    }
`;

export default css(
    mq({
        position:'relative',
        marginBottom:grid.default[9],
        
        '&__aside-text': {
            ...fonts.p0,
            color: colors.lilaLight,
            transform: 'translateY(101%)',
            transition: '1s ease'
        },
        
        '&__table': {
            position:'relative',
            display:'flex',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
        },
        
        '&__col': {
            '&_left': {
                width: ['calc(100% / 7)', null , null , null , 'auto'],
                display:'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                overflow: 'hidden',
                padding: [null, 0],
                position: [null,null,null,null,'absolute'],
                left: [null,null,null,null,'100%']
            },
    
            '&_right': {
                width: ['calc(100% / 7 * 5)', null, null, null, '100%'],
                padding: ['0 90px', '0 35px', '0 0 0 10px', null, 0]
            },
        },
        
        '&__list-wrap': {
            display: 'block',
            position: 'relative',
            ...styles.rh(1, ['block',null,null,null,'none']),
        },
        
        '&__circle': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            zIndex: 1
        },

        '&__circle-col': {
            ...styles.absolute(),
            width: 'calc(50% + 1px)',
            height: '100%',
            overflow: 'hidden',
            
            '&::before': {
                content: '""',
                ...styles.absolute(),
                width: '200%',
                height: '100%',
                boxSizing: 'border-box',
                borderRadius: '50%',
                borderStyle: 'solid',
                borderWidth: '10px',
                transform: 'rotate(-225deg)',
                transition: 'transform .5s linear'
            },
            
            '&_left': {
                right: 'auto',
    
                '&::before': {
                    right: 'auto',
                    borderColor: `${colors.lilaLight} transparent transparent ${colors.lilaLight}`,
                    transitionDelay: '0s'
                },
            },
            
            '&_right': {
                left: 'auto',
    
                '&::before': {
                    left: 'auto',
                    borderColor: `transparent ${colors.lilaLight} ${colors.lilaLight} transparent`,
                    transitionDelay: '.5s'
                },
            }
        },
        
        '&__list': {
            ...styles.absolute(),
            position: ['absolute',null,null,null, 'relative'],
            width: '100%',
            height: '100%',
            zIndex: 3
        },

        '&__item': {
            position: 'relative',
            marginBottom: 35,

            '&:last-of-type': {
                marginBottom: 0
            }
        },

        '&__inner-wrap': {
            position: 'relative',

            '&::before': {
                left: ['calc(50% - 12px)', null, null, null, 13]
            },

            '&::after': {
                content: '"\\e90e"',
                fontSize: 8,
                fontFamily: 'icomoon',
                color: colors.lilaMiddle,
                lineHeight: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: '.3s ease',
                ...styles.absolute('0', '0','0', 'auto'),
            },

            '&._active': {
                '&::after': {
                    transform: 'scaleY(-1)'
                }
            }
        },
        
        '&__rotate-wrap': {
            ...styles.absolute('0', ['calc(50% - 70px)', null, null, 'calc(50% - 60px)', 'calc(50% - 50px)'], 'auto', 'auto'),
            transformOrigin: '0% 100%',
            width: [70, null, null, 60, 50],
            height: '50%',
            
            '&:hover': {
                zIndex: 5
            }
        },
    
        '&__info-wrap': {
            ...styles.absolute([-25], 'auto', 'auto', '0'),
            transformOrigin: '50% 50%',
        },
        
        '&__info-item': {
            position: 'relative',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: [70, null, null, 60, 50],
            height: [70, null, null, 60, 50],
            borderRadius: '50%',
            background: colors.lilaMiddle,
            cursor: 'pointer',
            opacity: '0',
            transition: '0.3s ease',
            
            '&::before, &::after': {
                content: '""',
                display: 'block',
                ...styles.absolute(),
                width: '100%',
                height: '100%',
                borderRadius: '50%',
            },
            
            '&::before': {
                background: 'inherit',
                animation: `${breath} 3s linear infinite`,
                transformOrigin: '50% 50%',
                zIndex: 1,
                pointerEvents: 'none'
            },
            
            '&::after': {
                zIndex: 2
            },
        },
        
        '&__icon, &__label': {
            zIndex: 5
        },
        
        '&__icon': {
            zIndex: 5,
            height: [35, null, null, null, '50%'],
            width: [35, null, null, null, '50%'],

            img: {
                ...styles.of({fit: 'contain'}),
                width: '100%',
                height: '100%'
            }
        },

        '&__label': {
            ...styles.absolute('0', 'auto', '0', '100%'),
            ...fonts.p6,
            color: colors.lilaMiddle,
            whiteSpace: ['nowrap', null, null, null, 'normal'],
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            height: "100%",
            width: [null, null, null, null, 170],
            paddingLeft: '20px',
            transform: 'translateX(-10px)',
            opacity: '0',
            transition: 'all .3s ease',
            zIndex: 5
        },

       '&._active': {
           zIndex: 5
       },
       
       '&:not(._active) &': {
           '&__info-content': {
               opacity: 0
           },

           '&__inner-wrap': {
               '&::before': {
                   opacity: 0
               }
           }
       },

       '&._active &': {
           '&__aside-text': {
               transform: 'translateY(0)'
           },
    
           '&__rotate-wrap': {
               ...styles.setDelays({base: -800, delay: 800, count: 10, selectorMod: ' .info-item'}),
               ...styles.setDelays({base: -300, delay: 800, count: 10, selectorMod: ' .info-label'}),
               ...styles.setDelays({base: -800, delay: 800, count: 10, selectorMod: ' .info-item::before', type: 'animation'}),
           },
    
           '&__label': {
               transition: 'all .7s ease',
               transform: 'translateX(0)',
               opacity: 1
           },
           
           '&__info-item': {
               transition: '0.7s ease',
               opacity: 1
           },
    
           '&__circle-col': {
               '&::before': {
                   transition: 'transform 2s linear',
                   transform: 'rotate(-45deg)'
               },
               
               '&_left': {
                   '&::before': {
                       transitionDelay: '2s'
                   },
               },
    
               '&_right': {
                   '&::before': {
                       transitionDelay: '0s'
                   },
               }
           }
       },
    })
);
