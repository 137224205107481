const initialState = {
    popup: {
        showPopUp: false,
        content: null
    }
};

export default function mainReducer(state = initialState, { type, ...action }) {
    switch (type) {
        case 'TOGGLE_POPUP':
            return {
                ...state,
                popup: { ...state.popup, ...action.payload }
            };

        default:
            return state;
    }
}
