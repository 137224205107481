import React from "react";
import { default as st } from './style';

export default props => {
    const additionalClassName = props.className || '';
    
    return (
        <div className={`${st} ${additionalClassName}`}>
            <div className={`${st}__inner`}>{props.text}</div>
        </div>
    )
    
}



