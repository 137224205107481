import React, { Component } from 'react';
import { default as st } from './style';
import { NavLink } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Image from '../../components/Image';
import FormDefault from '../../containers/FormDefault';
import TextBlock from "../../components/TextBlock";
import MediaQuery from "react-responsive/src/Component";
import ScrollWrap from "../../components/ScrollWrap";
import {styles} from "../../constants";
const { breakpoints } = styles;

export default class MenuPopup extends Component {
    render() {
        const { menu_img, nav, showPopUp, content, contacts } = this.props;

        return (
            <div className={st}>
                <CSSTransition
                    timeout={1100}
                    in={showPopUp}
                    classNames={`${st}__overlay`}
                    unmountOnExit
                >
                    <div className={`${st}__overlay`} />
                </CSSTransition>
                <CSSTransition
                    timeout={1500}
                    in={showPopUp && !content}
                    classNames={`${st}__anim`}
                    unmountOnExit
                >
                    <div className={`${st}__anim`}>
                        <div className={`${st}__content`}>
                            <div className={`${st}__col ${st}__col_left col`}>
                                <div className={`${st}__scroll-wrap`}>
                                    <ScrollWrap>
                                        <div className={`${st}__nav-wrapper`}>
                                            {nav.map((item, index) => {
                                                return (
                                                    <NavLink
                                                        to={item.to}
                                                        title={item.title}
                                                        className={`${st}__nav-item`}
                                                        key={index}
                                                        activeClassName={`${st}__nav-item_active`}
                                                    >
                                                        {item.title}
                                                    </NavLink>
                                                );
                                            })}
                                        </div>
                                    </ScrollWrap>
                                  
                                </div>

                                <MediaQuery maxWidth={breakpoints["550"]}>
                                    <a
                                        className={`${st}__phone`}
                                        href={contacts.phone.href}
                                    >
                                        {contacts.phone.title}
                                    </a>
                                </MediaQuery>
                            </div>
                            <MediaQuery maxWidth={breakpoints["700"]}>
                                {matches =>
                                    !matches && (
                                        <div className={`${st}__col ${st}__col_right col`}>
                                            <div className={`${st}__img-wrapper`}>
                                                <Image src={menu_img} />
                                            </div>
                                        </div>
                                    )
                                }
                            </MediaQuery>
                        </div>
                    </div>
                </CSSTransition>
                <TransitionGroup className={`${st}__transition-group`}>
                    <CSSTransition
                        timeout={1500}
                        in={!!(showPopUp && content)}
                        classNames={`${st}__anim`}
                        unmountOnExit
                        key={content && content.key}
                    >
                        <div className={`${st}__anim`}>
                            {content && (
                                <div
                                    className={`${st}__content ${st}__content_center col`}
                                >
                                    <div className={`${st}__popup-wrap`}>
                                        <div className={`${st}__scroll-wrap`}>
                                            <ScrollWrap>
                                                <div className={`${st}__popup`}>
                                                    {content.ty &&
                                                    <TextBlock content={content.ty} mod='_white' />
                                                    }
                                                    {content.form && (
                                                        <FormDefault
                                                            {...content.form}
                                                            theme="popup"
                                                        />
                                                    )}
                                                    {content.scheme && (
                                                        <div className={`${st}__scheme`}>
                                                            <div
                                                                className={`${st}__scheme-wrapper`}
                                                            >
                                                                <Image
                                                                    src={content.scheme}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </ScrollWrap>
                                            
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}
