import React, { Component } from 'react';
import { default as st } from './style';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import InputStyle from '../../components/InputStyle';

export default class FormStructure extends Component {
    render() {
        const { fields, big_title, title, btn, vertical, theme } = this.props;

        return (
            <div
                className={`${st}${vertical ? ` ${st}_vertical` : ''}${
                    theme ? ` ${st}_${theme}` : ''
                }`}
            >
                <div className={`${st}__content`}>
                    {big_title && (
                        <h2 className={`${st}__big-title`}>{big_title}</h2>
                    )}
                    {title && <h3 className={`${st}__title`}>{title}</h3>}
                    <div className={`${st}__row`}>
                        {fields.map((item, index) => {
                            let { mod_wrap } = item;

                            return (
                                <div
                                    className={`${st}__row-item${
                                        mod_wrap
                                            ? ` ${st}__row-item_${mod_wrap}`
                                            : ''
                                    }`}
                                    key={index}
                                >
                                    <InputStyle {...item} theme={theme} />
                                </div>
                            );
                        })}
                        <div
                            className={`${st}__row-item ${st}__row-item_1of2 ${st}__row-item_top ${st}__row-item_privacy`}
                        >
                            <p className={`${st}__privacy`}>
                                {`Нажимая кнопку ${
                                    btn.text
                                }, вы соглашаетесь с `}
                                <a
                                    className={`${st}__privacy-link`}
                                    title="Политика конфиденциальности и Правила
                                    обработки персональных данных"
                                    href={require(`../../docs/confidentiality.pdf`)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Политикой конфиденциальности и Правилами
                                    обработки персональных данных
                                </a>
                            </p>
                        </div>
                        <div className={`${st}__row-item ${st}__row-item_1of2`}>
                            {theme && theme === 'white' ? (
                                <Button
                                    tag="button"
                                    stretch={true}
                                    {...btn}
                                    icon="icon-icon-btn"
                                />
                            ) : (
                                <Button
                                    tag="button"
                                    bordered={true}
                                    stretch={true}
                                    {...btn}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
FormStructure.propTypes = {
    fields: PropTypes.array.isRequired,
    title: PropTypes.string,
    btn: PropTypes.object.isRequired,
    vertical: PropTypes.bool,
    theme: PropTypes.string
};
