import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';

const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: "flex-start",
        
        h1: {
            ...fonts.h1,
            marginBottom: grid.default[6]
        },
        h2: {
            ...fonts.h2,
            marginBottom: grid.default[5]
        },
        h3: {
            ...fonts.h3,
            marginBottom: grid.default[4]
        },
        img: {
            display: 'block',
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: grid.default[4]
        },
        p: {
            ...fonts.p4,
            marginBottom: grid.default[4],
            
            a: {
                display: 'inline-block',
                marginBottom: 0
            }
        },
        a: {
            ...fonts.p4,
            display: 'block',
            color: colors.rose,
            marginBottom: grid.default[1],
            
            '&:visited': {
                color: colors.rose
            },
            
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        
        '*:last-child': {
            marginBottom: 0
        },
    
        "&._2-col-content": {
            display: 'block',
            columnCount: [2, null, 'unset'],
            columnGap: [80, 35, 'unset']
        },
        
        '&._white': {
            color: colors.white
        }
    })
);
