import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        position: "relative",

        "&__item": {
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: [null, null, null, "column"],
            marginBottom: grid.default[3],
            opacity: 0,
            transform: "translateY(20px)",
            transition: "all 0.3s ease",

            "&:last-of-type": {
                marginBottom: 0
            }
        },

        "&__preview": {
            position: "relative",
            width: [82, 62],
            ...styles.rh(),
            flex: "0 0 auto",

            img: {
                ...styles.absolute(),
                ...styles.of({ fit: "contain" }),
                display: "block"
            }
        },

        "&__description": {
            paddingTop: [grid.default[2][0], 5, null, 0],
            paddingLeft: [30, null, null, 0],
            marginTop: [null, null, null, 20, 15]
        },

        "&__title": {
            ...fonts.p6
        },

        "&__text": {
            ...fonts.p3,
            marginTop: grid.default[1]
        },

        "&_active &": {
            "&__item": {
                ...styles.setDelays({ delay: 150, count: 10 }),
                opacity: 1,
                transform: "translateY(0)"
            }
        }
    })
);
