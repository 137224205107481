import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq, colors } = styles;

export default css(
    mq({
        "&__row": {
            padding: [18, null, "10px 18px"],
            border: `1px solid ${colors.lilaLight}`,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "space-between",
            marginBottom: grid.default[8],
            marginTop: grid.default[6]
        },
        "&__item": {
            display: "block",
            padding: [20, null, "18px 10px"],
            transition: ".3s ease-in-out",
            width: "25%",
            textAlign: "center",
            color: colors.rose,
            "&.active,&:hover": {
                transition: ".3s ease-in-out",
                background: colors.lilaLight,
                color: colors.lila,
                i: {
                    transition: ".3s ease-in-out",
                    color: colors.lila
                }
            },
            span: {
                ...fonts.p5
            }
        },
        "&__icon": {
            marginLeft: 10,
            transition: ".3s ease-in-out",
            color: "rgba(255,255,255,0)",
            fontSize: [null, null, 11]
        },
        ".container": {
            padding: [null, null, null, 0]
        },
        "&__select": {
            borderTop: `1px solid ${colors.lilaLight}`,
            borderBottom: `1px solid ${colors.lilaLight}`,
            paddingTop: 19,
            paddingBottom: 19,
            marginTop: grid.default[6],
            marginBottom: grid.default[8]
        }
    })
);
