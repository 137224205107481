import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";
const { mq } = styles;

export default css(
    mq({
        marginBottom: grid.default[5],
        padding: [null, null, null, 0],
        textAlign: [null, null, null, null, "center"],
        "&__row": {
            display: ["flex", null, null, null, "block"],
            justifyContent: "space-between",
            alignItems: "baseline"
        },

        "&__col": {
            "&_left": {
                width: ["calc(100% / 8 * 5)", null, null, null, "100%"],
                paddingRight: [20, null, null, null, 0]
            },

            "&_right": {
                width: ["calc(100% / 8 * 3)", null, null, null, "100%"],
                paddingLeft: [50, 13, 0]
            }
        },

        "&__title": {
            ...fonts.h1
        },
        "&__subtitle": {
            ...fonts.p2,
            marginTop: grid.default[4]
        },
        "&__price-wrap": {
            display: "flex",
            justifyContent: ["flex-start", null, null, null, "center"],
            alignItems: "baseline",
            marginBottom: grid.default[2],
            marginTop: [null, null, null, null, 10]
        },
        "&__label": {
            ...fonts.p2,
            marginRight: ".55em"
        },
        "&__price": {
            ...fonts.p1,
            color: styles.colors.lilaMiddle,
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }
    })
);
