import React, { Component } from 'react';

import { default as st } from './style';
import IconsList from "../IconsList";
import Lightbox from "react-image-lightbox";
import TextBlock from "../TextBlock";

export default class Finishes extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
           lightBoxCurrentPhotoIndex: 0,
           lightBoxIsOpen: false,
        }
    }

    render() {
        const
            { title, text, list } = this.props,
            { lightBoxCurrentPhotoIndex, lightBoxIsOpen } = this.state;
        
        return (
            <div className={st}>
                {title &&
                    <h3 className={`${st}__title`}>
                        {title}
                    </h3>
                }
    
                {text &&
                    <h3 className={`${st}__text-container`}>
                        <TextBlock content={text} />
                    </h3>
                }
    
                {list &&
                    <div className={`${st}__list`}>
                        {
                            list.map(({img, label}, key) => {
                                const { preview } = img;
                                
                                return (
                                    <div key={key} className={`${st}__item`}>
                                        <div className={`${st}__img-wrap`} onClick={() => this.setState({
                                            lightBoxIsOpen: true,
                                            lightBoxCurrentPhotoIndex: key
                                        })}>
                                            <img className={`${st}__img`} src={preview} alt={label} />
                                        </div>
            
                                        <span className={`${st}__label`}>
                                            {label}
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
    
                {list && lightBoxIsOpen && (
                    <Lightbox
                        mainSrc={list[lightBoxCurrentPhotoIndex]['img']['full']}
                        nextSrc={list[(lightBoxCurrentPhotoIndex + 1) % list.length]['img']['full']}
                        prevSrc={list[(lightBoxCurrentPhotoIndex + list.length - 1) % list.length]['img']['full']}
                        onCloseRequest={() => this.setState({ lightBoxIsOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                lightBoxCurrentPhotoIndex: (lightBoxCurrentPhotoIndex + list.length - 1) % list.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                lightBoxCurrentPhotoIndex: (lightBoxCurrentPhotoIndex + 1) % list.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
