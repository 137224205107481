import React, { Component } from 'react';
import { connect } from 'react-redux';
import { default as st } from './style';
import { addZeroToNumber } from '../../helpers/functions';

class SliderHomeDots extends Component {
    render() {
        const { slides_count, sliderHome } = this.props;

        return (
            <div className={st}>
                <span className={`${st}__dots-number`}>01</span>
                <div className={`${st}__dots-line-empty`}>
                    <div
                        className={`${st}__dots-line-fill`}
                        style={{
                            top: `calc((100% / ${slides_count}) * ${sliderHome.currentSlideIndex +
                                1})`
                        }}
                    />
                </div>
                <span className={`${st}__dots-number`}>
                    {addZeroToNumber(slides_count, 2)}
                </span>
            </div>
        );
    }
}

function mapStateToProps({ sliderHome }) {
    return { sliderHome };
}

export default connect(
    mapStateToProps,
    null
)(SliderHomeDots);
