import React, { Component, Fragment } from 'react';
import { default as st } from './style';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

export default class InputStyle extends Component {
    getErrorMod = (form, name) => {
        return form.touched[name] && form.errors[name] ? `_error` : ''
    };
    
    render() {
        const {
                tag,
                name,
                placeholder,
                label,
                type = 'text',
                mask,
                mask_char = '_',
                theme
            } = this.props,
            TagInput = tag || 'input';
        
        return (
            <div
                className={`${st}${
                    tag === 'textarea' ? ` ${st}_textarea` : ''
                }${theme ? ` ${st}_${theme}` : ''}`}
            >
                <Field name={name}>
                    {({ field, form }) => (
                        <Fragment>
                            {label && (
                                <span className={`${st}__label ${this.getErrorMod(form, name)}`}>{label}</span>
                            )}
                            {mask ? (
                                <InputMask
                                    {...field}
                                    type={type}
                                    mask={mask}
                                    placeholder={placeholder}
                                    maskChar={mask_char}
                                    className={`${st}__input ${this.getErrorMod(form, name)}`}
                                />
                            ) : (
                                <TagInput
                                    {...field}
                                    type={type}
                                    className={`${st}__input ${this.getErrorMod(form, name)}`}
                                    placeholder={placeholder}
                                />
                            )}
                        </Fragment>
                    )}
                </Field>
            </div>
        );
    }
}
InputStyle.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    tag: PropTypes.string,
    mask: PropTypes.string,
    mask_char: PropTypes.string,
    value: PropTypes.string,
    theme: PropTypes.string
};
