import React from 'react';
import { default as st } from './style';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const TitleBlock = ({
    title,
    subtitle,
    level = 1,
    containerMod = 'container_right'
}) => {
    const CustomTag = `h${level}`;

    return (
        <div className="block-wrapper">
            <div className={`container ${containerMod}`}>
                <div className={`${st} col`}>
                    <CustomTag className={`${st}__title`}>
                        {parse(title)}
                    </CustomTag>

                    {subtitle && (
                        <p className={`${st}__subtitle`}>{subtitle}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

TitleBlock.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    containerMod: PropTypes.string,
    level: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default TitleBlock;
