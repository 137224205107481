import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        position: "relative",
        maxWidth: ["calc(100% / 9 * 7)", null, null, "100%"],
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: grid.default[6],
        marginBottom: grid.default[9],
        padding: [null, null, null, 0],

        "&__frame-wrap": {
            display: "block",
            position: "absolute",
            top: 0,
            left: [
                "calc(100% / 7 * 1.5)",
                "calc(100% / 7 * 1.73)",
                "12.5%",
                "15.5%"
            ],
            width: [
                "calc(100% / 7 * 2.1)",
                "calc(100% / 7 * 3)",
                "62%",
                "58%",
                "69%"
            ],
            height: ["calc(100% - 45px)", null, null, null, "100%"],
            maxHeight: "100%",
            zIndex: 1
        },

        "&__content": {
            display: "block",
            position: "relative",
            zIndex: 2,
            paddingRight: ["50px", 0],
            minHeight: [630, 600, null, 520, 0]
        },

        p: {
            ...fonts.p4,
            position: "relative",
            width: [
                "calc(100% / 7 * 3 - 50px)",
                "calc(100% / 7 * 3 - 22px)",
                "45%",
                "calc(50% - 20px)",
                "100%"
            ],
            paddingTop: grid.default[3],
            marginLeft: 0,
            marginRight: "auto",
            transition: "1s ease-out",
            transform: "translate(0,20%)",
            opacity: 0,
            zIndex: 2,
            "&:first-child": {
                marginLeft: "auto",
                marginRight: 0
            }
        },
        "&_active": {
            p: {
                transition: "1s ease-out",
                transform: "translate(0,0)",
                opacity: 1
            }
        },

        "&._big-margins": {
            marginTop: grid.default[9]
        }
    })
);
