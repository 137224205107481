import React, { Component } from 'react';
import { default as st } from './style';
import MediaQuery from 'react-responsive';

import LinkCustom from '../../components/LinkCustom';
import TextCollapse from '../../components/TextCollapse';

import { styles } from '../../constants';

const { breakpoints } = styles;

export default class CatalogItem extends Component {
    render() {
        const { img, title, text, to, reverse } = this.props;

        return (
            <div className={`${st}${reverse ? ` ${st}_reverse` : ''}`}>
                <div className={`${st}__col ${st}__col_image`}>
                    <div className={`${st}__img-block`}>
                        <div className={`${st}__img-wrapper`}>
                            <img src={img} alt={''} />
                        </div>
                    </div>
                </div>
                <div className={`${st}__col ${st}__col_content`}>
                    <div className={`${st}__content`}>
                        {title && <h2 className={`${st}__title`}>{title}</h2>}
                        {text && (
                            <MediaQuery maxWidth={breakpoints['550']}>
                                {matches =>
                                    matches ? (
                                        <div className={`${st}__text`}>
                                            <TextCollapse text={text} />
                                        </div>
                                    ) : (
                                        <p className={`${st}__text`}>{text}</p>
                                    )
                                }
                            </MediaQuery>
                        )}

                        <div className={`${st}__link`}>
                            <LinkCustom
                                to={to}
                                title="Подробнее"
                                icon="arrow-right-small"
                            />
                        </div>
                    </div>
                    <div className={`${st}__border`}>
                        <div className={`${st}__border-line`} />
                        <div className={`${st}__border-line`} />
                        <div className={`${st}__border-line`} />
                        <div className={`${st}__border-line`} />
                    </div>
                </div>
            </div>
        );
    }
}
