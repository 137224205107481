import React, { Component } from "react";
import { default as st } from "./style";
import {InView} from "react-intersection-observer";
import Image from "../Image";
import TextBlock from "../TextBlock";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import {styles} from "../../constants";
const { breakpoints } = styles;

export default class twinImages extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            lbCurrent: 0,
            lbIsOpen: false,
        };
    
        this.disableLinks = false;
        this.clickPos = 0;
    }
    
    toggleLinks = bool => {
        this.disableLinks = bool;
    };
    
    handleOnMouseDown = e => {
        this.clickPos = e.pageX;
    };
    
    openImg = (e, key) => {
        const diff = Math.abs(this.clickPos - e.pageX) > 5;
    
        if (!this.disableLinks && !diff) {
            this.setState({
                lbIsOpen: true,
                lbCurrent: key
            });
        }
    }
    
    renderItems = (images) => {
        return (
            images.map(({preview}, key) => {
                return (
                    <div key={key} className={`${st}__item`}>
                        <div
                            className={`${st}__img-wrap`}
                            onClick={e => {this.openImg(e, key)}}
                            onMouseDown={e => this.handleOnMouseDown(e)}
                        >
                            <img className={`${st}__img`} src={preview} alt={`img-${key}`} draggable="false"/>
                        </div>
                    </div>
                )
            })
        )
    }
    
    render() {
        const
            { title, description, images, bottom, mod } = this.props,
            { lbCurrent, lbIsOpen } = this.state,
            sliderSettings = {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: false,
                speed: 1000,
                arrows: true,
                fade: false,
                centerPadding: "0",
                responsive: [
                    {
                        breakpoint: breakpoints["700"] + 1,
                        settings: {
                            arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: breakpoints["550"] + 1,
                        settings: {
                            arrows: false,
                            dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            };
        
        return (
            <InView threshold={0.4}>
                {({inView, ref}) => {
                    const activeMod = inView ? ` ${st}_active` : "";
                    
                    return (
                        <div
                            className={`${st} ${activeMod}${mod ? ` ${st}_${mod}` : ""}`}
                            ref={ref}
                        >
                            <div className={`${st}__container`}>
                                {(title || description) && (
                                    <div className={`${st}__title-wrap`}>
                                        {title && <div className={`${st}__title`}>{title}</div>}
                    
                                        {description && (
                                            <div className={`${st}__description`}>
                                                <TextBlock content={description} />
                                            </div>
                                        )}
                                    </div>
                                )}
    
                                {images && images.length > 2
                                    ?
                                        <Slider
                                            beforeChange={this.toggleLinks.bind(this, true)}
                                            onSwipe={this.toggleLinks.bind(this, true)}
                                            afterChange={this.toggleLinks.bind(this, false)}
                                            className={`${st}__list`}
                                            {...sliderSettings}
                                        >
                                            {
                                                this.renderItems(images)
                                            }
                                        </Slider>
                                    :
                                        <div className={`${st}__list`}>
                                            {
                                                this.renderItems(images)
                                            }
                                        </div>
                                }
            
                                {bottom && (
                                    <div className={`${st}__bottom-wrap`}>
                                        <div className={`${st}__col ${st}__col_left`}>
                                            <div className={`${st}__bottom`}>
                                                {bottom.link && (
                                                    <a
                                                        href={bottom.link.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className={`${st}__link`}
                                                    >
                                                        {bottom.link.text}
                                                    </a>
                                                )}
                            
                                                {bottom.label && (
                                                    <span className={`${st}__label`}>
                                                        {bottom.label}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {images && lbIsOpen && (
                                    <Lightbox
                                        mainSrc={images[lbCurrent]['full']}
                                        nextSrc={images[(lbCurrent + 1) % images.length]['full']}
                                        prevSrc={images[(lbCurrent + images.length - 1) % images.length]['full']}
                                        onCloseRequest={() => this.setState({ lbIsOpen: false })}
                                        onMovePrevRequest={() =>
                                            this.setState({
                                                lbCurrent: (lbCurrent + images.length - 1) % images.length,
                                            })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                lbCurrent: (lbCurrent + 1) % images.length,
                                            })
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )
                }}
            </InView>
        )
    }
}
