import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";
const { mq } = styles;

export default css(
    mq({
        paddingBottom: [null, null, null, null, 80],
        "&__header": {
            ...fonts.h2,
            color: styles.colors.greyDark,
            marginBottom: grid.default[6],
            textAlign: "center"
        },
        "&__content": {
            display: "flex",
            flexDirection: ["row", null, "column"],
            justifyContent: "space-between",
            alignItems: "stretch"
        },

        "&__map": {
            width: ["calc(100% / 9 * 5 - 10px)", null, "100%"],
            height: [null, null, 320, 274, 280]
        },

        "&__form": {
            width: ["calc(100% / 9 * 4 - 10px)", null, "100%"],
            padding: ["0 50px 0 30px", "0 30px 0 10px", "15px 0 0", "24px 0 0"]
        }
    })
);
