import React, { Component } from 'react';
import { default as st } from './style';
import MediaQuery from 'react-responsive';

import FilterCheckbox from '../../components/FilterCheckbox';
import SelectCustom from '../../components/SelectCustom';

import { styles } from '../../constants';

const { breakpoints } = styles;

export default class CatalogFilterItem extends Component {
    render() {
        const { title, fields, name } = this.props;

        return (
            <div className={st}>
                {title && <p className={`${st}__title`}>{title}</p>}
                <div className={`${st}__wrapper`}>
                    <MediaQuery maxWidth={breakpoints['1100']}>
                        {matches =>
                            matches ? (
                                <div className={`${st}__select-wrapper`}>
                                    <SelectCustom
                                        options={fields}
                                        name={name}
                                        isMulti={true}
                                    />
                                </div>
                            ) : (
                                <div className={`${st}__row`}>
                                    {fields.map((item, index) => {
                                        return (
                                            <div
                                                className={`${st}__row-item`}
                                                key={index}
                                            >
                                                <FilterCheckbox
                                                    name={name}
                                                    {...item}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )
                        }
                    </MediaQuery>
                </div>
            </div>
        );
    }
}
