import React, { Component } from 'react'
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
configureAnchors({ scrollDuration: 1000 });

export default class AnchorWrap extends Component {
	render() {
		const
			{ anchor, children } = this.props;

		return (
			(anchor && anchor.length)
				?
					<ScrollableAnchor id={anchor} >
						<div>
							{children}
						</div>
					</ScrollableAnchor>
				:
					children
		)
	}
}
