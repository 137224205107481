import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors, fontFamily } = styles;

export default css(
    mq({
        '&__label': {
            ...fonts.labelForm,
            display: 'block',
            marginBottom: 3,
            color: colors.white,
    
            '&._error': {
                color: colors.rose
            }
        },
        '&__input': {
            ...fonts.placeholder,
            display: 'block',
            backgroundColor: colors.white,
            boxSizing: 'border-box',
            resize: 'none',
            width: '100%',
            height: [210, 100],
            padding: ['30px', '17px 20px', null, '17px 25px', '14px 20px'],
            color: colors.greyDark,
            fontFamily: fontFamily.robo,
            ':placeholder': {
                opacity: 0.3
            },
            '::-moz-placeholder': {
                lineHeight: ['81px', '60px', null, null, '51px']
            },
            
            '&._error': {
                backgroundColor: colors.rose,
            }
        },
        '&__input:not(textarea)': {
            height: [81, 60, null, null, 51],
            padding: ['0 30px', '0 20px', null, '0 25px', '0 20px']
        },
        '&_white &': {
            '&__label': {
                color: colors.lila,
                
                '&._error': {
                    color: colors.rose
                }
            },
            '&__input': {
                backgroundColor: '#f6f3f7',
                
                '&._error': {
                    backgroundColor: colors.rose
                }
            }
        }
    })
);
