import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq } = styles;

export default css(
    mq({
        display: [null, "flex"],
        justifyContent: "space-between",
        flexWrap: "wrap",
        "&__item": {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: [null, "calc(50% - 10px)", null, null, "100%"],
            marginBottom: grid.default[2],

            "&:last-of-type": {
                marginBottom: 0
            }
        },

        "&__icon": {
            position: "relative",
            width: 34,
            height: 40,
            flex: "0 0 auto",

            img: {
                ...styles.absolute(),
                ...styles.of({ fit: "contain" }),
                display: "block"
            }
        },

        "&__text-wrap": {
            display: "block",
            position: "relative",
            paddingTop: grid.default[0]
        },

        "&__text": {
            display: "block",
            position: "relative",
            ...fonts.p3,
            paddingLeft: 42,

            "&::before": {
                content: '""',
                display: "block",
                ...styles.absolute(".8em", "auto", "auto", 15),
                width: 14,
                height: 1,
                background: styles.colors.greyDark
            }
        }
    })
);
