import { css } from "emotion";
import { styles } from "../../constants";

const { mq } = styles;

// export default css(
//     mq({
//         display: "block",
//         overflow: "hidden",
//         width: ["1em", null, null, null, "auto"],
//         lineHeight: "1 !important",
//
//         "&__inner": {
//             fontSize: "inherit",
//             display: "inline-block",
//             whiteSpace: ["nowrap", null, null, null, "normal"],
//             transform: ["rotate(270deg)", null, null, null, "none"],
//             transformOrigin: "50% 50%",
//
//             ":before": {
//                 content: ['""', null, null, null, "none"],
//                 float: "left",
//                 paddingTop: "100%"
//             }
//         }
//     })
// );


export default css(
    mq({
        display: "block",
        overflow: "hidden",
        width: "1em",
        lineHeight: "1 !important",

        "&__inner": {
            fontSize: "inherit",
            display: "inline-block",
            whiteSpace: "nowrap",
            transform: "rotate(270deg)",
            transformOrigin: "50% 50%",

            ":before": {
                content: '""',
                float: "left",
                paddingTop: "100%"
            }
        }
    })
);
