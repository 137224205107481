import React, { Component } from "react";
import { default as st } from "./style";
import TextBlock from "../TextBlock";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import PersonInfo from "../PersonInfo";

class PersonQuote extends Component {
    render() {
        const { content, person_info } = this.props;

        return (
            <InView threshold={0.4}>
                {({ inView, ref }) => {
                    const activeMod = inView ? ` ${st}_active` : "";

                    return (
                        <div className={`${st} ${activeMod}`} ref={ref}>
                            <div className={`${st}__table container_narrow `}>
                                <div
                                    className={`${st}__col ${st}__col_left col`}
                                >
                                    <TextBlock content={content} />
                                </div>

                                <div
                                    className={`${st}__col ${st}__col_right col`}
                                >
                                    <PersonInfo {...person_info} />
                                </div>
                            </div>
                        </div>
                    );
                }}
            </InView>
        );
    }
}

PersonQuote.propTypes = {
    content: PropTypes.string.isRequired,
    person_info: PropTypes.object.isRequired
};

export default PersonQuote;
