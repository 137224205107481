import React from "react";
import { default as st } from "./style";
import { useInView } from "react-intersection-observer";
import { css } from "emotion";
import RotatedText from "../RotatedText";
import InfoWindow from "../InfoWindow";
import Image from "../Image";
import MediaQuery from "react-responsive";

import { styles } from "../../constants";

const { breakpoints } = styles;

export default ({ title, aside_text, image, list }) => {
    const [ref, inView] = useInView({
            threshold: 0.4
        }),
        activeMod = inView ? ` ${st}_active` : "";

    return (
        <div className={`${st} ${activeMod} `} ref={ref}>
            <h2 className={`${st}__title`}>{title}</h2>

            <div className={`${st}__table`}>
                <MediaQuery maxWidth={breakpoints["550"]}>
                    {matches =>
                        !matches && (
                            <div className={`${st}__col ${st}__col_left`}>
                                <RotatedText
                                    className={`${st}__aside-text`}
                                    text={aside_text}
                                />
                            </div>
                        )
                    }
                </MediaQuery>

                <div className={`${st}__col col ${st}__col_right`}>
                    <div className={`${st}__advantages-container`}>
                        <div className={`${st}__img-wrap`}>
                            <Image src={image} />
                        </div>

                        <div className={`${st}__list`}>
                            {list.map((item, key) => {
                                const {
                                        num = "",
                                        top = "0",
                                        left = "0",
                                        content = {}
                                    } = item,
                                    styles = css({
                                        top: top,
                                        left: left
                                    });

                                return (
                                    <InfoWindow
                                        content={content}
                                        key={key}
                                        className={`${st}__info-wrap ${styles}`}
                                    >
                                        <div
                                            className={`${st}__info-item info-item`}
                                        >
                                            <div className={`${st}__num`}>
                                                {num}
                                            </div>
                                        </div>
                                    </InfoWindow>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
