import React, { Component } from "react";
import { default as st } from "./style";
import TextBlock from "../TextBlock";
import PropTypes from "prop-types";
import TitleBlock from "../TitleBlock";
import { InView } from "react-intersection-observer";

class Description extends Component {
    render() {
        const {
            content,
            title,
            titleLevel,
            content_title,
            styleMod = "",
            contentMod = ""
        } = this.props;

        return (
            <InView threshold={0.4}>
                {({ inView, ref }) => {
                    const activeMod = inView ? ` ${st}_active` : "";

                    return (
                        <div
                            className={`${st} ${activeMod} ${styleMod}`}
                            ref={ref}
                        >
                            {title && (
                                <div className={`${st}__title-wrap`}>
                                    <TitleBlock
                                        {...title}
                                        level={titleLevel}
                                        containerMod="container_narrow"
                                    />
                                </div>
                            )}

                            <div
                                className={`${st}__container col container_narrow`}
                            >
                                {content_title && (
                                    <h3 className={`${st}__content-title`}>
                                        {content_title}
                                    </h3>
                                )}

                                <TextBlock content={content} mod={contentMod} />
                            </div>
                        </div>
                    );
                }}
            </InView>
        );
    }
}

Description.propTypes = {
    title: PropTypes.object,
    content_title: PropTypes.string,
    content: PropTypes.string.isRequired,
    titleLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    styleMod: PropTypes.string,
    contentMod: PropTypes.string
};

export default Description;
