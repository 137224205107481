import React from 'react';
import { default as st } from './style';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import Image from '../../components/Image';

export default props => {
    const [ref, inView] = useInView({
        threshold: 0.4
    });
    return (
        <div className={`${st}${inView ? ` ${st}_active` : ''}`} ref={ref}>
            <h2 className={`${st}__header col`}>Почему кабины b-intense?</h2>
            <div className={`${st}__content`}>
                {props.items.map((item, index) => (
                    <div
                        key={index}
                        className={`${st}__block col`}
                        style={{ transitionDelay: `${index * 0.2}s` }}
                    >
                        <Link
                            className={`${st}__link`}
                            to={item.href}
                            title={item.text}
                        >
                            <div className={`${st}__border`}>
                                <div className={`${st}__border-line`} />
                                <div className={`${st}__border-line`} />
                                <div className={`${st}__border-line`} />
                                <div className={`${st}__border-line`} />
                            </div>
                            <div className={`${st}__item-content`}>
                                <div className={`${st}__icon`}>
                                    <Image src={item.img} />
                                </div>
                                <p className={`${st}__text`}>{item.text}</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};
