import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SectionGrid from "../../components/SectionGrid";
import TwinImages from "../../components/TwinImages";

import * as mainActions from "../../actions/main.js";
import { urls } from "../../constants";
import Banner from "../../components/Banner";
import Description from "../../components/Description";
import GreySlider from "../../components/GreySlider";
import Breadcrumbs from "../../components/Breadcrumbs";
import isNotEmpty from "../../utils/is-not-empty";

class About extends Component {
    componentDidMount() {
        const { routePath } = this.props;

        if (
            !this.props.mainStore.fetchResult ||
            !this.props.mainStore.fetchResult.hasOwnProperty(routePath)
        ) {
            this.props.mainActions.fetchQuery({
                type: "GET",
                url: urls.about,
                alias: routePath
            });
        }
    }

    render() {
        const { mainStore, routePath } = this.props,
            { fetchResult } = mainStore;

        return (
            <Fragment>
                {fetchResult[routePath] && (
                    <Fragment>
                        {isNotEmpty(fetchResult[routePath].breadcrumbs) &&
                            <Breadcrumbs
                                links={fetchResult[routePath].breadcrumbs}
                            />
                        }
    
                        {isNotEmpty(fetchResult[routePath].banner) &&
                            <SectionGrid right_column_hide={true}>
                                <Banner
                                    images={fetchResult[routePath].banner}
                                    frame={true}
                                    big
                                />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].description) &&
                            <SectionGrid>
                                <Description
                                    {...fetchResult[routePath].description}
                                />
                            </SectionGrid>
                        }
    
                        {isNotEmpty(fetchResult[routePath].twin_images) &&
                            <SectionGrid
                                right_column={{
                                    logo: true
                                }}
                            >
                                <TwinImages
                                    {...fetchResult[routePath].twin_images}
                                    mod="col"
                                />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].slider) &&
                            <SectionGrid last={true}>
                                <GreySlider {...fetchResult[routePath].slider} />
                            </SectionGrid>
                        }
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ mainStore }) {
    return { mainStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
