import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq, colors } = styles;

export default css(
    mq({
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "stretch",
        flexDirection: [null, null, "column"],
        width: [null, null, "calc(100% / 9 * 8)", "100%"],
        marginBottom: grid.default[9],
        marginLeft: "auto",
        paddingLeft: [null, null, 10, 0],
        "&__title": {
            ...fonts.h3,
            marginBottom: grid.default[4]
        },

        "&__col": {
            display: "flex",
            alignItems: "flex-start",

            "&_left": {
                width: [
                    "calc(100% / 9 * 4 + 30px)",
                    "calc(100% / 9 * 4 - 10px)",
                    "100%"
                ]
            },
            "&_right": {
                width: ["calc(100% / 9 * 4 - 30px)", null, "100%"],
                maxWidth: "550px",
                marginLeft: ["calc(100% / 9 - 20px)", 47, 0],
                marginTop: [null, null, 35, null, 26]
            }
        },

        "&__content": {
            transform: "translateY(50px)",
            opacity: 0,
            width: "100%",
            margin: "auto",
            transition: ".5s ease"
        },

        "&__img-wrap": {
            position: "relative",
            display: "block",
            width: "100%",
            overflow: "hidden",
            height: [null, null, 250, 214, 230],

            "&::before": {
                content: '""',
                display: "block",
                ...styles.absolute(),
                width: "100%",
                height: "100%",
                background: colors.white,
                transition: ".5s ease",
                zIndex: 3
            },

            img: {
                display: "block",
                width: "100%",
                height: ["auto", null, "100%"],
                zIndex: 1,
                objectFit: [null, null, "cover"]
            }
        },

        "&_active &": {
            "&__img-wrap": {
                "&::before": {
                    transform: "translateX(101%)"
                }
            },

            "&__content": {
                transform: "translateY(0)",
                opacity: 1
            }
        }
    })
);
