import { css } from "emotion";
import { styles, grid, fonts } from "../../constants";
const { mq } = styles;

export default css(
    mq({
        marginTop: grid.default[8],
        marginBottom: grid.default[9],

        "&__title-wrap": {
            marginBottom: grid.default[6]
        },

        "&__content-title": {
            ...fonts.h3,
            marginBottom: grid.default[4]
        },

        "&__container": {
            transition: ".5s ease",
            transform: "translateY(100px)",
            opacity: 0,
            padding: [null, null, null, 0]
        },

        "&._big-margins &": {
            "&__title-wrap": {
                marginBottom: grid.default[9]
            }
        },

        "&._clear-top": {
            marginTop: 0
        },

        "&_active &": {
            "&__container": {
                transform: "translateY(0)",
                opacity: 1
            }
        }
    })
);
