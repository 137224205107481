import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SectionGrid from "../../components/SectionGrid";
import TitleBlock from "../../components/TitleBlock";
import Banner from "../../components/Banner";
import LinkFieldset from "../../components/LinkFieldset";
import IntroText from "../../components/Introtext";
import Pluses from "../../components/Pluses";
import InteriorItem from "../../components/InteriorItem";
import FormDefault from "../../containers/FormDefault";

import * as mainActions from "../../actions/main.js";
import { urls } from "../../constants";
import Breadcrumbs from "../../components/Breadcrumbs";

import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import isNotEmpty from "../../utils/is-not-empty";
configureAnchors({ scrollDuration: 1000 });

class Interior extends Component {
    componentDidMount() {
        const { routePath } = this.props;

        if (
            !this.props.mainStore.fetchResult ||
            !this.props.mainStore.fetchResult.hasOwnProperty(routePath)
        ) {
            this.props.mainActions.fetchQuery({
                type: "GET",
                url: urls.interior,
                alias: routePath
            });
        }
    }

    render() {
        const { mainStore, routePath } = this.props,
            { fetchResult } = mainStore;

        return (
            <Fragment>
                {fetchResult[routePath] && (
                    <Fragment>
                        {isNotEmpty(fetchResult[routePath].breadcrumbs) &&
                            <Breadcrumbs
                                links={fetchResult[routePath].breadcrumbs}
                            />
                        }
      
    
                        {isNotEmpty(fetchResult[routePath].banner) &&
                            <SectionGrid right_column_hide={true}>
                                <Banner images={fetchResult[routePath].banner} />
                            </SectionGrid>
                        }
                     
                        
                        {isNotEmpty(fetchResult[routePath].items) &&
                            <SectionGrid>
                                <LinkFieldset data={fetchResult[routePath].items} />
                            </SectionGrid>
                        }
                   
    
                        {isNotEmpty(fetchResult[routePath].title) &&
                            <SectionGrid>
                                <TitleBlock {...fetchResult[routePath].title} />
                            </SectionGrid>
                        }
                 
    
                        {isNotEmpty(fetchResult[routePath].intro) &&
                            <SectionGrid>
                                <IntroText {...fetchResult[routePath].intro} />
                            </SectionGrid>
                        }
                   
    
                        {isNotEmpty(fetchResult[routePath].adv) &&
                            fetchResult[routePath].adv.map((item, index) => (
                                <Fragment key={index}>
                                    <SectionGrid>
                                        <Pluses
                                            {...item}
                                            inflow={index !== 0}
                                        />
                                    </SectionGrid>
                                    <SectionGrid>
                                        {fetchResult[routePath].items.map(
                                            (interior, key) =>
                                                interior.category ===
                                                item.categoryId ? (
                                                    <ScrollableAnchor
                                                        key={key}
                                                        id={interior.anchor}
                                                    >
                                                        <div>
                                                            <InteriorItem
                                                                {...interior}
                                                                reverse={key % 2}
                                                            />
                                                        </div>
                                                    </ScrollableAnchor>
                                                ) : (
                                                    ""
                                                )
                                        )}
                                    </SectionGrid>
                                </Fragment>
                            ))
                        }
    
                        {isNotEmpty(fetchResult[routePath].form) &&
                            <SectionGrid last={true}>
                                <FormDefault
                                    theme="white"
                                    {...fetchResult[routePath].form}
                                />
                            </SectionGrid>
                        }
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ mainStore }) {
    return { mainStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Interior);
