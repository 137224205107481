import { css, keyframes } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq, colors } = styles;

const breath = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    
    100% {
        opacity: 0;
        transform: scale(1.5);
    }
`;

export default css(
    mq({
        position: "relative",
        marginBottom: grid.default[9],

        "&__title": {
            ...fonts.h2,
            marginBottom: grid.default[6]
        },

        "&__aside-text": {
            ...fonts.p0,
            color: colors.lilaLight,
            transform: "translateY(101%)",
            transition: "1s ease"
        },

        "&__table": {
            position: "relative",
            width: ["calc(100% / 9 * 6)", null, "calc(100% / 9 * 7)", "100%"],
            marginLeft: ["calc(100% / 9)", null, null, 0],
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            marginRight: "auto"
        },

        "&__col": {
            "&_left": {
                width: "calc(100% / 6)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                overflow: "hidden"
            },

            "&_right": {
                width: ["calc(100% / 6 * 5)", null, null, null, "100%"],
                padding: ["0 90px", "0 10px 0 51px", 0, "0 14px", 0]
            }
        },

        "&__advantages-container": {
            position: "relative",
            display: "block",
            width: "100%"
        },

        "&__list": {
            ...styles.absolute(),
            display: "block",
            width: "100%",
            height: "100%"
        },

        "&__info-wrap": {
            ...styles.absolute("0", "auto", "auto", "0"),
            transform: "translate(-50%, -50%)",
            opacity: "0",
            ...styles.setDelays({
                selectorMod: " .info-item::before",
                type: "animation",
                count: 10,
                delay: 500
            }),
            transition: "opacity .5s ease",
            ...styles.setDelays({ type: "transition", count: 10, delay: 150 })
        },

        "&__info-item": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: [69, 49, null, null, 44],
            height: [69, 49, null, null, 44],
            borderRadius: "50%",
            background: colors.white,
            cursor: "pointer",
            transition: "0.3s ease",

            "&::before": {
                content: '""',
                display: "block",
                ...styles.absolute(),
                width: "100%",
                height: "100%",
                background: "inherit",
                borderRadius: "50%",
                animation: `${breath} 3s linear infinite`,
                transformOrigin: "50% 50%",
                pointerEvents: "none"
            },

            "&:hover": {
                background: colors.lilaMiddle
            }
        },

        "&__num": {
            position: "relative",
            display: "block",
            ...fonts.p6,
            color: colors.lilaMiddle,
            transition: "0.3s ease",
            zIndex: 3
        },

        "&__info-item:hover &": {
            "&__num": {
                color: colors.white
            }
        },

        "&__img-wrap": {
            position: "relative",
            display: "block",
            width: "100%",

            img: {
                position: "relative",
                display: "block",
                width: "100%",
                height: "auto"
            }
        },

        "&_active &": {
            "&__aside-text": {
                transform: "translateY(0)"
            },

            "&__info-wrap": {
                opacity: "1"
            }
        }
    })
);
