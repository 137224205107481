import React, { Component } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { st, sst } from "./style";

const ValueContainer = ({ children, ...props }) => {
		const { selectProps } = props,
			{ className, value } = selectProps;

		return (
			<components.ValueContainer {...props}>
				<p className={`${className}__label`}>
					{value.label}
					<i
						className={`${className}__label-icon icon-arrowstroke`}
					/>
				</p>
				<div className={`${className}__child-wrapper`}>{children}</div>
			</components.ValueContainer>
		);
	},
	DropdownIndicator = props => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<i
						className={`${st}__icon icon-angle-down ${
							props.selectProps.menuIsOpen ? ` _active` : ""
						}`}
					/>
				</components.DropdownIndicator>
			)
		);
	};
export default class SelectLinkFieldset extends Component {
	render() {
		const { options } = this.props;

		return (
			<div className={st}>
				<Select
					name="link-fieldset"
					components={{
						DropdownIndicator,
						ValueContainer
					}}
					className={st}
					styles={sst}
					options={options}
					defaultValue={options[0]}
					onChange={props => {
						window.location.hash = props.anchor;
					}}
				/>
			</div>
		);
	}
}

SelectLinkFieldset.propsTypes = {
	options: PropTypes.array.isRequired
};
