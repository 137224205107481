import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as mainActions from '../../actions/main.js';
import { urls } from '../../constants';

import SectionGrid from '../../components/SectionGrid';
import MapSection from '../../components/MapSection';
import ContactsAbout from '../../components/ContactsAbout';
import Breadcrumbs from "../../components/Breadcrumbs";
import isNotEmpty from "../../utils/is-not-empty";

class Contacts extends Component {
    componentDidMount() {
        const { routePath } = this.props;
    
        if (
            !this.props.mainStore.fetchResult ||
            !this.props.mainStore.fetchResult.hasOwnProperty(
                routePath
            )
        ) {
            this.props.mainActions.fetchQuery({
                type: 'GET',
                url: urls.contacts,
                alias: routePath
            });
        }
    }
    render() {
        const { mainStore, routePath } = this.props,
            { fetchResult } = mainStore;
        
        return (
            <Fragment>
                {fetchResult[routePath] && (
                    <Fragment>
                        {isNotEmpty(fetchResult[routePath].breadcrumbs) &&
                            <Breadcrumbs links={fetchResult[routePath].breadcrumbs} />
                        }
    
                        {isNotEmpty(fetchResult[routePath].about) &&
                            <SectionGrid>
                                <ContactsAbout {...fetchResult[routePath].about} />
                            </SectionGrid>
                        }
    
                        {isNotEmpty(fetchResult[routePath].map) &&
                            <SectionGrid last={true}>
                                <MapSection {...fetchResult[routePath].map} />
                            </SectionGrid>
                        }
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ mainStore }) {
    return { mainStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contacts);
