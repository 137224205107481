import { css } from 'emotion';
import {styles, fonts, grid} from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        width: '100%',
        marginTop: 25,
        
        "&__title": {
            marginBottom: 10,
            ...fonts.p2,
        },
        "&__text-container": {
            marginBottom: 20
        },
        "&__list": {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: 'calc(100% + 8px)',
            left: -4,
            marginTop: -20,
        },
        "&__item": {
            width: ['calc(25% - 8px)', 'calc(100% / 3 - 8px - 0.1px)', 'calc(50% - 8px)'],
            margin: '20px 4px 0',
        },
        "&__img-wrap": {
            width: '100%',
            cursor: 'pointer'
        },
        "&__img-wrap:hover &__img": {
            transform: 'translateY(-5px)'
        },
        "&__img": {
            display: 'block',
            position: 'relative',
            maxWidth: '100%',
            height: 'auto',
            marginRight: 'auto',
            transition: 'transform .3s ease'
        },
        "&__label": {
            width: '100%',
            padding: '0 5px',
            marginTop: 10
        },
    })
);
