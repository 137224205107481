import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import validate from "../../utils/validate-yup";
import collect_schema from "../../utils/collect-schema";
import { urls } from '../../constants';
import fetchQuery from "../../utils/fetch";
import FormStructure from './FormStructure';
import {bindActionCreators} from "redux";
import * as popupActions from "../../actions/popup";
import {connect} from "react-redux";

class FormDefault extends Component {
    collectInitialValues = fields => {
        let obj = {};

        for (let i = 0; i < fields.length; i++) {
            const { name, value = '' } = fields[i];

            obj[name] = value;
        }

        return obj;
    };
    
    getYup = (fields) => {
        let schema = {};
        return Yup.object(collect_schema(schema, fields));
    };
    
    onSuccess = (actions) => {
        const
            { popupActions, ty_popup_content } = this.props,
            { togglePopUp } = popupActions;
        
        togglePopUp({
            showPopUp: true,
            content: {
                ty: ty_popup_content
            }
        });

        setTimeout(() => {
            actions.resetForm()
        }, 1000)
    };
    
    render() {
        const { fields, schema, form_id, form_handler } = this.props;
        
        return (
            <Formik
                enableReinitialize={false}
                onSubmit={(values, actions) => {
                    const body = {...values, form_id: form_id};
                    
                    fetchQuery({
                        url: form_handler || urls.form_handler,
                        method: 'POST',
                        body: JSON.stringify(body),
                        customSuccess: this.onSuccess.bind(this, actions)
                    });
                }}
                validate={schema ? validate(schema) : validate(this.getYup.bind(this, fields))}
                initialValues={this.collectInitialValues(fields)}
            >
                {props => (
                    <Form>
                        <FormStructure {...this.props} />
                    </Form>
                )}
            </Formik>
        );
    }
}

FormDefault.propTypes = {
    fields: PropTypes.array.isRequired,
    ty_popup_content: PropTypes.string,
    form_id: PropTypes.string.isRequired,
    form_handler: PropTypes.string,
    title: PropTypes.string,
    btn: PropTypes.object.isRequired,
    vertical: PropTypes.bool,
    theme: PropTypes.string
};

function mapDispatchToProps(dispatch) {
    return {
        popupActions: bindActionCreators(popupActions, dispatch)
    };
}

export default connect(
    null,
    mapDispatchToProps
)(FormDefault);
