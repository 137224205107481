import { css } from "emotion";
import { styles, fonts, grid, common } from "../../constants";

const { mq, colors, fontFamily } = styles;

export default css(
    mq({
        position: "relative",
        marginBottom: grid.default[9],

        "&__title": {
            ...fonts.p2,
            marginBottom: grid.default[3],
            "&_btn": {
                ":after": {
                    content: [null, null, null, null, "'\\e90e'"],
                    transition: ".4s ease",
                    display: "inline-block",
                    fontFamily: fontFamily.icon,
                    fontSize: 9,
                    marginLeft: 10
                }
            },
            "&._active": {
                ":after": {
                    transform: "scaleY(-1)"
                }
            }
        },
        "&__subtitle": {
            ...fonts.p6
        },

        "&__container": {
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            flexDirection: [null, "column"],
            width: [null, "calc(100% / 9 * 8)", "100%"],
            paddingLeft: [null, 33, 0],
            marginLeft: [null, "auto"]
        },

        "&__params": {
            width: [
                "calc(100% / 9 * 2 - 10px)",
                "100%",
                "calc(100% / 9 * 8)",
                "100%"
            ],
            paddingBottom: grid.default[7],
            paddingTop: [grid.default[7][0], 0],
            marginLeft: "auto",
            paddingLeft: [null, null, 10, 0]
        },

        "&__options": {
            marginTop: grid.default[5],

            li: {
                width: [null, "calc(50% - 10px)", null, null, "100%"]
            }
        },

        "&__additional-price": {
            display: "block",
            marginTop: grid.default[5],
            ...fonts.p1,
            color: colors.lilaMiddle
        },

        "&__table": {
            position: "relative",
            width: ["calc(100% / 9 * 7 - 10px)", "100%"],
            paddingBottom: [
                grid.default[7][0],
                grid.default[7][1],
                grid.default[7][2],
                0
            ],
            paddingTop: [
                grid.default[7][0],
                grid.default[7][1],
                grid.default[7][2],
                0
            ],
            paddingLeft: [80, 60, null, 18, 0],
            paddingRight: [80, 60, null, 18, 0]
        },

        "&__row": {
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: grid.default[6],
            flexWrap: "wrap",

            "&:last-of-type": {
                marginBottom: 0
            },
            "&_tablet-column": {
                flexDirection: [null, null, "column"]
            }
        },
        ul: {
            ...common.markerList,
            display: [null, "flex"],
            justifyContent: "space-between",
            flexWrap: "wrap"
        },
        "&__col": {
            width: [
                "calc(50% - 40px)",
                "calc(50% - 17px)",
                "calc(50% - 13px)",
                "calc(50% - 32px)",
                "100%"
            ],

            "&_right": {
                marginTop: [null, null, null, null, 50]
            }
        },
        "&__row_tablet-column &": {
            "&__col": {
                width: "100%",
                "&_right": {
                    marginTop: 25
                }
            }
        },
        "&__slide-down": {
            transition: ".7s ease",
            overflow: "hidden"
        }
    })
);
