import { css } from 'emotion';
import { styles, fonts, grid, grid_negative } from '../../constants';

const { mq, colors } = styles;

export default css(
    mq({
        '&__wrapper-anim': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexDirection: [null, null, null, null, 'column'],
            transition:
                'transform 1.5s cubic-bezier(0.18, 1, 0.21, 1), opacity 1.5s cubic-bezier(0.18, 1, 0.21, 1)',
            transform: 'translateY(50px)',
            opacity: 0,
            width: '100%',
            paddingTop: [
                grid.default[6][0],
                grid.default[6][1],
                grid.default[6][2],
                0
            ],
            '&_active': {
                transform: 'translateY(0)',
                opacity: 1
            }
        },
        '&__col': {
            position: 'relative',
            width: 'calc(50% - 10px)',
            '&_image': {
                zIndex: 1,
                top: [
                    grid_negative.default[6][0],
                    grid_negative.default[6][1],
                    grid_negative.default[6][2],
                    50,
                    0
                ],
                borderRight: [
                    `40px solid ${colors.white}`,
                    `30px solid ${colors.white}`,
                    `10px solid ${colors.white}`
                ],
                borderBottom: [
                    `40px solid ${colors.white}`,
                    `30px solid ${colors.white}`,
                    `10px solid ${colors.white}`
                ],
                borderTop: [null, null, `10px solid ${colors.white}`],
                border: [null, null, null, null, 'none'],
                alignSelf: 'flex-start',
                width: [null, null, 'calc(35.3% - 10px)', 164, '100%']
            },
            '&_content': {
                width: [
                    null,
                    null,
                    'calc(64.7% - 10px)',
                    'calc(100% - 198px)',
                    '100%'
                ],
                ':before': {
                    content: "''",
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    backgroundColor: colors.lilaLight,
                    display: 'block',
                    height: '100%',
                    width: ['124%', null, '138%', '133%', '100%']
                }
            }
        },
        '&__wrapper': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: '100%',
            padding: [
                '40px 80px',
                '40px 58px',
                '49px 80px 49px 12px',
                '53px 40px 49px 0',
                '35px 20px 45px 20px'
            ]
        },
        '&__title': {
            ...fonts.h3
        },
        '&__text': {
            marginTop: grid.default[4],
    
            ':first-child': {
                marginTop: 0
            },
            
            p: {
                ...fonts.p4,
                marginTop: grid.default[3],
                ':first-child': {
                    marginTop: 0
                }
            }
        },
        '&__img-block': {
            position: 'relative',
            ':before': {
                content: ['none', null, "''"],
                display: 'block',
                paddingTop: ['160%', null, null, null, '117%']
            }
        },
        '&__img-wrapper': {
            position: ['static', null, 'absolute'],
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            img: {
                objectFit: 'cover',
                width: '100%',
                height: [null, null, '100%']
            }
        },

        '&_reverse &': {
            '&__wrapper-anim': {
                flexDirection: ['row-reverse', null, null, null, 'column'],
                paddingTop: 0,
                paddingBottom: [
                    grid.default[6][0],
                    grid.default[6][1],
                    grid.default[6][2],
                    0
                ]
            },
            '&__wrapper': {
                padding: [
                    null,
                    null,
                    '49px 50px 49px 31px',
                    '49px 5px 49px 34px',
                    '35px 20px 45px 20px'
                ]
            },
            '&__col': {
                '&_image': {
                    top: [
                        grid.default[6][0],
                        grid.default[6][1],
                        grid.default[6][2],
                        grid.default[6][3],
                        0
                    ],
                    alignSelf: ['flex-end', null, 'flex-start'],
                    borderRight: 'none',
                    borderBottom: [
                        'none',
                        null,
                        `10px solid ${colors.white}`,
                        null,
                        'none'
                    ],
                    borderLeft: [
                        `40px solid ${colors.white}`,
                        `30px solid ${colors.white}`,
                        `10px solid ${colors.white}`,
                        null,
                        'none'
                    ],
                    borderTop: [
                        `40px solid ${colors.white}`,
                        `30px solid ${colors.white}`,
                        `10px solid ${colors.white}`,
                        null,
                        'none'
                    ]
                },
                '&_content': {
                    ':before': {
                        right: 'auto',
                        left: 0
                    }
                }
            }
        }
    })
);
