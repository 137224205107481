import React from 'react';
import { default as st } from './style';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkImageBorder = ({ title, img, to }) => {
    const [ref, inView] = useInView({
        threshold: 0.5
    });
    return (
        <div className={`${st}${inView ? ` ${st}_active` : ''}`} ref={ref}>
            <Link className={`${st}__link col`} to={to} title={title}>
                {img && (
                    <div className={`${st}__header-img-block`}>
                        <img
                            className={`${st}__header-img`}
                            src={require(`../../${img}`)}
                            alt=""
                        />
                    </div>
                )}

                <div className={`${st}__wrapper`}>
                    <div className={`${st}__border`}>
                        <div className={`${st}__border-line`} />
                        <div className={`${st}__border-line`} />
                        <div className={`${st}__border-line`} />
                        <div className={`${st}__border-line`} />
                    </div>
                    <span className={`${st}__title`}>{title}</span>
                </div>
            </Link>
        </div>
    );
};

LinkImageBorder.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    img: PropTypes.string
};

export default LinkImageBorder;
