import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { Field } from 'formik';
import { st, sst } from './style';

const ValueContainer = ({ children, ...props }) => {
        const { selectProps } = props,
            { className, value } = selectProps;

        let text;

        if (value instanceof Array) {
            if (value[0].single) {
                text = value[0].label;
            } else {
                text = `Выбрано: ${value.length}`;
            }
        } else {
            text = value.label;
        }
        return (
            <components.ValueContainer {...props}>
                <p className={`${className}__label`}>{text}</p>
                <div className={`${className}__child-wrapper`}>{children}</div>
            </components.ValueContainer>
        );
    },
    DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <i
                        className={`${st}__icon icon-angle-down ${
                            props.selectProps.menuIsOpen ? ` _active` : ''
                        }`}
                    />
                </components.DropdownIndicator>
            )
        );
    };

const single_check = (options, option) => {
    let fields = option && option.length ? option : options,
        new_options = [];

    if (fields[fields.length - 1].single) {
        return fields[fields.length - 1];
    } else {
        if (!option || (option && !option.length)) {
            for (let i = 0; i < fields.length; i++) {
                if (fields[i].single) {
                    return fields[i];
                }
            }
        } else {
            for (let i = 0; i < fields.length; i++) {
                if (!fields[i].single) {
                    new_options.push(fields[i]);
                }
            }
        }
    }

    return new_options;
};

export default class SelectStyle extends Component {
    render() {
        const { options, placeholder, name, label, isMulti } = this.props;
        return (
            <div className={st}>
                <Field name={name}>
                    {({ field, form: { setFieldValue, submitForm }, form }) => (
                        <Fragment>
                            {label && (
                                <span className={`${st}__label`}>
                                    {label.text}
                                </span>
                            )}
                            <Select
                                {...field}
                                components={{
                                    DropdownIndicator,
                                    ValueContainer
                                }}
                                placeholder={placeholder}
                                options={options}
                                className={st}
                                styles={sst}
                                value={field.value}
                                onChange={(option: Option) => {
                                    setFieldValue(
                                        field.name,
                                        single_check(options, option)
                                    );
                                    submitForm();
                                }}
                                hideSelectedOptions={false}
                                isMulti={isMulti}
                                isSearchable={false}
                            />
                        </Fragment>
                    )}
                </Field>
            </div>
        );
    }
}

SelectStyle.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    isMulti: PropTypes.bool
};
