import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";
const { mq, colors } = styles;

export default css(
    mq({
        padding: [null, null, null, 0],
        h1: {
            ...fonts.h1
        },
        h2: {
            ...fonts.h2
        },
        h3: {
            ...fonts.h3
        },
        "&__title": {
            position: "relative",
            zIndex: 3,

            span: {
                color: colors.lilaMiddle
            }
        },
        "&__subtitle": {
            ...fonts.p2,
            marginTop: grid.default[3]
        }
    })
);
