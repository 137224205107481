import React, { Component } from 'react';
import { default as st } from './style';

export default class CatalogItem extends Component {
    render() {
        const { active } = this.props;

        return (
            <div className={`${st} ${active ? '_active' : ''}`}>
                <div className={`${st}__line`} />
                <div className={`${st}__line`} />
                <div className={`${st}__line`} />
                <div className={`${st}__line`} />
            </div>
        );
    }
}
