import { css, keyframes } from "emotion";
import { styles, fonts, grid } from "../../constants";
const { mq } = styles;

const bg_anim = keyframes({
    "0%": {
        transform: "translateX(0)"
    },
    "100%": {
        transform: "translateX(-20%)"
    }
});

export default css(
    mq({
        paddingTop: grid.default[5],
        textAlign: "center",
        "&__code": {
            position: "relative",
            overflow: "hidden",
            width: "100%",
            maxWidth: [834, 662, 570, "none"],
            margin: "0 auto"
        },
        "&__code-bg": {
            position: "absolute",
            left: 0,
            top: 0,
            animation: `${bg_anim} 15s linear infinite alternate`,
            width: "125%"
        },
        "&__code-number": {
            position: "relative",
            display: "block",
            width: "100%"
        },
        "&__title": {
            ...fonts.p2,
            marginTop: grid.default[4]
        },
        "&__desc": {
            ...fonts.p4,
            marginTop: grid.default[3]
        },
        "&__content": {
            width: "100%",
            maxWidth: 500,
            margin: "0 auto"
        },
        "&__link": {
            marginTop: grid.default[4]
        }
    })
);
