import React, { Component } from "react";
import { default as st } from "./style";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import TwinImages from "../../components/TwinImages";
import ContactsInfo from "../../containers/ContactsInfo";

export default class ContactsAbout extends Component {
    render() {
        const {
            title,
            twin_images,
            additional,
            contacts
        } = this.props;

        return (
            <div className={st} data-additional={additional}>
                <div className={`container container_narrow`}>
                    <h2 className={`${st}__title col`}>{parse(title)}</h2>
                </div>
                <div className={`${st}__twin col`}>
                    <TwinImages
                        {...twin_images}
                        mod="reset-margin"
                    />
                </div>
                <div className={`${st}__contacts-info`}>
                    <div className={`container container_narrow`}>
                        <ContactsInfo {...contacts} />
                    </div>
                </div>
            </div>
        );
    }
}

ContactsAbout.propsTypes = {
    title: PropTypes.string.isRequired,
    image_left: PropTypes.string.isRequired,
    image_right: PropTypes.string.isRequired,
    additional: PropTypes.string,
    contacts: PropTypes.object
};
