import styles from "./styles";
import fonts from "./fonts";
import grid from "./grid";

const common = {
    slickArrow: (obj = {}) => {
        const pos = {
                top: "auto",
                right: 0,
                bottom: [0],
                left: "auto",
                ...obj
            },
            width = [150, 104, 84],
            height = [75, 52, 42];

        return {
            width: width,
            height: height,
            display: "flex !important",
            transform: "none",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: styles.colors.lilaLight,
            position: "absolute",
            margin: "auto",
            zIndex: 5,
            ...pos,
            fontSize: "0",
            lineHeight: "0",
            
            '&.slick-disabled': {
                cursor: 'auto',
                
                "&::before": {
                    color: styles.colors.greyDark,
                    opacity: .5
                },
            },

            "&::before": {
                content: '"\\e90b"',
                fontFamily: "icomoon",
                color: styles.colors.lila,
                display: "block",
                position: "relative",
                transition: ".3s ease",
                fontSize: 10,
                lineHeight: 1,
                opacity: 1
            },

            "&:not(.slick-disabled):hover": {
                "&::before": {
                    transform: "translateX(3px)"
                }
            },

            "&.slick-prev": {
                paddingBottom: [18, null, 11],
                justifyContent: "flex-end",

                "&::before": {
                    transform: "scaleX(-1)"
                },

                "&:not(.slick-disabled):hover": {
                    "&::before": {
                        transform: "scaleX(-1) translateX(3px)"
                    }
                }
            },

            "&.slick-next": {
                paddingTop: [18, null, 11],
                justifyContent: "flex-start"
            }
        };
    },
    
    slickDots: {
        display: "flex !important",
        justifyContent: "center",
        position: "relative",
        bottom: "auto",
        marginTop: 20,
        li: {
            padding: 5
        },
        button: {
            transform: "rotate(45deg)",
            width: "9px",
            height: "9px",
            border: `1px solid ${styles.colors.lila}`,
            padding: 0,
            margin: "0 auto",
            ":before": {
                content: "none"
            }
        },
        ".slick-active": {
            button: {
                backgroundColor: styles.colors.lila
            }
        }
    },

    markerList: {
        display: "block",
        position: "relative",
        marginTop: grid.default[3],

        li: {
            display: "block",
            position: "relative",
            ...fonts.p3,
            marginBottom: grid.default[1],
            paddingLeft: 20,

            "&::before": {
                content: '""',
                display: "block",
                ...styles.absolute(".8em", "auto", "auto", "0"),
                width: 12,
                height: 1,
                background: styles.colors.greyDark
            },

            "&::last-of-type": {
                marginBottom: "0"
            }
        }
    }
};

export default common;
