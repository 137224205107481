import React, { useEffect, Fragment } from "react";
import { SectionGrid } from "../../components";
import { fetchQuery } from "../../actions/main";
import { styles, urls } from "../../constants";
import BannerSlider from "../../containers/BannerSlider";
import { default as Why } from "../../components/Why";
import { default as Effects } from "../../components/Effects";
import { default as Trends } from "../../components/Trends";
import { default as MapSection } from "../../components/MapSection";
import { connect } from "react-redux";
import isNotEmpty from "../../utils/is-not-empty";

export default connect(
    state => ({
        ...state.mainStore
    }),
    {
        fetchQuery: fetchQuery
    }
)(({ isFetching, fetchResult, routePath, ...rest_props }) => {
    useEffect(() => {
        if (
            !isFetching &&
            (!fetchResult || !fetchResult.hasOwnProperty(routePath))
        )
            rest_props.fetchQuery({
                type: "GET",
                url: urls.homepage,
                alias: routePath
            });
    }, [rest_props, isFetching, routePath, fetchResult]);

    return (
        <Fragment>
            {fetchResult[routePath] && (
                <Fragment>
                    <SectionGrid
                        left_column={{
                            stretch: true,
                            gradient_block: true
                        }}
                        first={true}
                        mod="main"
                    >
                        {isNotEmpty(fetchResult[routePath].slides) &&
                            <BannerSlider slides={fetchResult[routePath].slides} />
                        }
    
                        {isNotEmpty(fetchResult[routePath].icons) &&
                            <Why items={fetchResult[routePath].icons} />
                        }
                    </SectionGrid>
                    
                    {isNotEmpty(fetchResult[routePath].effects) &&
                        <SectionGrid
                            left_column={{
                                fill: styles.colors.lilaLight,
                                stretch: true,
                                logo: true
                            }}
                        >
                            <Effects effects={fetchResult[routePath].effects} />
                        </SectionGrid>
                    }
                    
                    {isNotEmpty(fetchResult[routePath].trends) &&
                        <SectionGrid
                            left_column={{ text: "вдохновение" }}
                            right_column={{
                                fill: styles.colors.lilaLight,
                                logo: true
                            }}
                        >
                            <Trends trends={fetchResult[routePath].trends} />
                        </SectionGrid>
                    }
                    
                    {isNotEmpty(fetchResult[routePath].map) &&
                        <SectionGrid last={true}>
                            <MapSection {...fetchResult[routePath].map} />
                        </SectionGrid>
                    }
                </Fragment>
            )}
        </Fragment>
    );
});
