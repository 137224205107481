import { css } from 'emotion';
import { styles } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        ...styles.absolute(),
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        
        '&__line': {
            position: 'absolute',
            overflow: 'hidden',
            
            ':after,:before': {
                content: "''",
                position: 'absolute',
                display: 'block',
                transition: '.4s ease',
                backgroundColor: colors.lilaLight
            },
            ':nth-of-type(odd)': {
                width: '100%',
                height: [15, null, 10],
                ':after,:before': {
                    top: 0,
                    width: '50%',
                    height: '100%'
                },
                ':before': {
                    left: 0,
                    transform: 'translateX(-105%)'
                },
                ':after': {
                    right: 0,
                    transform: 'translateX(105%)'
                }
            },
            ':nth-of-type(even)': {
                width: [15, null, 10],
                height: '100%',
                ':after,:before': {
                    left: 0,
                    width: '100%',
                    height: '50%'
                },
                ':before': {
                    top: 0,
                    transform: 'translateY(-105%)'
                },
                ':after': {
                    bottom: 0,
                    transform: 'translateY(105%)'
                }
            },
            ':nth-of-type(1)': {
                left: 0,
                top: 0
            },
            ':nth-of-type(2)': {
                right: 0,
                top: 0
            },
            ':nth-of-type(3)': {
                right: 0,
                bottom: 0
            },
            ':nth-of-type(4)': {
                left: 0,
                bottom: 0
            }
        },
 
        '&._active &': {
            '&__line': {
                ':after,:before': {
                    transform: 'translate(0,0)'
                }
            }
        }
    })
);
