import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";

const { mq, colors } = styles;

export default css(
    mq({
        display: "flex",
        alignItems: "flex-start",
        alignContent: "center",
        justifyContent: "space-between",
        flexDirection: [null, null, "column"],
        width: "100%",
        border: [
            `15px solid ${colors.lilaLight}`,
            null,
            `10px solid ${colors.lilaLight}`,
            null,
            "none"
        ],
        paddingTop: grid.default[6],
        paddingBottom: grid.default[6],
        overflow: 'hidden',

        "&_inflow": {
            marginTop: grid.default[8]
        },
        "&__side": {
            marginRight: "auto",
            marginLeft: "auto",
            padding: [null, null, "0 10px", null, 0],

            "&_left": {
                display: [null, null, "flex"],
                flexDirection: ["row-reverse", null, null, null, "column"],
                justifyContent: "space-between",
                width: ["calc(100% / 9 * 3)", null, "80.7%", "90%", "100%"],
                marginLeft: ["calc(100% / 9)", null, "auto"]
            },
            "&_right": {
                width: ["calc(100% / 9 * 5)", null, "80.7%", "90%", "100%"],
                paddingLeft: [10, null, null, null, 0],
                display: "flex",
                alignItems: "flex-start",
                alignContent: "center",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                marginTop: [null, null, 38, null, 26]
            }
        },
        h3: {
            ...fonts.h3,
            marginBottom: grid.default[3],
            opacity: 0,
            transform: "translate(0,20%)",
            transition: "1s ease-out",
            width: [null, null, "calc(100% - 172px)", null, "100%"]
        },
        "&__icon": {
            width: [160, 120, null, null, 90],
            height: [160, 120, null, null, 90],
            opacity: 0,
            transform: "translate(0,20%)",
            transition: "1s ease-out 0.5s",
            img: {
                maxWidth: "100%",
                maxHeight: "100%"
            }
        },
        "&__item": {
            position: "relative",
            width: ["50%", null, null, null, "100%"],
            paddingRight: [10, null, null, null, 0],
            marginBottom: grid.default[2],
            paddingLeft: 27,
            opacity: 0,
            transform: "translate(20%,0%)",
            transition: "1s ease-out",
            "&:before": {
                display: "block",
                position: "absolute",
                top: [10, null, null, null, null],
                left: 0,
                width: 14,
                height: 1,
                background: colors.greyDark,
                content: '""'
            }
        },
        "&__item-title": {
            ...fonts.p6,
            marginBottom: grid.default[1]
        },
        "&__item-text": {
            ...fonts.p3
        },
        "&_active &": {
            "&__icon": {
                opacity: 1,
                transform: "translate(0,0)",
                transition: "1s ease-out"
            },
            "&__item": {
                opacity: 1,
                transform: "translate(0,0)",
                transition: "1s ease-out"
            }
        },
        "&_active": {
            h3: {
                opacity: 1,
                transform: "translate(0,0)",
                transition: "1s ease-out"
            }
        }
    })
);
