import React, { Fragment } from "react";
import { default as st } from "./style";
import parse from "html-react-parser";
import MediaQuery from "react-responsive";

import TextCollapse from "../../components/TextCollapse";

import { styles } from "../../constants";

const { breakpoints } = styles;

export default ({ content, mod = "" }) => {
	return (
		<div className={`${st} ${mod}`}>
			<MediaQuery maxWidth={breakpoints["550"]}>
				{matches =>
					matches ? (
						<TextCollapse maxLine={8} text={content} />
					) : (
						<Fragment>{parse(content)}</Fragment>
					)
				}
			</MediaQuery>
		</div>
	);
};
