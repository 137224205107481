import React, { Component, Fragment } from "react";
import { default as st } from "./style";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as mainActions from "../../actions/base.js";
import * as popupActions from "../../actions/popup.js";
import { urls } from "../../constants";

import Button from "../../components/Button";
import Dev from "../../components/Dev";

export class Footer extends Component {
    componentDidMount() {
        if (
            !this.props.baseStore.isFetching &&
            (!this.props.baseStore.fetchResult ||
                !this.props.baseStore.fetchResult.hasOwnProperty("base"))
        ) {
            this.props.mainActions.fetchQuery({
                type: "GET",
                url: urls.base,
                alias: "base"
            });
        }
    }

    render() {
        const { baseStore, popupActions } = this.props,
            { fetchResult, isFetching } = baseStore,
            { togglePopUp } = popupActions;

        return (
            <Fragment>
                {!isFetching && fetchResult.base && (
                    <footer className={st}>
                        <div className={`${st}__block ${st}__block_top`}>
                            <div className={`${st}__content`}>
                                <div
                                    className={`${st}__item ${st}__item_4of10`}
                                >
                                    <div className={`${st}__row`}>
                                        <div
                                            className={`${st}__row-item ${st}__row-item_left col`}
                                        >
                                            <Link
                                                className={`${st}__logo`}
                                                to={"/"}
                                                title="Главная"
                                            >
                                                <img
                                                    src={require("../../images/svg/logo.svg")}
                                                    alt={""}
                                                />
                                            </Link>
                                        </div>
                                        <div
                                            className={`${st}__row-item ${st}__row-item_right`}
                                        >
                                            <div
                                                className={`${st}__contacts col`}
                                            >
                                                <p className={`${st}__address`}>
                                                    {
                                                        fetchResult.base
                                                            .contacts.address
                                                    }
                                                </p>
                                                <a
                                                    className={`${st}__contact-link`}
                                                    href={
                                                        fetchResult.base
                                                            .contacts.phone.href
                                                    }
                                                    title="Позвонить"
                                                >
                                                    {
                                                        fetchResult.base
                                                            .contacts.phone
                                                            .title
                                                    }
                                                </a>
                                                <a
                                                    className={`${st}__contact-link`}
                                                    href={
                                                        fetchResult.base
                                                            .contacts.email.href
                                                    }
                                                    title="Написать"
                                                >
                                                    {
                                                        fetchResult.base
                                                            .contacts.email
                                                            .title
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${st}__item ${st}__item_3of10`}
                                >
                                    <div className={`${st}__navigation`}>
                                        {fetchResult.base.nav.map(
                                            (item, index) => {
                                                return (
                                                    <Link
                                                        className={`${st}__nav-item col`}
                                                        {...item}
                                                        key={index}
                                                    >
                                                        {item.title}
                                                    </Link>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`${st}__item ${st}__item_3of10 col`}
                                >
                                    <div className={`${st}__btn-wrapper`}>
                                        <Button
                                            text="Я архитектор / дизайнер"
                                            stretch={true}
                                            bordered={true}
                                            mod="_lila"
                                            onClick={() => {
                                                togglePopUp({
                                                    showPopUp: true,
                                                    content:
                                                        fetchResult.base
                                                            .footer_popup
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`${st}__block ${st}__block_bottom`}>
                            <div
                                className={`${st}__content ${st}__content_vertical-middle`}
                            >
                                <p className={`${st}__copyright col`}>
                                    {fetchResult.base.copyright}
                                </p>
                                <div className={`${st}__dev`}>
                                    <Dev />
                                </div>
                            </div>
                        </div>
                    </footer>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ baseStore }) {
    return { baseStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch),
        popupActions: bindActionCreators(popupActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
