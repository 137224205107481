export const innerHTML = html => {
    return { __html: html };
};

export const addZeroToNumber = (number, countOfNumber) => {
    const numberArr = number
        .toString(10)
        .replace(/\D/g, '0')
        .split('')
        .map(Number);
    while (numberArr.length !== countOfNumber) {
        numberArr.unshift(0);
    }
    return numberArr.join('');
};
