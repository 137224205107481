import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "../../actions/main.js";

import SectionGrid from "../../components/SectionGrid";
import { urls } from "../../constants";

import Description from "../../components/Description";
import IllustratedDescription from "../../components/IllustratedDescription";
import SkinGraphic from "../../components/SkinGraphic";
import PersonQuote from "../../components/PersonQuote";
import ColsIconsList from "../../components/ColsIconsList";
import Compare from "../../components/Compare";
import Breadcrumbs from "../../components/Breadcrumbs";
import FormDefault from "../../containers/FormDefault";
import isNotEmpty from "../../utils/is-not-empty";

class Technologies extends Component {
    componentDidMount() {
        const { routePath } = this.props;

        if (
            !this.props.mainStore.fetchResult ||
            !this.props.mainStore.fetchResult.hasOwnProperty(routePath)
        ) {
            this.props.mainActions.fetchQuery({
                type: "GET",
                url: urls.technologies,
                alias: routePath
            });
        }
    }

    render() {
        const { mainStore, routePath } = this.props,
            { fetchResult } = mainStore;

        return (
            <Fragment>
                {fetchResult[routePath] && (
                    <Fragment>
    
                        {isNotEmpty(fetchResult[routePath].breadcrumbs) &&
                            <Breadcrumbs
                                links={fetchResult[routePath].breadcrumbs}
                            />
                        }
                        
                        {isNotEmpty(fetchResult[routePath].description) &&
                            <SectionGrid>
                                <Description
                                    {...fetchResult[routePath].description}
                                    contentMod="_2-col-content"
                                    styleMod="_big-margins _clear-top"
                                />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].pic_description) &&
                            <SectionGrid>
                                <IllustratedDescription
                                    {...fetchResult[routePath].pic_description}
                                />
                            </SectionGrid>
                        }
    
                        <SectionGrid>
                            <SkinGraphic />
                        </SectionGrid>
                        
                        {isNotEmpty(fetchResult[routePath].person_quote) &&
                            <SectionGrid>
                                <PersonQuote
                                    {...fetchResult[routePath].person_quote}
                                />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].advantages) &&
                            <SectionGrid>
                                <ColsIconsList
                                    {...fetchResult[routePath].advantages}
                                />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[routePath].compare) &&
                            <SectionGrid>
                                <Compare {...fetchResult[routePath].compare} />
                            </SectionGrid>
                        }
    
                        {isNotEmpty(fetchResult[routePath].form) &&
                            <SectionGrid last={true}>
                                <FormDefault
                                    theme="white"
                                    {...fetchResult[routePath].form}
                                />
                            </SectionGrid>
                        }
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ mainStore }) {
    return { mainStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Technologies);
