import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SectionGrid from '../../components/SectionGrid';
import ProductHeader from '../../components/ProductHeader';

import * as mainActions from '../../actions/main.js';
import {styles, urls} from '../../constants';
import Banner from "../../components/Banner";
import IntroText from "../../components/Introtext";
import ModelAdvantages from "../../components/ModelAdvantages";
import TwinImages from "../../components/TwinImages";
import GalleryLinksSlider from "../../components/GalleryLinksSlider";
import ModelParameters from "../../components/ModelParameters";
import Breadcrumbs from "../../components/Breadcrumbs";
import isNotEmpty from "../../utils/is-not-empty";

class Product extends Component {
    componentDidMount() {
        const { url, alias } = this._getUrlsObj();
        
        if (!this.props.mainStore.fetchResult || !this.props.mainStore.fetchResult.hasOwnProperty(alias)) {
            this.props.mainActions.fetchQuery({
                type: 'GET',
                url: url,
                alias: alias
            });
        }
    }
    
    _getUrlsObj = () => {
        const
            { routePath, match } = this.props,
            matchIsset = match && match.params && (typeof match.params.id !== 'undefined');
        
        return {
            url: (matchIsset ? `${urls.product_page}?id=${match.params.id}` : urls.product_page),
            alias: (matchIsset ? (routePath.replace(':id', '') + match.params.id) : routePath)
        }
    };

    render() {
        const { mainStore } = this.props,
            { alias } = this._getUrlsObj(),
            { fetchResult } = mainStore;
        
        return (
            <Fragment>
                {fetchResult[alias] && (
                    <Fragment>
                        {isNotEmpty(fetchResult[alias].breadcrumbs) &&
                            <Breadcrumbs links={fetchResult[alias].breadcrumbs} />
                        }
                        
                        {isNotEmpty(fetchResult[alias].header) &&
                            <SectionGrid>
                                <ProductHeader {...fetchResult[alias].header} />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[alias].banner) &&
                            <SectionGrid
                                right_column_hide={true}
                            >
                                <Banner
                                    images={fetchResult[alias].banner}
                                />
                            </SectionGrid>
                        }
    
                        {isNotEmpty(fetchResult[alias].intro) &&
                            <SectionGrid
                                right_column={{
                                    fill: styles.colors.lilaLight,
                                    logo: true
                                }}
                            >
                                <IntroText mod={'_big-margins'} {...fetchResult[alias].intro} />
                            </SectionGrid>
                        }
    
                        {isNotEmpty(fetchResult[alias].model_advantages) &&
                            <SectionGrid>
                                <ModelAdvantages {...fetchResult[alias].model_advantages} />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[alias].twin_images) &&
                            <SectionGrid>
                                <TwinImages {...fetchResult[alias].twin_images} />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[alias].model_parameters) &&
                            <SectionGrid>
                                <ModelParameters {...fetchResult[alias].model_parameters} />
                            </SectionGrid>
                        }
                        
                        {isNotEmpty(fetchResult[alias].gallery_links_slider) &&
                            <SectionGrid
                                right_column_hide={true}
                                last={true}
                                overflow={true}
                            >
                                <GalleryLinksSlider {...fetchResult[alias].gallery_links_slider} />
                            </SectionGrid>
                        }
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps({ mainStore }) {
    return { mainStore };
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Product);
