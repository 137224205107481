import React, { Component, Fragment } from "react";
import { default as st } from "./style";
import { InView } from "react-intersection-observer";
import AnimatedFrame from "../AnimatedFrame";
import ColorsList from "../ColorsList";
import IconsList from "../IconsList";
import Button from "../Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as popupActions from "../../actions/popup.js";
import MediaQuery from "react-responsive";
import { SlideDown } from "react-slidedown";

import { styles } from "../../constants";
import TextBlock from "../TextBlock";
import Finishes from '../Finishes'

import Lightbox from 'react-image-lightbox';

const { breakpoints } = styles;

class ModelParameters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showParameters: false,
            showPremiumColors: false
        };
    }
    renderMarkerList = array => {
        return (
            <ul>
                {array.map((item, key) => (
                    <li key={key}>{item}</li>
                ))}
            </ul>
        );
    };

    toggleParameters = state => {
        this.setState({
            showParameters: !state
        });
    };

    togglePremiumColors = state => {
        this.setState({
            showPremiumColors: !state
        });
    };

    renderParams = () => {
        const { params, add_options } = this.props;

        return (
            <Fragment>
                {params && (
                    <div
                        className={`${st}__parameters`}
                    >
                        <IconsList
                            list={
                                params
                            }
                        />
                    </div>
                )}

                {add_options && (
                    <div
                        className={`${st}__options`}
                    >
                        <h4
                            className={`${st}__subtitle`}
                        >
                            Дополнительные
                            опции:
                        </h4>
                        {this.renderMarkerList(
                            add_options
                        )}
                    </div>
                )}
            </Fragment>
        )
    };

    renderPremiumColors = (inView) => {
        const { prem_colors, prem_add_price } = this.props;

        return (
            <React.Fragment>
                {prem_colors && (
                    <ColorsList
                        list={prem_colors}
                        active={inView}
                    />
                )}

                {prem_add_price && (
                    <span
                        className={`${st}__additional-price`}
                    >
                        {prem_add_price}
                    </span>
                )}
            </React.Fragment>
        )
    };
    
    render() {
        const {
                std_colors,
                another_conf,
                baseStore,
                popupActions,
                finishes
            } = this.props,
            { fetchResult } = baseStore,
            { togglePopUp } = popupActions,
            { showParameters, showPremiumColors } = this.state;
        
        
        console.log(this.props);

        return (
            <InView threshold={0.2}>
                {({ inView, ref }) => {
                    const activeMod = inView ? ` ${st}_active` : "";

                    return (
                        <div className={`${st} ${activeMod}`} ref={ref}>
                            <div className={`${st}__container`}>
                                <div className={`${st}__params`}>
                                    <h3
                                        className={`${st}__title ${st}__title_btn${
                                            showParameters ? " _active" : ""
                                        }`}
                                        onClick={() => this.toggleParameters(showParameters)}
                                    >
                                        Характеристики:
                                    </h3>
                                    <MediaQuery maxWidth={breakpoints["550"]}>
                                        {matches =>
                                            matches ? (
                                                <SlideDown
                                                    className={`${st}__slide-down`}
                                                >
                                                    {showParameters && (
                                                        this.renderParams()
                                                    )}
                                                </SlideDown>
                                            ) : (
                                                this.renderParams()
                                            )
                                        }
                                    </MediaQuery>
                                </div>

                                <div className={`${st}__table`}>
                                    <MediaQuery maxWidth={breakpoints["900"]}>
                                        {matches =>
                                            !matches && (
                                                <AnimatedFrame
                                                    active={inView}
                                                />
                                            )
                                        }
                                    </MediaQuery>

                                    <div className={`${st}__row`}>
                                        <div
                                            className={`${st}__col ${st}__col_left`}
                                        >
                                            <h3 className={`${st}__title`}>
                                                Стандартные цвета:
                                            </h3>

                                            {std_colors && (
                                                <ColorsList
                                                    list={std_colors}
                                                    active={inView}
                                                />
                                            )}
                                        </div>
                                        <div
                                            className={`${st}__col ${st}__col_right`}
                                        >
                                            <h3 className={`${st}__title ${st}__title_btn${
                                                showPremiumColors ? " _active" : ""
                                            }`}
                                                onClick={() => this.togglePremiumColors(showPremiumColors)}
                                            >
                                                Premium-цвета:
                                            </h3>

                                            <MediaQuery maxWidth={breakpoints["550"]}>
                                                {matches =>
                                                    matches ? (
                                                        <SlideDown
                                                            className={`${st}__slide-down`}
                                                        >
                                                            {showPremiumColors && (
                                                                this.renderPremiumColors(inView)
                                                            )}
                                                        </SlideDown>
                                                    ) : (
                                                        this.renderPremiumColors(inView)
                                                    )
                                                }
                                            </MediaQuery>
                                        </div>
                                    </div>
                                    <div
                                        className={`${st}__row ${st}__row_tablet-column`}
                                    >
                                        {another_conf &&
                                            <div
                                                className={`${st}__col ${st}__col_left`}
                                            >
                                                <h3 className={`${st}__title`}>
                                                    Другие варианты:
                                                </h3>
        
                                                {another_conf &&
                                                this.renderMarkerList(
                                                    another_conf
                                                )}
                                            </div>
                                        }
                                        
                                        {finishes &&
                                            <div
                                                className={`${st}__col ${st}__col_mid`}
                                            >
                                                {<Finishes {...finishes} />}
                                            </div>
                                        }
                                        
                                        <div
                                            className={`${st}__col ${st}__col_right`}
                                        >
                                            {fetchResult.base && (
                                                <Button
                                                    text="Связаться с менеджером"
                                                    bordered={true}
                                                    mod="_lila"
                                                    onClick={() => {
                                                        togglePopUp({
                                                            showPopUp: true,
                                                            content:
                                                                fetchResult.base
                                                                    .header_popup
                                                        });
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </InView>
        );
    }
}

function mapStateToProps({ baseStore, popupStore }) {
    return { baseStore, popupStore };
}

function mapDispatchToProps(dispatch) {
    return {
        popupActions: bindActionCreators(popupActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelParameters);
